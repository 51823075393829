import { Directive, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[proibirCaracteresEspeciais]'
})
export class ProibirCaracteresEspeciaisDirective implements OnInit {

  @Input() public proibirCaracteresEspeciais: any;
  @Input() public permitirNumeros = false;

  public nameRegexp = /[a-zA-Z\sáéíóúãõâêîôûçSÁÉÍÓÚÃÕÂÊÎÔÛÇ'˜^ˆ]/g;
  public nameNumbersRegexp = /[0-9a-zA-Z\sáéíóúãõâêîôûçSÁÉÍÓÚÃÕÂÊÎÔÛÇ'˜^ˆ]/g;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.proibirCaracteresEspeciais) {
      return;
    }
    this.proibirCaracteresEspeciais.valueChanges.subscribe((valor) => {
      setTimeout(() => {
        let nomeTratado;

        if (this.permitirNumeros) {
          nomeTratado = this.proibirCaracteresEspeciais.value.match(this.nameNumbersRegexp);
        } else {
          nomeTratado = this.proibirCaracteresEspeciais.value.match(this.nameRegexp);
        }

        if (nomeTratado) {
          if (nomeTratado.join('').length !== this.proibirCaracteresEspeciais.value.length) {
            this.proibirCaracteresEspeciais.patchValue(nomeTratado.join(''));
          }
        } else if (valor.length) {
          this.proibirCaracteresEspeciais.patchValue('');
        }

        if (this.proibirCaracteresEspeciais.value.match(/\s{2}/g)) {
          this.proibirCaracteresEspeciais.patchValue(
            this.proibirCaracteresEspeciais.value.replace(/\s{2}/g, ' '));
        }
      }, 180);
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApresentarDadosDirective, CasoCarregandoDirective, CasoErroDirective, CasoResultadoDirective } from './apresentar-dados.directive';
import { CasoVazioDirective, TesteVazioDirective } from './teste-vazio.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ApresentarDadosDirective,
    CasoCarregandoDirective,
    CasoErroDirective,
    CasoResultadoDirective,
    TesteVazioDirective,
    CasoVazioDirective,
  ],
  exports: [
    ApresentarDadosDirective,
    CasoCarregandoDirective,
    CasoErroDirective,
    CasoResultadoDirective,
    TesteVazioDirective,
    CasoVazioDirective,
  ],
})
export class ApresentarDadosComponentModule {}

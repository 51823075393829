import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Promise<Storage>;
  private appKey = environment.app;

  constructor(
    storageBackend: Storage,
  ) {
    this.storage = storageBackend.create();
    if (!environment.production) {
      this.storage.then(storage => {
        (window as any).storage = storage;
      });
    }
  }

  async get<T extends Storable>(key: string, prefix?: string): Promise<T | undefined> {
    if (prefix) {
      key = `${this.appKey}:${prefix}:${key}`;
    } else {
      key = `${this.appKey}:${key}`;
    }
    const s = await this.storage;
    return s.get(key);
  }

  async set<T extends Storable>(key: string, value: T, prefix?: string): Promise<T> {
    if (prefix) {
      key = `${this.appKey}:${prefix}:${key}`;
    } else {
      key = `${this.appKey}:${key}`;
    }
    const s = await this.storage;
    return s.set(key, value);
  }

  async remove(key: string, prefix?: string) {
    if (prefix) {
      key = `${this.appKey}:${prefix}:${key}`;
    } else {
      key = `${this.appKey}:${key}`;
    }
    const s = await this.storage;
    await s.remove(key);
  }
}

export type Storable = JsonValue | ArrayBuffer | Blob;

type JsonValue = string | number | boolean | null | JsonValue[] | object;

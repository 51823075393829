import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-',
  templateUrl: './alerta-modal.component.html',
  styleUrls: [ './alerta-modal.component.scss' ],
})

export class AlertaModalComponent implements OnInit {

  @Input() tipo: any;
  @Input() titulo: any;
  @Input() mensagem: any;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  async fechar() {
    await this.modalController.dismiss();
  }

  async excluir() {
    await this.modalController.dismiss(true);
  }
}

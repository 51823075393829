import { Injectable } from '@angular/core';
import { IdPerfilProdutor } from '@zellotec/terralogs_regras/models';
import { BehaviorSubject } from 'rxjs';
import { SolicitacaoSessao } from '../util/mapeamento-solicitacao';
import { SituacaoSolicitacaoEnum } from '../enums/situacao-solicitacao.enum';
import { getNumeroOperacao } from '../util/utils';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  private itens: Tabs[] = [
    {
      nome: 'Dados do Solicitante',
      rota: 'solicitante',
      desabilitado: false,
      obrigatorio: true
    },
    {
      nome: 'Crédito',
      rota: 'credito',
      desabilitado: true,
      obrigatorio: true
    },
    {
      nome: 'Histórico',
      rota: 'historico',
      desabilitado: true
    },
    {
      nome: 'Atividade',
      rota: 'producao',
      desabilitado: true
    },
    {
      nome: 'Propriedades Rurais Próprias',
      rota: 'propriedades-rurais-proprias',
      desabilitado: true
    },
    {
      nome: 'Propriedades Rurais Arrendadas',
      rota: 'propriedades-rurais-arrendadas',
      desabilitado: true
    },
    {
      nome: 'Outros Bens',
      rota: 'outros-bens',
      desabilitado: true
    },
    {
      nome: 'Estoque',
      rota: 'estoque',
      desabilitado: true
    },
    {
      nome: 'Recebíveis',
      rota: 'contratos-futuros',
      desabilitado: true
    },
    {
      nome: 'Endividamento',
      rota: 'endividamento',
      desabilitado: true
    },
    {
      nome: 'Garantias',
      rota: 'garantias',
      desabilitado: true
    },
    {
      nome: 'Referências',
      rota: 'referencias',
      desabilitado: true
    },
    {
      nome: 'Documentos',
      rota: 'documentos',
      desabilitado: true
    },
  ];
  private titulo$: BehaviorSubject<string> = new BehaviorSubject<string>('Novo cadastro');
  private habilitarResumo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private itens$: BehaviorSubject<Tabs[]> = new BehaviorSubject<Tabs[]>(this.itens);

  constructor() {
  }

  buscarItens() {
    return this.itens$.asObservable();
  }

  habilitarObrigatorios() {
    this.itens
      .filter(x => x.obrigatorio)
      .map(x => x.desabilitado = false);
    this.itens$.next(this.itens);
  }

  habilitarTodos() {
    this.itens
      .map(x => x.desabilitado = false);
    this.itens$.next(this.itens);
  }

  desabilitarTodos() {
    this.itens
      .map(x => x.desabilitado = true);
    this.itens$.next(this.itens);
  }

  desabilitarTabs(tabs: string[]) {
    this.itens
      .filter(x => tabs.includes(x.rota))
      .map(x => x.desabilitado = true);
  }

  reset() {
    this.desabilitarTodos();
    this.itens[0].desabilitado = false;
    this.itens$.next(this.itens);
  }

  buscarTitulo() {
    return this.titulo$.asObservable();
  }

  limparTitulo() {
    this.titulo$.next('');
  }

  alterarTitulo(nomeUsuario: string, isOperacao = null, idSolicitacao = null) {
    const titulo = !isOperacao ? `${ nomeUsuario }` : `${ nomeUsuario } - #${ getNumeroOperacao(idSolicitacao) }`;
    this.titulo$.next(titulo);
  }

  buscarHabilitarResumo() {
    return this.habilitarResumo$.asObservable();
  }

  habilitarResumo(situacao) {
    this.habilitarResumo$.next(situacao > SituacaoSolicitacaoEnum.SolicitacaoIniciada);
  }

  liberarTabs(solicitacao: SolicitacaoSessao) {
    const perfilProdutor = solicitacao?.perfilProdutor?.campos?.id;
    if (solicitacao.menuTodos) {

      if (solicitacao?.situacaoSolicitacao !== SituacaoSolicitacaoEnum.PendenteDeDocumentacao) {
        this.habilitarTodos();
      } else {
        this.desabilitarTodos();
        this.habilitarRota([ 'documentos' ]);
      }

      if (perfilProdutor === IdPerfilProdutor.Pecuarista) {
        this.habilitarRota([ 'estoque' ], false);
      }

    } else if (solicitacao.menuObrigatorio) {
      this.habilitarObrigatorios();
    }
  }

  habilitarRota(rotas: string[], habilitar = true) {
    rotas.forEach(rota => {
      this.itens = this.itens.filter((item) => {
        if (item.rota === rota) {
          item.desabilitado = !habilitar;
        }
        return item;
      });
    })
  }

}

export interface Tabs {
  nome: string;
  rota: string;
  desabilitado: boolean;
  obrigatorio?: boolean;
}

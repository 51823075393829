import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { resolve } from 'src/app/util/resolve';
import { RetornoApi } from '../models/api';
import { joinParams } from '../util/utils';
import { bucketDocumentos } from 'src/app/util/constants';
import { pastas } from 'src/app/models/forms';
import * as fileSaver from 'file-saver';
import { Documento } from '@zellotec/terralogs_regras/models/documento';
import { SolicitacaoService } from './solicitacao.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {
  constructor(
    private http: HttpClient,
    public solicitacaoService: SolicitacaoService
  ) {}

  getData(url: string, fileName?: string): any {
    const headers = new HttpHeaders().append('Access-Control-Allow-Origin', '*');
    return this.http.get(url, { responseType: 'blob', headers: headers }).pipe(
      catchError(err => {
        if (err.error) {
          if (err.status === '401') {
            return throwError(err.message);
          } else if (err.status === '400') {
            return throwError(err.message);
          } else if (err.status === '404') {
            return throwError(err.message);
          } else {
            return throwError('Não foi possivel baixar arquivo.');
          }
        }

      })
    );

  }

  async SaveFile(blob: Blob, FileName: string) {
    return fileSaver.saveAs(blob, FileName);
  }

  async uploadFormData(solicitanteId, data: FormData) {
    const url = resolve('backend://upload', { solicitanteId });
    return await this.http
      .post<any>(url, data)
      .toPromise();
  }

  async uploadKmz(solicitanteId, data: FormData) {
    const url = resolve('backend://uploadKmz', { solicitanteId });
    return await this.http
      .post<any>(url, data)
      .toPromise();
  }

  async upload(caminho, base64) {
    const url = resolve('upload://upload');
    return await this.http
      .post<any>(url, { path: caminho, image: base64 })
      .toPromise();
  }

  async uploadRobo(data: FormData) {
    const url = resolve('refactory://documento/criarSolicitacao');
    return await this.http
      .post<any>(url, data)
      .toPromise();
  }

  async uploadPlanilhaParceiro(data: FormData, idSolicitante: number) {
    const url = resolve('refactory://criarSolicitacaoParceiro', { idSolicitante });
    return await this.http
      .post<any>(url, data)
      .toPromise();
  }

  async uploadDocumentoParceiro(data: FormData, idSolicitacao: number, documentType: string) {
    const url = resolve('refactory://documentoSolicitacaoParceiro', { idSolicitacao, documentType });
    return await this.http
      .post<any>(url, data)
      .toPromise();
  }

  async submeterSolicitacao(idSolicitacao: number) {
    const url = resolve('refactory://submeterSolicitacaoParceiro', { idSolicitacao });
    return await this.http
      .put<any>(url, {})
      .toPromise();
  }

  async fetchPastasCaminho(caminho): Promise<IDocumentoTipo[]> {
    const request: IDocumentoTipoRequest = {
      caminho: `${ caminho }`,
    };
    const url = resolve('documento://pastas/caminho');
    const response: any = await this.http
      .get<IDocumentoTipo[]>(`${ url }?${ joinParams(request) }`)
      .toPromise();

    return response.data;
  }

  async fetchArquivos(pasta): Promise<IDocumento[]> {
    const request: IDocumentoTipoRequest = {
      idPasta: `${ pasta }`,
    };
    const url = resolve('documento://arquivos/pasta');
    const response: any = await this.http
      .get<IDocumento[]>(`${ url }?${ joinParams(request) }`)
      .toPromise();

    return response.data;
  }

  async fetchConteudo(idArquivo): Promise<IDocumento> {
    const url = resolve('documento://arquivos/find', { idArquivo: idArquivo });
    const response: any = await this.http
      .get<IDocumento>(`${ url }?somenteUrl=0`)
      .toPromise();

    return response.data;
  }

  async createArquivoType(caminho, obj): Promise<IDocumento[]> {
    return await this.transformDocumentoObj(caminho, obj);
  }

  async createArquivo(body: IDocumento): Promise<IDocumento[]> {
    const url = resolve('documento://arquivos/create');
    return await this.http
      .post<IDocumento[]>(url, body)
      .toPromise();
  }

  async transformDocumentoObj(caminho, obj: any): Promise<IDocumento[]> {
    let retVal: IDocumento;
    let pastaIndex = 0;
    //     'DOCUMENTOS PESSOAIS',
    //   'DOCUMENTOS CONTABEIS FINANCEIROS',
    //   'DOCUMENTOS DAS GARANTIAS',
    //   'DOCUMENTOS DAS PROPRIEDADES',
    //   'IMAGENS',
    //   'MANDADO TERRALOGS',
    if (obj.id_obj == '13.2') {
      pastaIndex = 3
    } else if (obj.id_obj == '13.3') {
      pastaIndex = 1
    } else if (obj.id_obj == '13.4') {
      pastaIndex = 2
    } else if (obj.id_obj == '13.5') {
      pastaIndex = 4
    } else if (obj.id_obj == '13.6') {
      pastaIndex = 5
    }
    const pasta: any = await this.fetchPastasCaminho(`${ caminho }/${ pastas[pastaIndex] }`);
    switch (obj.id_obj) {
      case '13.1':
        // if(obj.perfil.values.images.length>0){
        obj.obj.perfil.value.imagens.forEach(async image => {
          retVal = {
            descricao: obj.obj.perfil.label,
            titulo: obj.obj.perfil.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: image.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        obj.obj.perfil.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.perfil.label,
            titulo: obj.obj.perfil.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.cnhRgCpf.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.cnhRgCpf.label,
            titulo: obj.obj.cnhRgCpf.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.cnhRgCpf.value.imagens.forEach(async imagem => {
          retVal = {
            descricao: obj.obj.cnhRgCpf.label,
            titulo: obj.obj.cnhRgCpf.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: imagem.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.compResidencia.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.compResidencia.label,
            titulo: obj.obj.compResidencia.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.compResidencia.value.imagens.forEach(async imagem => {
          retVal = {
            descricao: obj.obj.compResidencia.label,
            titulo: obj.obj.compResidencia.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: imagem.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.declaracaoIRPF.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.declaracaoIRPF.label,
            titulo: obj.obj.declaracaoIRPF.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.declaracaoIRPF.value.imagens.forEach(async imagem => {
          retVal = {
            descricao: obj.obj.declaracaoIRPF.label,
            titulo: obj.obj.declaracaoIRPF.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: imagem.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.reciboIRPF.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.reciboIRPF.label,
            titulo: obj.obj.reciboIRPF.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.reciboIRPF.value.imagens.forEach(async imagem => {
          retVal = {
            descricao: obj.obj.reciboIRPF.label,
            titulo: obj.obj.reciboIRPF.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: imagem.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.certCasamento.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.certCasamento.label,
            titulo: obj.obj.certCasamento.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.certCasamento.value.imagens.forEach(async imagem => {
          retVal = {
            descricao: obj.obj.certCasamento.label,
            titulo: obj.obj.certCasamento.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: imagem.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.docConjuge.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.docConjuge.label,
            titulo: obj.obj.docConjuge.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.docConjuge.value.imagens.forEach(async imagem => {
          retVal = {
            descricao: obj.obj.docConjuge.label,
            titulo: obj.obj.docConjuge.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: imagem.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });

        obj.obj.documentos.value.imagens.forEach(async image => {
          retVal = {
            descricao: obj.obj.documentos.label,
            titulo: obj.obj.documentos.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: image.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        obj.obj.documentos.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.documentos.label,
            titulo: obj.obj.documentos.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        // }
        // const pasta = await this.fetchPastasCaminho(caminho);

        break;
      case '13.2':
      case '13.3':
      case '13.4':
        obj.obj.documentos.value.imagens.forEach(async image => {
          retVal = {
            descricao: obj.obj.documentos.label,
            titulo: obj.obj.documentos.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: image.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        obj.obj.documentos.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.documentos.label,
            titulo: obj.obj.documentos.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        break;
      case '13.5':
        obj.obj.imagens.value.imagens.forEach(async image => {
          retVal = {
            descricao: obj.obj.imagens.label,
            titulo: obj.obj.imagens.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: image.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        obj.obj.imagens.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.imagens.label,
            titulo: obj.obj.imagens.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        break;
      case '13.6':
        obj.obj.mandatoTerraLogs.value.imagens.forEach(async image => {
          retVal = {
            descricao: obj.obj.mandatoTerraLogs.label,
            titulo: obj.obj.mandatoTerraLogs.label,
            idTipoArquivo: 2,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: image.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        obj.obj.mandatoTerraLogs.value.pdfs.forEach(async pdfs => {
          retVal = {
            descricao: obj.obj.mandatoTerraLogs.label,
            titulo: obj.obj.mandatoTerraLogs.label,
            idTipoArquivo: 1,
            idPasta: pasta.pastas[0].idPasta,
            conteudo: pdfs.src.split(',')[1],
            ativo: 1
          };
          await this.createArquivo(retVal);
        });
        break;
      default:
        break;
    }

    return

  }

  async createPastasType(pasta): Promise<IDocumentoTipo[]> {
    const url = resolve('documento://pastas/create');

    let retVal = new Array<IDocumentoTipo>();
    this.estruturaPastasDefault(pasta).forEach(async element => {
      const body: IDocumentoTipo = element;
      const response: any = await this.http
        .post<IDocumentoTipo[]>(url, body)
        .toPromise();
      retVal.push(response);
    });
    return retVal;

  }

  private estruturaPastasDefault(pasta): IDocumentoTipo[] {
    let retVal = new Array<IDocumentoTipo>();
    let estruturaDefault: IDocumentoTipo = {
      nome: pasta,
      descricao: 'Pasta para documentos de solicitantes',
      caminho: pasta,
      repositorio: bucketDocumentos,
      ativo: 1,
    };
    retVal.push(estruturaDefault);
    pastas.forEach(element => {
      let estruturaDefault: IDocumentoTipo = {
        nome: pasta,
        descricao: 'Pasta para documentos de solicitantes',
        caminho: `${ pasta }/${ element }`,
        repositorio: bucketDocumentos,
        ativo: 1,
      };
      retVal.push(estruturaDefault);
    });
    return retVal;
  }

  adicionar(documento): Observable<Documento> {
    const url = resolve('refactory://documento');
    return this.http.post<Documento>(url, documento);
  }

  getDocumentoRaro(idSolicitacao: number, forcar: boolean = false): Observable<any> {
    let url = resolve('refactory://documento/raro', { idSolicitacao });
    if (forcar) {
      url += '?force=true';
    }
    return this.http.get<any>(url);
  }

  excluir(idDocumento: number): Observable<Documento> {
    const url = resolve('refactory://documento/idDocumento', { idDocumento });
    return this.http.delete<Documento>(url);
  }

  uploadDocumento(data: FormData, idSolicitacao: number): Observable<any> {
    const url = resolve('refactory://documento/idSolicitacao', { idSolicitacao });
    return this.http.post<RetornoApi<{ result: any }>>(url, data).pipe(
      map(result => result.data.result),
    );
  }

  uploadArquivo(data: FormData): Observable<any> {
    const url = resolve('refactory://arquivo');
    return this.http.post<RetornoApi<{ result: any }>>(url, data).pipe(
      map(result => result.data.result),
    );
  }

  uploadArquivoUsuario(data: FormData): Observable<any> {
    const url = resolve('refactory://arquivo/porUsuario');
    return this.http.post<RetornoApi<{ result: any }>>(url, data).pipe(
      map(result => result.data.result),
    );
  }
}

export interface IDocumento {
  idArquivo?: string;
  conteudo?: any;
  titulo: string;
  descricao: string;
  idTipoArquivo: number;
  dataHoraCriado?: string;
  dataHoraAtualizado?: string;
  idPasta: string;
  url?: string;
  ativo: number;
}

export interface IDocumentoRequest {
  idPasta?: string;
  subpastas?: number;
  nome?: string;
}

export interface IDocumentoTipo {
  id?: number;
  ativo: number;
  tipo?: string;
  idPasta?: string;
  caminho?: string;
  nome?: string;
  descricao?: string;
  repositorio?: string;
}

export interface IDocumentoTipoRequest {
  caminho?: string;
  nome?: string;
  idPasta?: string;
}

export interface ICriarArquivoRequest {
  titulo: string;
  descricao?: string;
  idTipoArquivo: number;
  idPasta: string;
  ativo: number;
  conteudo: string;
}

export interface ICriarArquivoResponse {
  idArquivo: string;
}



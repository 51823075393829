import { NgModule } from '@angular/core';
import { ProibirCaracteresEspeciaisDirective } from './proibir-caracteres-especiais.directive';
import { FormatarQuantidadeDirective } from './formatar-quantidade.directive';

@NgModule({
  declarations: [
    ProibirCaracteresEspeciaisDirective,
    FormatarQuantidadeDirective
  ],
  imports: [],
  exports: [
    ProibirCaracteresEspeciaisDirective,
    FormatarQuantidadeDirective
  ],
})
export class DirectivesModule {
}

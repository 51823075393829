import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { InfoUserMenuComponent } from './info-user-menu.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    LazyLoadImageModule
  ],
  exports: [
    InfoUserMenuComponent
  ],
  declarations: [ InfoUserMenuComponent ]
})
export class InfoUserMenuComponentModule {
}

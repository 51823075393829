import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-selecionar-filtro',
  templateUrl: './modal-selecionar-filtro.component.html',
  styleUrls: [ './modal-selecionar-filtro.component.scss' ],
})
export class ModalSelecionarFiltroComponent {

  @Input() filtros: SelecionarFiltro[];

  constructor(private modalCtrl: ModalController) {
  }

  ionViewDidEnter() {
    this.tratarFiltro();
  }

  tratarFiltro() {
    if (this.filtros.length) {
      this.filtros = this.filtros.map((filtro: any) => {
        delete filtro.ibAtividadePrincipal;
        delete filtro.ordem;
        delete filtro.mesBase;
        return filtro;
      })
    }
  }

  async filtrar() {
    const selecionados = this.filtros.filter(filtro => filtro.checked).map(filtro => filtro.id);
    await this.modalCtrl.dismiss(selecionados);
  }
}

export interface SelecionarFiltro {
  id?: number;
  nome?: string;
  checked?: boolean;
}

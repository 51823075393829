import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anoDuplo',
})

export class AnoDuploPipe implements PipeTransform {
  transform(ano: string | number): string {
    if (ano) {
      const proximoAno = +ano + 1;
      return `${ ano }/${ String(proximoAno).slice(2) }`;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { showToastMessage } from '../../util/utils';
import { AlertaModalComponent } from '../alerta-modal/alerta-modal.component';
import { Observable, of } from 'rxjs';
import { DominioService } from '../../services/dominio.service';

@Component({
  selector: 'app-reprovar-raro',
  templateUrl: './reprovar-raro.component.html',
  styleUrls: [ './reprovar-raro.component.scss' ],
})

export class ReprovarRaroComponent implements OnInit {

  @Input() isAdmin = false;

  guias$: Observable<any[]>;
  guia$: Observable<any[]>;
  formulario$: Observable<any[]>;

  customPopoverOptions: any = {
    subHeader: 'Selecione uma opção',
  };
  form: FormGroup;
  listaGuias = [];
  listaFormularios = [];

  constructor(private modalCtrl: ModalController,
              private formBuilder: FormBuilder,
              private modalController: ModalController,
              private dominioService: DominioService) {
  }

  ionViewDidLeave() {
    this.guias$ = of([]);
    this.guia$ = of([]);
    this.formulario$ = of([]);
  }

  ngOnInit() {
    this.form = new FormGroup({
      alteracoes: new FormArray([])
    });
    this.getGuias();
    this.getFormularios();
    this.carregarFormAlteracoes();
  }

  carregarFormAlteracoes() {
    const formulario = this.criarFormGuiasFormularios();
    const formAlteracoes = this.form.get('alteracoes') as FormArray;
    this.limparFormAlteracoes(formAlteracoes);
    formAlteracoes.push(formulario);
  }

  limparFormAlteracoes(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  getGuias() {
    this.dominioService.buscarGuias().subscribe(res => {
      this.listaGuias = res;
    });
  }

  getFormularios() {
    this.dominioService.buscarFormularios().subscribe(res => {
      this.listaFormularios = res;
    });
  }

  changeMotivo() {
    this.carregarFormAlteracoes();
  }

  changeGuia(form) {
    form.get('formulario').setValue(null);
    form.get('formulario').enable();
  }

  criarFormGuiasFormularios(guia?, formulario?, descricao?): FormGroup {
    return new FormGroup({
      guia: new FormControl(guia),
      formulario: new FormControl({
        value: formulario,
        disabled: !formulario
      }),
      descricao: new FormControl(descricao, [ Validators.required ]),
      quantidadeCaracteres: new FormControl(0)
    });
  }

  async novoGuiaFormulario(valueGuia = null, valueFormulario = null, valueDescricao = null) {

    if (!valueGuia || !valueFormulario || !valueDescricao) {
      return showToastMessage('É necessário informar a guia e o formuário.', 'danger');
    }

    const formsGuiasFormularios = this.form.get('alteracoes') as FormArray;
    await formsGuiasFormularios.insert(
      formsGuiasFormularios.length,
      this.criarFormGuiasFormularios(valueGuia, valueFormulario, valueDescricao)
    );

    setTimeout(() => {
      const id = document.getElementById('outro_' + (formsGuiasFormularios.length - 1));
      this.scrollToElement(id);
    });

    await this.limparForm(formsGuiasFormularios, '0');

  }

  limparForm(form, index) {
    form.get(index).get('guia').setValue(null);
    form.get(index).get('formulario').disable();
    form.get(index).get('descricao').setValue(null);
    form.get(index).get('formulario').disable();
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  async delOutroGuiaFormulario(index: number) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Guia e Formuário!',
        mensagem: 'Tem certeza que deseja excluir?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const alteracoes = this.form.get('alteracoes') as FormArray;
      alteracoes.removeAt(index);
    }
  }


  calcularQuantidade(i: any, evento: any) {
    const valor = evento.detail.value;
    this.form.get('alteracoes').get(i.toString()).get('quantidadeCaracteres').setValue(valor.length || 0);
  }

  async fechar() {
    await this.modalController.dismiss();
  }

  async salvar() {
    const formAlteracoes = this.form.get('alteracoes') as FormArray;
    const form = [];

    let guiaInvalida = false;
    for (const x of formAlteracoes.controls) {
      if (!x.get('guia').value || !x.get('formulario').value) {
        guiaInvalida = true;
      }
      form.push(x.value);
    }

    if ( guiaInvalida) {
      return await showToastMessage('Preencha corretamente o formulário', 'danger');
    }

    await this.modalController.dismiss({alteracoes: form});
  }
}

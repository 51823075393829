import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { showToastMessage } from '../../util/utils';
import { Observable, of } from 'rxjs';
import { DominioService } from '../../services/dominio.service';

@Component({
  selector: 'app-cancelar-operacao',
  templateUrl: './cancelar-operacao.component.html',
  styleUrls: [ './cancelar-operacao.component.scss' ],
})

export class CancelarOperacaoComponent implements OnInit {

  @Input() isAdmin = false;
  customPopoverOptions: any = {
    subHeader: 'Selecione uma opção',
  };
  form: FormGroup;
  quantidadeCaracteres = 0;

  tiposCancelamento$: Observable<any[]>;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private dominioService: DominioService) {
  }

  ionViewDidLeave() {
    this.tiposCancelamento$ = of([]);
  }

  ngOnInit() {
    this.form = new FormGroup({
      tipoCancelamento: new FormControl('', [ Validators.required ]),
      descricao: new FormControl('', [ Validators.required ]),
    });
    this.tiposCancelamento$ = this.dominioService.buscarTiposCancelamento();
  }

  calcularQuantidade(campo: any) {
    this.quantidadeCaracteres = campo.value.length || 0;
  }

  async fechar() {
    await this.modalController.dismiss();
  }

  async salvar() {
    if (!this.form.valid) {
      return await showToastMessage('Preencha corretamente o formulário', 'danger');
    }
    await this.modalController.dismiss(this.form.getRawValue());
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ParamsPaginacao, RetornoApiPaginado } from "../models/api";
import { Params } from "@angular/router";
import { pick } from "lodash";
import { Pagina, mapParamsPaginacao, mapRetornoApiPaginado } from "../util/pagination";
import { Observable } from "rxjs";
import { Usuario } from "@zellotec/terralogs_regras/models";
import { removerAcentos } from "../util/utils";
import { resolve } from 'src/app/util/resolve';

@Injectable({
  providedIn: 'root'
})
export class SolicitacoesParceiroService {
  constructor(
    private http: HttpClient
  ) {
  }

  getSolicitacoes(params: ParamsGetSolicitacoes): Observable<Pagina<SolicitacaoParceiro>> {
    const query: any = { ...params };
    if (query && query.query) {
      query.query = removerAcentos(query.query);
    }
    const url = resolve('refactory://listaSolicitacoesParceiros');
    return this.http.get<RetornoSolicitacoes>(url, {
      params: { ...query },
    }).pipe(
      mapRetornoApiPaginado('items', query)
    );
  }

  async updateSolicitacao(data: any, idSolicitacao: number) {
    const url = resolve('refactory://atualizarSolicitacaoParceiro', { idSolicitacao });
    return await this.http
      .put<any>(url, data)
      .toPromise();
  }
}

export type RetornoSolicitacoes = RetornoApiPaginado<'items', SolicitacaoParceiro>;
export interface ParamsGetSolicitacoes extends ParamsPaginacao {
  query?: string;
  idParceiro?: string;
}

export interface SolicitacaoParceiro {
  id?: number;
  dataCadastro?: Date | string;
  dataAtualizacao?: Date | string;
  status?: string;
  motivo?: string;
  enderecoPlanilha?: string;
  usuarioParceiro?: Usuario;
  usuarioSolicitante?: Usuario;
  usuarioAtendente?: Usuario;
  enderecoDocumentos?: string;
  enderecoComprovantesEnderecos?: string;
  enderecoImpostoRenda?: string;
  enderecoCertidaoCasamento?: string;
  enderecoMatriculas?: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { EstadoVazioComponent } from './estado-vazio.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    EstadoVazioComponent
  ],
  declarations: [EstadoVazioComponent]
})
export class EstadoVazioComponentModule {
}

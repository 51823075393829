import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { throwError } from 'rxjs';
import { AlertController } from '@ionic/angular';

@Injectable()
export class AutorizacaoInterceptor implements HttpInterceptor {
  constructor(
    private readonly auth: AutorizacaoService,
    private readonly alertController: AlertController,
    private readonly autorizacaoService: AutorizacaoService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    const urlsSemAutorizacao = [ '/login', '/resetpassword', '/changepassword', 's3.amazonaws' ];
    const isAutorizacao = urlsSemAutorizacao.findIndex(url => request.url.indexOf(url) !== -1);
    if (isAutorizacao !== -1) {
      return next.handle(request);
    }

    if (request.headers.get('x-skip-autorizacao')) {
      return next.handle(request.clone({
        headers: request.headers.delete('x-skip-autorizacao')
      }));
    }

    const token = localStorage.getItem('TERRALOGS-TOKEN');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${ token }`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        const user = localStorage.getItem('TERRALOGS-TOKEN');
        if (err.status === 401 && user) {
          this.autorizacaoService.apresentarModal(
            'Sessão Expirada!',
            'Sua sessão expirou. Você será redirecionado à tela de login novamente.',
            'Sair'
          );
        }
        if (err.status === 403 && user) {
          this.autorizacaoService.apresentarModal(
            'Permissão Negada!',
            'Você não tem permissão para acessar este recurso.',
            'Ok'
          );
        }
        return throwError(err.error?.result || err.message);
      })
    );
  }
}

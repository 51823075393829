import { Component, Input, OnInit } from '@angular/core';
import { showToastMessage } from '../../../util/utils';
import { ModalController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Usuario } from '@zellotec/terralogs_regras/models';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import { loading } from '../../../util/loading';
import { SEGURO, SeguroRuralService } from '../../../services/seguro-rural.service';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-seguro-rural',
  templateUrl: './seguro-rural.component.html',
  styleUrls: [ './seguro-rural.component.scss' ],
})
export class SeguroRuralComponent implements OnInit {

  @Input() item: any;
  @Input() idSolicitacao: number;
  @Input() usuarioLogado: Usuario;
  opcaoOutras = false;
  segurosRurais: any[] = [];
  titulosFormsDinamicos = {};
  mostrarFormsDinamicos = {};
  totalFormDinamico = [];
  idUsuarioEditar: number;
  form: FormGroup;
  formDinamico: FormGroup;
  submitted: boolean;

  constructor(
    private modalCtrl: ModalController,
    private seguroRuralService: SeguroRuralService
  ) {
    this.form = new FormGroup({
      seguroRuralOutroCampo: new FormControl(''),
      seguroRuralOutroTipoCampo: new FormControl([]),
      outrosSegurosRurais: new FormArray([]),
    });

    this.formDinamico = new FormGroup({});
  }

  ngOnInit() {
    this.idUsuarioEditar = this.item?.id;
    loading(
      this.seguroRuralService.buscarSegurosRurais().pipe(
        mergeMap((seguroRural) => {
          this.segurosRurais = seguroRural;
          this.segurosRurais.forEach(async (seguro) => {
            // Nao possui e outros
            if (seguro.id !== SEGURO.outros && seguro.id !== SEGURO.naoPossui) {
              await this.formDinamico.addControl(seguro.id, new FormArray([]));
              await this.formDinamico.addControl('campo_' + seguro.id.toString(), new FormControl(''));
              this.titulosFormsDinamicos[seguro.id] = seguro.nome;
              this.mostrarFormsDinamicos[seguro.id] = false;
              this.totalFormDinamico = [ ...this.totalFormDinamico, seguro.id ];
            }
          });

          this.segurosRurais = this.segurosRurais.map((seguro) => {
            seguro.checked = false;
            return seguro;
          });

          let obs = of(null);
          if (this.idSolicitacao) {
            obs = this.seguroRuralService.buscarSolicitacaoSeguroRural(this.idSolicitacao);
          }
          return obs;
        })
      ).subscribe((solicitacaoSeguroRural) => {
        if (solicitacaoSeguroRural) {
          const segurosOutros = this.form.get('outrosSegurosRurais') as FormArray;
          solicitacaoSeguroRural.result.forEach((seguro) => {
            const buscar = this.buscarNoArrayPorId('segurosRurais', seguro.seguroRural.id);
            buscar.selecionado.checked = true;
            this.segurosRurais[buscar.index] = buscar.selecionado;
            if (seguro?.solicitacaoSeguroRuralSeguradora.length > 0) {
              seguro.solicitacaoSeguroRuralSeguradora.forEach((nmSeguro) => {
                // Outros
                if (seguro.seguroRural.id === SEGURO.outros) {
                  segurosOutros.insert(this.form.get('outrosSegurosRurais').value.length, this.criarForm(nmSeguro?.nome));
                  this.opcaoOutras = true;
                } else {
                  this.mostrarFormsDinamicos[seguro.seguroRural.id] = true;
                  const seguros = this.formDinamico.get(seguro.seguroRural.id.toString()) as FormArray;
                  seguros.insert(this.formDinamico.get(seguro.seguroRural.id.toString()).value.length, this.criarForm(nmSeguro?.nome));
                }
              });
            }
            if (seguro.seguroRural.id === SEGURO.outros) {
              this.form.get('seguroRuralOutroTipoCampo').setValue(seguro.nomeOutro);
            }
          });
        }
      })
    );
  }

  async selecionarOpcao(evento: any) {
    const buscar = this.buscarNoArrayPorId('segurosRurais', evento.id);
    const seguroRural = this.form.get('outrosSegurosRurais') as FormArray;
    // Não possui
    if (evento.id === SEGURO.naoPossui && evento.checked) {
      const retornoModal = await this.confirmOpcaoModal();
      if (retornoModal) {
        this.opcaoOutras = false;
        seguroRural.clear();
        // Dinamicos
        this.limparTodosOsForms();
        this.segurosRurais = this.segurosRurais.map((seguro) => {
          this.mostrarFormsDinamicos[seguro.id] = false;
          if (seguro.id !== SEGURO.naoPossui) {
            seguro.checked = false;
          }
          return seguro;
        });
        return this.segurosRurais;
      } else {
        evento.checked = false;
      }
    } else if (evento.id !== SEGURO.naoPossui && evento.checked) {
      // Retirar o não possui
      const buscarNaoPossui = this.buscarNoArrayPorId('segurosRurais', SEGURO.naoPossui);
      buscarNaoPossui.selecionado.checked = false;
      this.segurosRurais[buscarNaoPossui.index] = buscarNaoPossui.selecionado;
      this.mostrarFormsDinamicos[evento.id] = true;
    }

    if ((evento.id !== SEGURO.naoPossui && evento.id !== SEGURO.outros) && !evento.checked) {
      this.limparFormDinamico(evento.id);
      this.mostrarFormsDinamicos[evento.id] = false;
      this.formDinamico.get('campo_' + evento.id.toString()).setValue('');
    }
    this.segurosRurais[buscar.index] = evento;
    // Outras opcoes
    this.opcaoOutras = !!this.segurosRurais.find(opcao => opcao.id === SEGURO.outros && opcao.checked);
    if (!this.opcaoOutras) {
      seguroRural.clear();
      this.form.get('seguroRuralOutroCampo').setValue('');
      this.form.get('seguroRuralOutroTipoCampo').setValue('');
    }
  }

  limparTodosOsForms() {
    this.segurosRurais.forEach((seguro) => {
      const formDinamico = this.formDinamico.get(seguro.id.toString()) as FormArray;
      if (formDinamico) {
        formDinamico.clear();
      }
    });
  }

  limparFormDinamico(id) {
    // Dinamico
    const formDinamico = this.formDinamico.get(id.toString()) as FormArray;
    if (formDinamico) {
      formDinamico.clear();
    }
  }

  buscarNoArrayPorId(array, comparacao) {
    const selecionado = this[array].find((opcao) => opcao.id === comparacao);
    const index = this[array].indexOf(selecionado);
    return {
      index,
      selecionado
    };
  }

  criarForm(value?): FormGroup {
    return new FormGroup({
      nome: new FormControl(value)
    });
  }

  async novaSeguradora(item, tipo, formName?) {
    if (tipo === 'outros') {
      if (item && !item.value) {
        await showToastMessage('É necessário informar o nome da seguradora.', 'danger');
        return;
      }
      const seguroRural = this.form.get('outrosSegurosRurais') as FormArray;
      seguroRural.insert(this.form.get('outrosSegurosRurais').value.length, this.criarForm(item.value));
      this.form.get('seguroRuralOutroCampo').setValue('');
      setTimeout(() => {
        const id = document.getElementById('outro_' + (seguroRural.length - 1));
        this.scrollToElement(id);
      });
    } else {
      if (item && !item.value) {
        await showToastMessage('É necessário informar o nome da seguradora.', 'danger');
        return;
      }
      const formArrayDinamico = this.formDinamico.get(formName.toString()) as FormArray;
      formArrayDinamico.insert(this.formDinamico.get('campo_' + formName).value.length, this.criarForm(item.value));
      this.formDinamico.get('campo_' + formName).setValue('');
      setTimeout(() => {
        const id = document.getElementById('outro_dinamico_' + formName + '_' + (formArrayDinamico.length - 1));
        this.scrollToElement(id);
      });
    }

  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  async delSeguradora(index: number, tipo, formName?) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Seguradora!',
        mensagem: 'Tem certeza que deseja esta seguradora?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      if (tipo === 'outros') {
        const seguroRural = this.form.get('outrosSegurosRurais') as FormArray;
        seguroRural.removeAt(index);
      } else {
        const formArrayDinamico = this.formDinamico.get(formName.toString()) as FormArray;
        formArrayDinamico.removeAt(index);
      }
    }
  }

  async confirmOpcaoModal() {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Atenção_Confirmar',
        titulo: 'Seguro Rural',
        mensagem: `Tem certeza que deseja selecionar a opção <b>Não Possui</b>? Ao confirmar você perderá todas as outras marcações.`,
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      return data;
    }
  }

  async salvar() {
    const segurosRuraisObj = {
      solicitacao: this.idSolicitacao,
      segurosRurais: []
    };
    const naoPossui = this.segurosRurais.find(x => x.id === SEGURO.naoPossui && x.checked);
    if (naoPossui) {
      segurosRuraisObj.segurosRurais.push({
        idSeguroRural: SEGURO.naoPossui,
      });
    }
    if (!this.segurosRurais.filter(x => x.checked).length) {
      return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
    }

    // Outros
    const outrosNomeSeguradora = [];
    if (this.form.get('outrosSegurosRurais')['controls'] && this.form.get('outrosSegurosRurais')['controls'].length > 0) {
      for (const outro of this.form.get('outrosSegurosRurais')['controls']) {
        if (outro.value.nome) {
          outrosNomeSeguradora.push({ nome: outro.value.nome });
        } else {
          return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
        }
      }
    }

    // Verificar se tem valor no campo [outro]
    const valorOutro = this.form.get('seguroRuralOutroCampo').value;
    if (valorOutro) {
      outrosNomeSeguradora.push({ nome: valorOutro });
    }


    const outroSeguroRural = this.segurosRurais.find(x => x.id === SEGURO.outros);
    if (outroSeguroRural.checked) {
      const outroTipoSeguradora = this.form.get('seguroRuralOutroTipoCampo').value;
      if (outrosNomeSeguradora.length > 0 && outroTipoSeguradora) {
        segurosRuraisObj.segurosRurais.push({
          idSeguroRural: SEGURO.outros, // ID outros
          nomeOutro: this.form.get('seguroRuralOutroTipoCampo').value,
          seguradora: outrosNomeSeguradora
        });
      } else {
        return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
      }
    }

    if (!outrosNomeSeguradora.length && this.opcaoOutras) {
      segurosRuraisObj.segurosRurais.push({
        idSeguroRural: SEGURO.outros, // ID outros
        nomeOutro: this.form.get('seguroRuralOutroTipoCampo').value,
      });
    }

    // Forms diferente de [Outros]
    for (const form of this.totalFormDinamico) {
      if (this.mostrarFormsDinamicos[form]) {
        let peloMenosUmSeguroAdicionado = false;
        if (this.formDinamico.get(form.toString()).value && this.formDinamico.get(form.toString()).value.length > 0) {
          peloMenosUmSeguroAdicionado = true;
          segurosRuraisObj.segurosRurais.push({
            idSeguroRural: form,
            nomeOutro: '',
            seguradora: this.formDinamico.get(form.toString()).value
          });
        }

        // Verificar se tem valor no campo sem ser [outro]
        const valor = this.formDinamico.get('campo_' + form.toString()).value;
        if (valor) {
          segurosRuraisObj.segurosRurais.push({
            idSeguroRural: form,
            nomeOutro: '',
            seguradora: [ { nome: valor } ]
          });
        }

        if (!peloMenosUmSeguroAdicionado && !valor) {
          return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
        }

      }
    }

    loading(
      this.seguroRuralService.salvarSeguroRural(segurosRuraisObj)
        .subscribe(async () => {
          await showToastMessage('Registro salvo com sucesso.', 'success');
          await this.modalCtrl.dismiss(true);
        }, async error => {
          await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
        })
    );
  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }
}

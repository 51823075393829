import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { PerfilEnum } from '../../enums/perfil.enum';
import { ModalController } from '@ionic/angular';
import { SolicitarAlteracaoPessoalComponent } from '../solicitar-alteracao-pessoal/solicitar-alteracao-pessoal.component';
import { UtilService } from '../../services/util.service';
import { showToastMessage } from '../../util/utils';
import { DocumentoService } from '../../services/documento.service';
import { BehaviorSubject } from 'rxjs';
import { TipoRequisicaoEnum } from '../../enums/tipo-requisicao.enum';
import { SituacaoRequisicaoEnum } from '../../enums/situacao-requisicao.enum';
import { RequisicaoService } from '../../services/requisicao.service';

@Component({
  selector: 'app-info-user-menu',
  templateUrl: './info-user-menu.component.html',
  styleUrls: [ './info-user-menu.component.scss' ],
})
export class InfoUserMenuComponent implements OnInit {

  @Output() onEditarUsuarioEvent = new EventEmitter();
  @Output() onSolicitarAlteracaoEvent = new EventEmitter();

  urlImage$: BehaviorSubject<string>;

  fotoAnexada = true;
  notaAtendente;
  usuario;
  perfilAdm = false;
  perfilAtendente = false;

  constructor(
    private modalCtrl: ModalController,
    private usuarioService: UsuarioService,
    private utilService: UtilService,
    private documentoService: DocumentoService,
    private requisicaoService: RequisicaoService
  ) {
  }

  ngOnInit() {
    this.usuario = this.usuarioService.buscarUsuarioLogado();
    this.usuarioService.notaAtendente().subscribe(res => {
      this.notaAtendente = res?.nota;
    });
    this.urlImage$ = new BehaviorSubject<string>(this.getUrlImagem());
    if (this.usuario) {
      this.perfilAdm = this.usuario.perfil === PerfilEnum.Admin;
      this.perfilAtendente = this.usuario.perfil === PerfilEnum.Atendente;
    }
  }

  getUrlImagem(): string {
    return this.utilService.getUrlImagemUsuario() + this.usuario.id + '?time=' + Date.now();
  }

  onSelectFile(evento: any) {
    if (evento.target.files) {
      const file = evento.target.files[0];
      this.inserirArquivo(this.usuario.id, file);
    }
  }

  editarUsuario() {
    document.getElementById('file').click();
    this.onEditarUsuarioEvent.emit();
  }

  async inserirArquivo(usuario: any, file: any) {
    const formData = new FormData();
    formData.append('arquivo', file);
    formData.append('usuario', usuario);

    this.documentoService.uploadArquivoUsuario(formData)
      .subscribe(() => {
        this.urlImage$.next(this.getUrlImagem());
      }, error => {
        return showToastMessage(error.message || 'Não foi possível salvar os dados. Tente novamente!', 'danger');
      });
  }

  async openModalSolicitarAlteracao() {
    const modal = await this.modalCtrl.create({
      component: SolicitarAlteracaoPessoalComponent,
      swipeToClose: true,
      cssClass: 'modal-formulario',
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data) {
      const obj = {
        usuarioCadastro: this.usuario.id,
        perfil: this.usuario.perfil,
        tipoRequisicao: TipoRequisicaoEnum.AlteracaoDadosPessoais,
        situacaoRequisicao: SituacaoRequisicaoEnum.AguardandoAnalise,
        tipoAlteracaoPessoal: +data.tipoAlteracao,
        valorAlterado: data.valorAlterado,
        motivo: data.motivo
      };

      this.requisicaoService.adicionar(obj)
        .subscribe(() => {
          showToastMessage('Solicitação enviada com sucesso.', 'success');
        }, error => {
          return showToastMessage(error.message || 'Não foi possível salvar os dados. Tente novamente!', 'danger');
        });
    }
  }

  async solicitarAlteracao() {
    await this.openModalSolicitarAlteracao();
    this.onSolicitarAlteracaoEvent.emit();
  }
}

import { Injectable } from '@angular/core';
import { resolve } from '../util/resolve';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelosCertificadosService {
  constructor(
    private http: HttpClient
  ) {}

  buscarSelosCertificados(): Observable<any> {
    const url = resolve('refactory://certificados');
    return this.http.get<any>(url).pipe(
      map((result: any) => result.items)
    );
  }

  salvarSolicitacaoSelosCertificacoes(solicitacao: SolicitacaoCertificado) {
    const url = resolve('refactory://solicitacaoCertificado');
    return this.http.post(url, solicitacao);
  }

  salvarSolicitacaoSelosCertificacoesOutros(solicitacao: SolicitacaoCertificadoOutros) {
    const url = resolve('refactory://solicitacaoCertificadoOutros');
    return this.http.post(url, solicitacao);
  }

  deletarSolicitacoesSelosCertificacoes(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoCertificado/idSolicitacao', { idSolicitacao });
    return this.http.delete(url);
  }

  buscarSolicitacoesSelosCertificacoes(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoCertificado/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }
}

export interface SolicitacaoCertificado {
  solicitacao?: number;
  certificacao?: number;
}

export interface SolicitacaoCertificadoOutros {
  nome?: string;
  solicitacaoCertificado?: number;
}

export enum SELOS_CERTIFICADOS {
  outros = 9,
  naoPossui = 10
}



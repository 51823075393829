import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { resolve } from 'src/app/util/resolve';
import { RetornoApi } from '../models/api';
import { map } from 'rxjs/operators';
import { Requisicao } from '@zellotec/terralogs_regras/models/requisicao';

@Injectable({
  providedIn: 'root'
})
export class RequisicaoService {

  constructor(private http: HttpClient) {
  }

  buscar(id: number): Observable<Requisicao> {
    const url = resolve('refactory://requisicoes/idRequisicao', { idRequisicao: id });
    return this.http.get<RetornoApi<{ result: Requisicao }>>(url).pipe(
      map(result => result.data.result),
    );
  }

  adicionar(requisicao): Observable<Requisicao> {
    const url = resolve('refactory://requisicoes');
    return this.http.post<Requisicao>(url, requisicao);
  }

  alterarResponsavel(requisicao: Requisicao | any, idRequisicao: number): Observable<Requisicao> {
    const url = resolve('refactory://requisicoesResponsavel/idRequisicao', { idRequisicao });
    return this.http.patch<Requisicao>(url, requisicao);
  }

  alterarDadosPessoais(requisicao: Requisicao | any, idRequisicao: number): Observable<Requisicao> {
    const url = resolve('refactory://requisicoesDadosPessoais/idRequisicao', { idRequisicao });
    return this.http.patch<Requisicao>(url, requisicao);
  }

  countGetRequisicoes(): Observable<any> {
    const url = resolve('refactory://requisicoes/count');
    return this.http.get<any>(url);
  }

  avaliar(idRequisicao: number, avaliar: Avaliar) {
    const url = resolve('refactory://requisicoes/avaliar/parceiro/idRequisicao', { idRequisicao });
    return this.http.patch<Requisicao>(url, avaliar);
  }

}

export interface Avaliar {
  situacaoRequisicao?: number;
  ativo?: boolean;
}

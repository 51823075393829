import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, AfterViewChecked {

  @ViewChild('SelectComponent') SelectComponent: Element;
  @Output() onChange = new EventEmitter();

  @Input() titulo = 'Selecione uma opção';
  @Input() tipo = 'select';
  @Input() lista: any[] = [];

  checkboxList: any[] = [];

  constructor(public popoverCtrl: PopoverController) {
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    // @ts-ignore
    if (this.SelectComponent.nativeElement) {// @ts-ignore
      if ((this.SelectComponent.nativeElement.parentElement.parentElement.clientHeight >= 1 && // @ts-ignore
        this.SelectComponent.nativeElement.parentElement.parentElement.clientHeight <= 100)) {// @ts-ignore
        this.SelectComponent.nativeElement.parentElement.parentElement.classList.add('position-top'); // @ts-ignore
        this.SelectComponent.nativeElement.parentNode.parentNode.parentElement.children[0].style.display = 'none';
      } else {// @ts-ignore
        this.SelectComponent.nativeElement.parentNode.parentNode.parentElement.children[0].style.display = 'block';
      }
    }
  }

  check(evt, item) {
    this.checkboxList = [];
    for (let i in this.lista) {
      if (this.lista[i].checked) {
        this.checkboxList.push(this.lista[i].value);
      }
    }

    // const j = this.list.findIndex(obj => obj.label === 'date');
    // const i = this.checkboxList.indexOf(item.value);
    // if (this.checkboxList.indexOf(item.value) >= 0) {
    //   item.checked = false;
    //   this.checkboxList.splice(i, 1);
    // } else {
    //   item.checked = true;
    //   this.checkboxList.push(item.value);
    // }
  }

  async selecionar(item: any) {
    await this.popoverCtrl.dismiss(item);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { parseDecimals, parseTextToDouble, showToastMessage } from '../../../util/utils';
import { ModalController, PopoverController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IdPerfilProdutor } from '@zellotec/terralogs_regras/models';
import { AtividadeAreaProducaoService, TIPO_PRODUCAO } from '../../../services/atividade-area-producao.service';
import { loading } from '../../../util/loading';
import { TipoProdutorEnum } from '../../../enums/tipo-produtor.enum';
import { SolicitacaoService } from '../../../services/solicitacao.service';

@Component({
  selector: 'app-area-producao',
  templateUrl: './area-producao.component.html',
  styleUrls: [ './area-producao.component.scss' ],
})
export class AreaProducaoComponent implements OnInit {

  tipoProducao = [];
  tipoPeriodoPrimeiroSegmento = [];
  tipoPeriodoSegundoSegmento = [];
  formSelecionado = 0;
  //

  form: FormGroup;
  itemFocus: string;
  @Input() idSolicitacao: number;
  @Input() item;
  @Input() anoBase: string;
  @Input() perfilProdutor: number;
  @Input() titulo = 'Selecione o(s) Período(s) de Safra(s)';

  constructor(private modalCtrl: ModalController,
              public popoverCtrl: PopoverController,
              private atividadeAreaProducaoService: AtividadeAreaProducaoService,
              private solicitacaoService: SolicitacaoService) {
    this.form = new FormGroup({
      primeiroSegmento: new FormArray([]),
      segundoSegmento: new FormArray([]),
    });
  }

  async ngOnInit() {
    if (this.perfilProdutor === TipoProdutorEnum.Pecuarista) {
      this.titulo = 'Selecione o(s) ano(s)';
    }
    this.tipoProducao = this.retornarTipoProducao();
    this.tipoPeriodoPrimeiroSegmento = this.perfilProdutor === TipoProdutorEnum.Pecuarista ? this.criarPeriodoPecuaria(this.anoBase)
      : this.criarPeriodoAgricola(this.anoBase);
    this.tipoPeriodoSegundoSegmento = this.criarPeriodoPecuaria(this.anoBase);
    const primeiroSegmento = this.form.get('primeiroSegmento') as FormArray;
    this.tipoPeriodoPrimeiroSegmento.forEach(() => {
      primeiroSegmento.insert(this.form.get('primeiroSegmento').value.length, this.criarFormAreaProducao());
    });
    const segundoSegmento = this.form.get('segundoSegmento') as FormArray;
    this.tipoPeriodoSegundoSegmento.forEach(() => {
      segundoSegmento.insert(this.form.get('segundoSegmento').value.length, this.criarFormAreaProducao());
    });

    loading(
      this.atividadeAreaProducaoService.buscarSolicitacoesAreaProducao(this.idSolicitacao)
        .subscribe((solicitacao) => {
          if (solicitacao.result.length) {
            solicitacao.result.forEach((areaProducao) => {
              // Agricultor e pecuarista
              if (this.perfilProdutor === TipoProdutorEnum.Agricultor_pecuarista) {
                if (areaProducao.tipoProducao === TIPO_PRODUCAO.agricultura) {
                  // Agricola
                  this.tipoPeriodoPrimeiroSegmento.forEach((agricola, index) => {
                    const ano = +agricola.nome.substring(0, 4);
                    if (ano === areaProducao.anoSafra) {
                      agricola.checked = true;
                      primeiroSegmento.controls[index].get('areaArrendada').setValue(parseDecimals(areaProducao.areaArrendada));
                      primeiroSegmento.controls[index].get('areaPropria').setValue(parseDecimals(areaProducao.areaPropria));
                    }
                  });

                } else {
                  // Pecuaria
                  this.tipoPeriodoSegundoSegmento.forEach((pecuaria, index) => {
                    const ano = +pecuaria.nome;
                    if (ano === areaProducao.anoSafra) {
                      pecuaria.checked = true;
                      segundoSegmento.controls[index].get('areaArrendada').setValue(parseDecimals(areaProducao.areaArrendada));
                      segundoSegmento.controls[index].get('areaPropria').setValue(parseDecimals(areaProducao.areaPropria));
                    }
                  });
                }
              } else if (this.perfilProdutor === TipoProdutorEnum.Agricultor) {
                // Agricultor
                this.tipoPeriodoPrimeiroSegmento.forEach((agricola, index) => {
                  const ano = +agricola.nome.substring(0, 4);
                  if (ano === areaProducao.anoSafra) {
                    agricola.checked = true;
                    primeiroSegmento.controls[index].get('areaArrendada').setValue(parseDecimals(areaProducao.areaArrendada));
                    primeiroSegmento.controls[index].get('areaPropria').setValue(parseDecimals(areaProducao.areaPropria));
                  }
                });
              } else if (this.perfilProdutor === TipoProdutorEnum.Pecuarista) {
                // Pecuaria
                this.tipoPeriodoPrimeiroSegmento.forEach((pecuaria, index) => {
                  const ano = +pecuaria.nome;
                  if (ano === areaProducao.anoSafra) {
                    pecuaria.checked = true;
                    primeiroSegmento.controls[index].get('areaArrendada').setValue(parseDecimals(areaProducao.areaArrendada));
                    primeiroSegmento.controls[index].get('areaPropria').setValue(parseDecimals(areaProducao.areaPropria));
                  }
                });
              }
            });
          }
        })
    );
  }

  selectAba(evt) {
    this.formSelecionado = evt.index;
  }

  retornarTipoProducao() {
    let botoes = [];
    if (this.perfilProdutor === IdPerfilProdutor.Agricultor) {
      botoes.push(
        {
          id: 0,
          nome: 'Agrícola'
        }
      );
    } else if (this.perfilProdutor === IdPerfilProdutor.Pecuarista) {
      botoes.push(
        {
          id: 1,
          nome: 'Pecuária'
        }
      );
    } else {
      botoes = [
        {
          id: 0,
          nome: 'Agrícola'
        },
        {
          id: 1,
          nome: 'Pecuária'
        }
      ];
    }
    return botoes;
  }

  criarPeriodoAgricola(ano) {
    // 2 anos a frente e 2 anos a atrás
    ano = +ano;
    return [
      {
        id: 0,
        nome: `${ (ano - 2) }/${ ((ano - 1).toString()).substring(2, 4) }`,
        checked: false
      },
      {
        id: 1,
        nome: `${ (ano - 1) }/${ ((ano).toString()).substring(2, 4) }`,
        checked: false
      },
      {
        id: 2,
        nome: `${ ano }/${ ((ano + 1).toString()).substring(2, 4) }`,
        checked: false
      },
      {
        id: 3,
        nome: `${ (ano + 1) }/${ ((ano + 2).toString()).substring(2, 4) }`,
        checked: false
      },
      {
        id: 4,
        nome: `${ (ano + 2) }/${ ((ano + 3).toString()).substring(2, 4) }`,
        checked: false
      }
    ];
  }

  criarPeriodoPecuaria(ano) {
    // 2 anos a frente e 2 anos a atrás
    ano = +ano;
    return [
      {
        id: 0,
        nome: `${ ano - 2 }`,
        checked: false
      },
      {
        id: 1,
        nome: `${ ano - 1 }`,
        checked: false
      },
      {
        id: 2,
        nome: `${ ano }`,
        checked: false
      },
      {
        id: 3,
        nome: `${ ano + 1 }`,
        checked: false
      },
      {
        id: 4,
        nome: `${ ano + 2 }`,
        checked: false
      }
    ];
  }

  async selecionarOpcaoPeriodo(evento) {
    if (evento.checked) {
      setTimeout(() => {
        const id = document.getElementById('safra_' + evento.id);
        this.scrollToElement(id);
      });
    }

  }

  criarFormAreaProducao(areaArrendada?, areaPropria?): FormGroup {
    return new FormGroup({
      areaArrendada: new FormControl(areaArrendada),
      areaPropria: new FormControl(areaPropria)
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  onFocusItem(tipo) {
    this.itemFocus = tipo;
  }

  limparFocus() {
    this.itemFocus = null;
  }

  async salvar() {

    const safras = [];
    // Agricola
    if (this.form.get('primeiroSegmento').value.toString().length > 0) {
      let item;
      for (const i in this.form.get('primeiroSegmento').value) {
        item = this.form.get('primeiroSegmento').value[i];
        if (this.tipoPeriodoPrimeiroSegmento[i].checked) {
          safras.push(
            {
              anoSafra: +(this.tipoPeriodoPrimeiroSegmento[i].nome.substring(0, 4)),
              areaPropria: parseTextToDouble(item.areaPropria),
              areaArrendada: parseTextToDouble(item.areaArrendada),
              tipoProducao: (this.perfilProdutor === TIPO_PRODUCAO.agricultura_pecuaria ?
                TIPO_PRODUCAO.agricultura : this.perfilProdutor) ?? TIPO_PRODUCAO.agricultura
            }
          );
        }
      }
    }

    // Pecuaria
    if (this.form.get('segundoSegmento').value.toString().length > 0) {
      let item;
      for (const i in this.form.get('segundoSegmento').value) {
        item = this.form.get('segundoSegmento').value[i];
        if (this.tipoPeriodoSegundoSegmento[i].checked) {
          safras.push(
            {
              anoSafra: +this.tipoPeriodoSegundoSegmento[i].nome,
              areaPropria: parseTextToDouble(item.areaPropria),
              areaArrendada: parseTextToDouble(item.areaArrendada),
              tipoProducao: TIPO_PRODUCAO.pecuaria
            }
          );
        }
      }
    }

    loading(
      this.atividadeAreaProducaoService.salvarSolicitacaoAreaProducao(
        {
          solicitacao: this.idSolicitacao,
          safras
        }
      ).subscribe(async () => {
        this.solicitacaoService.atualizarPreenchimentoSolicitacao(this.idSolicitacao).subscribe(() => {});
        await showToastMessage('Registro salvo com sucesso.', 'success');
        await this.modalCtrl.dismiss(true);
      }, async () => {
        await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
      })
    );

  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }
}

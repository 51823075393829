import { Injectable } from '@angular/core';
import { resolve } from '../util/resolve';

;
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CondominioAgropecuarioService {
  constructor(
    private http: HttpClient
  ) {}

  registrarCondominioAgropecuario(idSolicitacao: number, solicitacao: SolicitacaoCondominioAgropecuario): Observable<any> {
    const url = resolve('refactory://solicitacoes/idSolicitacao', { idSolicitacao });
    return this.http.patch<any>(url, solicitacao);
  }

  buscarCondominioAgropecuario(idSolicitacao: number) {
    const url = resolve('refactory://usuarioCondominio/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }
}

export interface SolicitacaoCondominioAgropecuario {
  solicitacao?: number;
  percentualSocietarioSolicitante?: number;
  nomeCondominio?: string;
  outrosParticipantes?: [
    {
      cpf?: string,
      nome?: string,
      cnpj?: string,
      razaoSocial?: string,
      percentualSocietario?: number
    }
  ];
}


import { URL_BASE_API, URL_BUCKET } from 'src/app/util/constants';
import { AppEnvironment, merge } from 'src/environments/environment.common';

export const production = false;

export const environment: AppEnvironment = merge({
  production: false,
  appVersion: require('../../package.json').version,
  hosts: {
    bucket: {
      host: URL_BUCKET,
    },
    autenticacao: {
      host: URL_BASE_API,
    },
    usuario: {
      host: URL_BASE_API,
    },
    formulario: {
      host: URL_BASE_API,
    },
    refactory: {
      host: URL_BASE_API
    }
  },
  values: {
    'link-esqueci-senha': ''
  }
});

import { Injectable } from '@angular/core';
import { Router, CanLoad, Route } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanLoad {

  constructor(private router: Router) {
  }

  canLoad(route: Route): boolean {


    const isLogin = !!localStorage.getItem('TERRALOGS_LOGIN');
    if (!isLogin) {
      this.router.navigate([ '/login' ]);
      return false;
    }

    return true;
  }
}

import { ChangeDetectorRef, Directive, Host, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { get } from 'lodash';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ApresentarDadosDirective, CasoResultadoContext } from './apresentar-dados.directive';
import { TesteVazio } from './dados';

/**
 * Declara que o resultado do `[appApresentarDados]` deve ser testado se está
 * vazio. Caso vazio, a diretiva `*casoResultado` deixa de ser apresentada,
 * e a diretiva `*casoVazio` é mostrada no lugar.
 *
 * Formas de uso:
 *
 * * `testeVazio` – verifica se o resultado do `apresentarDados` é vazio
 * * `testeVazio="itens"` – verifica se a propriedade `itens` do resultado
 *   está vazia
 * * `[testeVazio]="fnTeste"` – invoca a função passada, e se ela retornar
 *   `true` o resultado é considerado vazio
 */
@Directive({
  selector: '[appApresentarDados][testeVazio]',
})
export class TesteVazioDirective<T> {
  constructor(
    @Host() private apresentarDados: ApresentarDadosDirective<T>,
  ) {}

  @Input() set testeVazio(value: string | string[] | TesteVazio<T>) {
    this.apresentarDados.testeVazio.next(typeof value === 'function' ? value : (resultado) => {
      const valor = value ? get(resultado, value) : resultado;
      return Array.isArray(valor) ? !valor.length : !valor;
    });
  }
}

/**
 * Bloco apresentado quando o Observable emite um resultado vazio.
 *
 * Para usar esta diretiva, é necessário definir um teste de coleção vazia
 * usando `[testeVazio]`.
 *
 * É possível saber se o Observable ainda não terminou de carregar através da
 * variável `carregando`.
 *
 * @example
 * ```html
 *  <ng-container [appApresentarDados]="infoAsync" testeVazio="topicos">
 *    <ng-container *casoConteudo="let info">
 *      <h1>Tópicos</h1>
 *      <p *ngFor="let topico of info.topicos">{{topico|json}}</p>
 *    </ng-container>
 *    <ng-container *casoVazio>
 *      <p>Nenhum tópico encontrado</p>
 *    </ng-container>
 *    ...
 *  </ng-container>
 * ```
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[casoVazio]',
})
export class CasoVazioDirective<T> implements OnDestroy, OnInit {
  private subscription!: Subscription;
  private context?: CasoResultadoContext<T>;

  constructor(
    @Host() private apresentarDados: ApresentarDadosDirective<T>,
    private cd: ChangeDetectorRef,
    private templateRef: TemplateRef<CasoResultadoContext<T>>,
    private viewContainer: ViewContainerRef,
    // tslint:disable-next-line: variable-name
    @Host() _testeVazio: TesteVazioDirective<T>,
  ) {}

  ngOnInit() {
    this.subscription = this.apresentarDados.comStatus.pipe(
      distinctUntilChanged((x, y) => x.vazio === y.vazio && x.carregando === y.carregando),
    ).subscribe((x) => {
      if (x.vazio) {
        if (!this.context) {
          this.context = {
            // tslint:disable-next-line: no-non-null-assertion
            $implicit: x.resultado!,
            carregando: x.carregando,
          };
          this.viewContainer.createEmbeddedView(this.templateRef, this.context);
        } else {
          // tslint:disable-next-line: no-non-null-assertion
          this.context.$implicit = x.resultado!;
          this.context.carregando = x.carregando;
        }
      } else if (this.context) {
        this.viewContainer.clear();
        this.context = undefined;
      }
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

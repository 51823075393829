import { concat, defer, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const ERRO_DESCONHECIDO = new Error('?');

export interface IncluindoStatus<T> {
  timestamp: number;
  carregando: boolean;
  resultado?: T;
  erro?: any;
}

export function incluirStatus<T>(inicial?: T, absorverErros = true) {
  return (obs: Observable<T>): Observable<IncluindoStatus<T>> => {
    let ultimo = inicial;

    const incluindoStatus = concat<IncluindoStatus<T>>(
      defer(() => of({timestamp: Date.now(), carregando: true, resultado: inicial})),
      obs.pipe(
        map((resultado) => {
          ultimo = resultado;
          return {timestamp: Date.now(), carregando: true, resultado};
        }),
      ),
      defer(() => of({timestamp: Date.now(), carregando: false, resultado: ultimo})),
    );

    if (!absorverErros) {
      return incluindoStatus;
    }

    return incluindoStatus.pipe(
      catchError((erro) => of<IncluindoStatus<T>>({
        timestamp: Date.now(),
        carregando: false,
        erro: erro === undefined ? ERRO_DESCONHECIDO : erro,
      })),
    );
  };
}

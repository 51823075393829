import { Injector, Type } from '@angular/core';
// import { injector } from 'main';

let injector = window['injector'];

const getInjector = () => {
  return new Promise<Injector>((resolve) => {
    const check = () => {
      injector = window['injector'];
      if (injector) {
        resolve(injector);
      } else {
        setTimeout(check, 100);
      }
    };
    check();
  });
};

const inject = <T>(type: Type<T>) => {
  return getInjector().then(injector => injector.get(type));
};

export { inject };

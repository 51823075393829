import { FormModelAny } from '@zellotec/terralogs_regras/models';

/** @deprecated Use `@zellotec/terralogs_regras/models` */
export type FormModel = FormModelAny;

export { IdObj } from '@zellotec/terralogs_regras/models';

export enum Domains {
  ComumEstados = 'Comum_estados',
  ComumMunicipios = 'Comum_municipios',
  DadosSolicitanteResidenciaMunicipio = 'Dados_solicitante_residencia_municipio',
  DadosSolicitanteAtividadePrincipal = 'Dados_solicitante_atividade_principal',
  DadosSolicitanteTempoExercicio = 'Dados_solicitante_tempo_exercicio',
  DadosSolicitanteAssociacaoCooperativa = 'Dados_solicitante_associacao_cooperativa',
  DadosSolicitanteParticipacaoGrupoClube = 'Dados_solicitante_participacao_grupo_clube',
  DadosSolicitanteEstadoCivil = 'Dados_solicitante_estado_civil',
  DadosSolicitanteRegimeCasamento = 'Dados_solicitante_regime_casamento',
  DadosSolicitanteSucessaoFamiliar = 'Dados_solicitante_sucessao_familiar',
  DadosSolicitantePessoaPoliticamenteExposta = 'Dados_solicitante_pessoa_politicamente_exposta',
  DadosSolicitanteTipoFinanciamento = 'Dados_solicitante_tipo_financiamento',
  DadosSolicitanteFinalidadeFinanciamento = 'Dados_solicitante_finalidade_financiamento',
  DadosSolicitanteFinalidadeFinanciamentoCusteio = 'Dados_solicitante_finalidade_financiamento_custeio',
  DadosSolicitanteFinalidadeFinanciamentoInvestimento = 'Dados_solicitante_finalidade_financiamento_investimento',
  DadosSolicitanteFinalidadeFinanciamentoComercializacao = 'Dados_solicitante_finalidade_financiamento_comercializacao',
  DadosSolicitantePrazoPagamento = 'Dados_solicitante_prazo_pagamento',
  DadosSolicitantePrazoCarencia = 'Dados_solicitante_prazo_carencia',
  CreditoTipoFinanciamento = 'Credito_tipo_financiamento',
  CreditoFinalidadeFinanciamento = 'Credito_finalidade_financimento',
  CreditoFinalidadeFinanciamentoCusteio = 'Credito_finalidade_financimento_custeio',
  CreditoFinalidadeFinanciamentoInvestimento = 'Credito_finalidade_financimento_investimento',
  CreditoFinalidadeFinanciamentoComercializacao = 'Credito_finalidade_financimento_comercializacao',
  CreditoPrazoPagamento = 'Credito_prazo_pagamento',
  CreditoPrazoCarencia = 'Credito_prazo_carencia',
  ProducaoAgriculturaCulturaPrincipal = 'Producao_agricultura_cultura_principal',
  ProducaoAgriculturaTipo = 'Producao_agricultura_tipo',
  ProducaoAgriculturaSafra = 'Producao_agricultura_safra',
  ProducaoAgriculturaCulturaSecundaria = 'Producao_agricultura_cultura_secundaria',
  ProducaoPecuariaCicloProdutivoPecuario = 'Producao_pecuaria_ciclo_produtivo_pecuario',
  ProducaoPecuariaCicloProdutivoTipoTerminacao = 'Producao_pecuaria_ciclo_produtivo_tipo_terminacao',
  ProducaoArmazenagem = 'Producao_armazenagem',
  ProducaoAtividadeMunicipioTipoCulturaProduzidas = 'Producao_atividade_municipio_tipo_cultura_produzidas',
  ProducaoFornecedoresPrincipaisForneedores = 'Producao_fornecedores_principais_fornecedores',
  ProducaoClientesPrincipaisClientes = 'Producao_clientes_principais_clientes',
  ProducaoClientesTradings = 'Producao_clientes_tradings',
  ProducaoSelosCertificacoes = 'Producao_selos_certificacoes',
  ProducaoIniciativasSocioambientais = 'Producao_iniciativas_socioambientais',
  ProducaoSeguroRural = 'Producao_seguro_rural'
}

export const pastas = [
  'DOCUMENTOS PESSOAIS',
  'DOCUMENTOS CONTABEIS FINANCEIROS',
  'DOCUMENTOS DAS GARANTIAS',
  'DOCUMENTOS DAS PROPRIEDADES',
  'IMAGENS',
  'MANDADO TERRALOGS',
]

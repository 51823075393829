import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formatarQuantidade]'
})
export class FormatarQuantidadeDirective {

  @Input() public formatarQuantidade: any;


  constructor(private el: ElementRef,
              private control: NgControl) {

  }

  // @HostListener('keyup', ['$event'])
  // @HostListener('paste', ['$event'])
  // @HostListener('input', ['$event'])
  // onKeyUp(event) {
  //   const val = this.formatPrice(this.control.value);
  //   this.control.control.setValue(val);
  // }

  // onPaste(event) {
  //   const val = this.formatPrice(this.control.value);
  //   this.control.control.setValue(val);
  // }
  ngOnInit(): void {
    if (this.formatarQuantidade) {

      this.formatarQuantidade.valueChanges.subscribe((val) => {
        setTimeout(() => {
          const val = this.formatPrice(this.control.value);
          this.control.control.setValue(val);
        }, 180);
      });
      //
      // if (this.control.value) {
      //   const val = this.formatPrice(this.control.value);
      //   this.control.control.setValue(val);
      // }
    }
  }


  formatPrice(str) {

    let val;
    let decimal;
    if (!str) {
      return;
    }

    if (typeof str != 'string') {
      str = str.toString().replace('.', ',');
    }

    if (str.search(/[,]/g) >= 0) {
      decimal = ',' + str.split(',')[1];
      val = str.split(',')[0];
    } else {
      val = str.replace(/\D/gi, '');
    }

    const reverse = val.toString().split('').reverse().join('');
    const thousands = reverse.match(/\d{1,3}/g);

    if (decimal) {
      val = thousands.join('.').split('').reverse().join('') + decimal;
    } else {
      val = thousands.join('.').split('').reverse().join('');
    }

    return val;
  }

  formatPrice2(str) {
    return str && str.toString().replace(/D/g, '').replace(/s(?=d)/g, '').replace(/(d)(?=(d{3})+(.|$|D))/g, '$1 ') || '';
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SolicitarAlteracaoPessoalComponent } from './solicitar-alteracao-pessoal.component';
import { BrMaskerModule } from 'br-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    BrMaskerModule,
  ],
  exports: [
    SolicitarAlteracaoPessoalComponent
  ],
  declarations: [SolicitarAlteracaoPessoalComponent]
})
export class SolicitarAlteracaoPessoalComponentModule {
}

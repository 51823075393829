import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { resolve } from 'src/app/util/resolve';
import { Params } from '@angular/router';
import { pick } from 'lodash';
import { mapParamsPaginacao, mapRetornoApiPaginado, Pagina } from '../util/pagination';
import { Usuario } from '@zellotec/terralogs_regras/models';
import { ParamsPaginacao, RetornoApiPaginado } from '../models/api';
import { PerfilEnum } from '../enums/perfil.enum';
import { removerAcentos } from '../util/utils';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  perfis: any = {};

  constructor(private http: HttpClient) {
    this.perfis[PerfilEnum.Superadmin] = 'Superadmin';
    this.perfis[PerfilEnum.Admin] = 'Administrador';
    this.perfis[PerfilEnum.Atendente] = 'Atendente';
    this.perfis[PerfilEnum.Parceiro] = 'Parceiro';
    this.perfis[PerfilEnum.Solicitante] = 'Solicitante';
  }

  obterParamsGetUsuarios(queryParams: Params): ParamsGetUsuarios {
    return {
      ...pick(queryParams, [ 'name', 'idPerfil' ]),
      ...mapParamsPaginacao()(queryParams),
    };
  }

  countGetUsuarios(params?: ParamsGetUsuarios): Observable<any> {
    const query = { ...params };
    if (query && query.name) {
      query.name = removerAcentos(query.name);
    }
    const url = resolve('refactory://usuarios/count');
    return this.http.get<any>(url, {
      params: { ...query },
    });
  }

  buscarUsuarios(params: ParamsGetUsuarios): Observable<Pagina<Usuario>> {
    const url = resolve('refactory://usuarios');
    return this.http.get<RetornoUsuario>(url, {
      params: { ...params },
    }).pipe(
      map((resultado: any) => {
        for (const usuario of resultado.data.items) {
          usuario.perfilNome = this.perfis[usuario.perfil];
        }
        return resultado;
      }),
      mapRetornoApiPaginado('items', params),
    );
  }

  pessoaJuridicaPorId(idPessoaJuridica: number): Observable<any> {
    const url = resolve('refactory://solicitacao/pessoa-juridica', { idPessoaJuridica });
    return this.http.get<any>(url).pipe(
      map((result: any) => result.data.result)
    );
  }

  buscarUsuarioId(idUsuario: number): Observable<any> {
    const url = resolve('refactory://usuarios/idUsuario', { idUsuario });
    return this.http.get<any>(url);
  }

  buscarUsuariosPorNome(params: ParamsGetUsuarios) {
    const url = resolve('refactory://usuarios/porNome');
    return this.http.get<RetornoUsuario>(url, {
      params: { ...params },
    });
  }

  buscarUsuariosPorParceiro(params: ParamsGetUsuarios) {
    const url = resolve('refactory://usuarios/porParceiro');
    return this.http.get<RetornoUsuario>(url, {
      params: { ...params },
    });
  }

  buscarUsuariosPorCPF(cpf: string) {
    const url = resolve('refactory://usuarios/porCPF', { cpf });
    return this.http.get<RetornoUsuario>(url).pipe(
      map((result: any) => result.data.result)
    );
  }

  buscarUsuarioLogado(): Usuario {
    const usuarioLogado = localStorage.getItem('TERRALOGS-USER');
    return JSON.parse(usuarioLogado);
  }

  async getCurrent() {
    const url = resolve('autenticacao://current');
    return this.http.get(url).toPromise();
  }

  buscarPorPerfil(ids: any): Observable<Usuario[]> {
    const url = resolve('refactory://usuarios/porPerfil', { idsPerfil: ids });
    return this.http.get<Usuario>(url, ids).pipe(
      map((result: any) => result.items)
    );
  }

  adicionar(usuario: Usuario): Observable<Usuario> {
    const url = resolve('refactory://usuarios');
    return this.http.post<Usuario>(url, usuario).pipe(pluck('data', 'result'));
  }

  alterar(usuario: Usuario, idUsuario: number): Observable<Usuario> {
    const url = resolve('refactory://usuarios/porUsuario', { idUsuario });
    return this.http.patch<Usuario>(url, usuario).pipe(pluck('data', 'result'));
  }

  excluir(idUsuario: number): Observable<Usuario> {
    const url = resolve('refactory://usuarios/porUsuario', { idUsuario });
    return this.http.delete<Usuario>(url);
  }

  resetPassword(email) {
    return this.http.post<any>(resolve('refactory://resetpassword'), email).toPromise();
  }

  changePassword(obj): Observable<any[]> {
    const url = resolve('refactory://changepassword');
    return this.http.post<any>(url, obj).pipe(
      catchError(err => {
        if (err.error) {
          if (err.error.result.code === '401') {
            return throwError(err.error.result.message);
          } else if (err.error.result.code === '400') {
            return throwError(err.error.result.message);
          } else if (err.error.result.code === '404') {
            return throwError(err.error.result.message);
          } else {
            return throwError('Não foi possível executar a operação.');
          }
        }
      })
    );
  }

  notaAtendente(): Observable<any> {
    const url = resolve('refactory://notaAtendente');
    return this.http.get<any>(url).pipe(pluck('data', 'result'));
  }

  buscarPorCpfPerfil(cpf: string, perfil: number) {
    const url = resolve('refactory://usuarios/buscar/cpfPerfil', { cpf, perfil });
    return this.http.get<RetornoUsuario>(url).pipe(
      map((result: any) => result.data.result)
    );
  }

  buscarAtendenteAdmin() {
    const url = resolve('refactory://usuarios/buscar/atendentes');
    return this.http.get<RetornoUsuario>(url).pipe(
      map((result: any) => result.data.items)
    );
  }
}

export type RetornoUsuario = RetornoApiPaginado<'items', Usuario>;

export interface ParamsGetUsuarios extends ParamsPaginacao {
  name?: string;
  ids?: string;
  cpf?: string;
}

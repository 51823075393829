import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { DominioService } from 'src/app/services/dominio.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Estado, Municipio } from '@zellotec/terralogs_regras/models';
import { distinctUntilChanged } from 'rxjs/operators';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import { showToastMessage } from '../../../util/utils';
import { ArmazenagemService, TIPO_ARMAZENAGEM, TipoArmazenagem } from '../../../services/armazenagem.service';
import { loading } from '../../../util/loading';

@Component({
  selector: 'app-armazenagem',
  templateUrl: './armazenagem.component.html',
  styleUrls: [ './armazenagem.component.scss' ],
})
export class ArmazenagemComponent implements OnInit {

  customPopoverOptions: any = {
    subHeader: 'Selecione uma opção',
  };

  form: FormGroup;
  estados: Estado[] = [];
  municipios: Municipio[] = [];
  tipoArmazenagem: TipoArmazenagem[] = [];
  itemFocus: string;
  NAO_ARMAZENO = TIPO_ARMAZENAGEM.nao_armazeno;

  @Input() idSolicitacao: number;

  constructor(private modalCtrl: ModalController,
              public popoverCtrl: PopoverController,
              private armazenagemService: ArmazenagemService,
              private dominioService: DominioService) {
    this.form = new FormGroup({
      armazenagem: new FormControl(''),
      ufCampo: new FormControl(''),
      municipioCampo: new FormControl(''),
      idUfCampo: new FormControl(''),
      idMunicipioCampo: new FormControl(''),
      nomeFazendaCampo: new FormControl(''),
      locaisArmazenagem: new FormArray([]),
    });
  }

  criarFormArmazenagem(uf, municipio, nomeFazenda, idUf, idMunicipio): FormGroup {
    return new FormGroup({
      uf: new FormControl(uf),
      municipio: new FormControl(municipio),
      nomeFazenda: new FormControl(nomeFazenda),
      idUf: new FormControl(idUf),
      idMunicipio: new FormControl(idMunicipio),
    });
  }

  async ngOnInit() {
    this.armazenagemService.buscarSolicitacaoArmazenagem(this.idSolicitacao)
      .subscribe((solicitacao) => {
        const formArrayDinamico = this.form.get('locaisArmazenagem') as FormArray;
        solicitacao.result.forEach((armazenagem) => {
          this.form.get('armazenagem').setValue(armazenagem.tipoArmazenagem.id);
          if (armazenagem.tipoArmazenagem.id !== TIPO_ARMAZENAGEM.nao_armazeno) {
            if (armazenagem.estado || armazenagem.municipio || armazenagem.nomeFazenda) {
              formArrayDinamico.insert(this.form.get('locaisArmazenagem').value.length, this.criarFormArmazenagem(
                armazenagem?.estado?.nome,
                armazenagem.municipio?.nome,
                armazenagem?.nomeFazenda,
                armazenagem?.estado?.id,
                armazenagem?.municipio?.id)
              );
            }
          }
        });
      });

    this.armazenagemService.buscarTipoArmazenamento().subscribe((tipo) => {
      this.tipoArmazenagem = tipo;
    });
    this.buscarUf();
  }

  buscarUf() {
    this.dominioService.buscarEstados().subscribe((result) => {
      this.estados = result;
    });
  }

  alterarArmazenagem(evento: any) {
    const id = +evento.detail.value;
    if (id === TIPO_ARMAZENAGEM.nao_armazeno) {
      const formArrayDinamico = this.form.get('locaisArmazenagem') as FormArray;
      formArrayDinamico.clear();
    }
  }

  async novaLocalArmazenagem() {
    if (this.form.get('ufCampo').value === '' && this.form.get('municipioCampo').value === '' && this.form.get('nomeFazendaCampo').value === '') {
      return await showToastMessage('É necessário informar a UF, município e nome da armazenagem.', 'danger');
    }
    const formArrayDinamico = this.form.get('locaisArmazenagem') as FormArray;
    formArrayDinamico.insert(this.form.get('locaisArmazenagem').value.length, this.criarFormArmazenagem(
      this.form.get('ufCampo').value,
      this.form.get('municipioCampo').value,
      this.form.get('nomeFazendaCampo').value,
      this.form.get('idUfCampo').value,
      this.form.get('idMunicipioCampo').value)
    );

    this.form.get('ufCampo').setValue('');
    this.form.get('municipioCampo').setValue('');
    this.form.get('nomeFazendaCampo').setValue('');
    this.form.get('idMunicipioCampo').setValue(null);
    this.form.get('idUfCampo').setValue(null);

    setTimeout(() => {
      const id = document.getElementById('local_' + (formArrayDinamico.length - 1));
      this.scrollToElement(id);
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  async delLocalArmazenagem(index: number) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Local!',
        mensagem: 'Tem certeza que deseja excluir este local de armazenagem',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const formArrayDinamico = this.form.get('locaisArmazenagem') as FormArray;
      formArrayDinamico.removeAt(index);
    }
  }

  selectUF(data, tipo, formName?) {
    this.dominioService.buscarMunicipios(data.id).pipe(
      distinctUntilChanged(),
    ).subscribe(res => {
      this.municipios = res;
    });
    if (tipo === 'dinamico') {
      this.form.get('locaisArmazenagem').get(formName.toString()).get('uf').setValue(data.sigla);
      this.form.get('locaisArmazenagem').get(formName.toString()).get('idUf').setValue(data.id);
      this.form.get('locaisArmazenagem').get(formName.toString()).get('municipio').setValue('');
      this.form.get('locaisArmazenagem').get(formName.toString()).get('idMunicipio').setValue('');
    } else {
      this.form.get('ufCampo').setValue(data.sigla);
      this.form.get('idUfCampo').setValue(data.id);
      this.form.get('municipioCampo').setValue('');
      this.form.get('idMunicipioCampo').setValue('');
    }
  }

  limparMunicipios() {
    this.municipios = [];
  }

  onFocusItem(tipo) {
    this.itemFocus = tipo;
  }

  limparFocus() {
    this.itemFocus = null;
  }

  selectMunicipio(municipio, tipo, formName?) {
    if (tipo === 'dinamico') {
      this.form.get('locaisArmazenagem').get(formName.toString()).get('municipio').setValue(municipio.nome);
      this.form.get('locaisArmazenagem').get(formName.toString()).get('idMunicipio').setValue(municipio.id);
    } else {
      this.form.get('municipioCampo').setValue(municipio.nome);
      this.form.get('idMunicipioCampo').setValue(municipio.id);
    }
  }

  async salvar() {
    if (!this.form.get('armazenagem').value) {
      return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
    }

    const locais = [];
    if (this.form.get('locaisArmazenagem')['controls'] && this.form.get('locaisArmazenagem')['controls'].length > 0) {
      for (const local of this.form.get('locaisArmazenagem')['controls']) {
        if (local.value.nomeFazenda || local.value.idMunicipio || local.value.idUf) {
          local.value.nomeFazenda = local.value.nomeFazenda ? local.value.nomeFazenda : '';
          local.value.idMunicipio = local.value.idMunicipio ? local.value.idMunicipio : null;
          local.value.idUf = local.value.idUf ? local.value.idUf : null;

          locais.push(
            {
              estado: local.value.idUf,
              municipio: local.value.idMunicipio,
              nomeFazenda: local.value.nomeFazenda,
            }
          );
        }
      }
    }

    const idUf = this.form.get('idUfCampo').value;
    const idMunicipio = this.form.get('idMunicipioCampo').value;
    const nomeFazenda = this.form.get('nomeFazendaCampo').value;
    if (idUf || idMunicipio || nomeFazenda) {
      locais.push(
        {
          estado: this.form.get('idUfCampo').value,
          municipio: this.form.get('idMunicipioCampo').value,
          nomeFazenda: this.form.get('nomeFazendaCampo').value
        }
      );
    }

    const obj = {
      solicitacao: this.idSolicitacao,
      tipoArmazenagem: this.form.get('armazenagem').value,
      armazens: locais
    };

    loading(
      this.armazenagemService.registrarArmazenagem(obj)
        .subscribe(async () => {
          await showToastMessage('Registro salvo com sucesso.', 'success');
          await this.modalCtrl.dismiss(true);
        }, async () => {
          await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
        })
    );
  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }
}

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { resolve } from 'src/app/util/resolve';
import { map } from 'rxjs/operators';
import { UnidadeMedida } from '@zellotec/terralogs_regras/models/unidade-medida';

@Injectable({
  providedIn: 'root'
})
export class UnidadeMedidaService {

  constructor(private http: HttpClient) {
  }

  buscarUnidadeMedida() {
    const url = resolve('refactory://unidadeMedida');
    return this.http.get<UnidadeMedida>(url).pipe(
      map( (result: any) => result.items),
    );
  }

}

import * as _ from 'lodash';
import {
  APP_BACKEND_URL,
  URL_BASE_API,
  PASSWORD_TERRALOGS,
  USER_TERRALOGS,
  URL_BUCKET
} from 'src/app/util/constants';

/* Formato de especificação de env. Esse é o arquivo base e deve
   conter conteúdo usado por todos os ambientes. Os ambientes específicos
   devem sobrescrever o que for necessário */


export interface AppHostConfig {
  host?: string;
  protocol?: string;
  port?: string;
  root?: string;
  unauthenticated?: boolean;
  endpoints?: {
    [name: string]: string;
  };
}

export interface ValueConfig {
  [name: string]: string | number | ValueConfig;
}

export interface AppEnvironment {
  appVersion?: string;
  production: boolean;
  time?: number;
  default?: string;
  app?: 'terralogs-adm';
  hosts?: {
    [name: string]: AppHostConfig
  };
  values?: ValueConfig;
  clientId?: string;
  clientSecret?: string;
  terralogs?: {
    email: string,
    password: string
  };
}

// tslint:disable: object-literal-key-quotes
export const environment: AppEnvironment = {
  production: false,
  default: 'terralogs-adm',
  app: 'terralogs-adm',
  hosts: {
    bucket: {
      host: URL_BUCKET,
      protocol: 'https',
    },
    refactory: {
      host: URL_BASE_API,
      protocol: 'https',
      port: '',
      root: '/',
      endpoints: {
        'login': 'auth/login',
        'solicitacoes': 'solicitacoes',
        'solicitacoes/count': 'solicitacoes/aux/countOperacoes',
        'solicitacoes/idSolicitacao': 'solicitacoes/:idSolicitacao',
        'notaAtendente': 'solicitacoes/nota/atendente',
        'solicitacaoCulturaAtividade': 'solicitacao-cultura-atividade',
        'solicitacaoIniciativasSocioambientais': 'solicitacao-iniciativas-socioambientais',
        'solicitacaoIniciativasSocioambientaisOutros': 'solicitacao-iniciativas-socioambientais-outros',
        'solicitacaoIniciativasSocioambientais/idSolicitacao': 'solicitacao-iniciativas-socioambientais/solicitacao/:idSolicitacao',
        'solicitacaoSeguroRural': 'solicitacao-seguro-rural',
        'solicitacaoSeguroRuralOutros': 'solicitacao-seguro-rural-outros',
        'solicitacaoSeguroRural/idSolicitacao': 'solicitacao-seguro-rural/solicitacao/:idSolicitacao',
        'solicitacaoFornecedorCliente': 'solicitacao-fornecedor-cliente',
        'solicitacaoFornecedorClienteOutros': 'solicitacao-fornecedor-cliente-outros',
        'solicitacaoFornecedorCliente/idSolicitacao': 'solicitacao-fornecedor-cliente/solicitacao/:idSolicitacao',
        'solicitacaoCertificado': 'solicitacao-certificado',
        'solicitacaoCertificadoOutros': 'solicitacao-certificado-outros',
        'solicitacaoCertificado/idSolicitacao': 'solicitacao-certificado/solicitacao/:idSolicitacao',
        'solicitacaoAtividadeMunicipio': 'solicitacao-atividade-municipio',
        'solicitacaoAtividadeMunicipio/idSolicitacao': 'solicitacao-atividade-municipio/solicitacao/:idSolicitacao',
        'solicitacaoAtividadeMunicipioPorId/id': 'solicitacao-atividade-municipio/:id',
        'solicitacaoArmazenagem': 'solicitacao-armazenagem',
        'solicitacaoArmazenagem/idSolicitacao': 'solicitacao-armazenagem/solicitacao/:idSolicitacao',
        'solicitacaoAreaProducao': 'solicitacao-area-producao',
        'solicitacaoAreaProducao/idSolicitacao': 'solicitacao-area-producao/solicitacao/:idSolicitacao',
        'solicitacaoAreaProducao/areaProducao/idSolicitacao': 'solicitacoes/area-producao/solicitacao/:idSolicitacao',
        'solicitacaoQuadroSafra': 'solicitacao-quadro-safra',
        'solicitacaoQuadroSafra/idSolicitacao': 'solicitacao-quadro-safra/solicitacao/:idSolicitacao',
        'solicitacaoQuadroSafra/id': 'solicitacao-quadro-safra/:id',
        'solicitacaoPecuariaCorte': 'solicitacao-pecuaria-bovina-corte',
        'solicitacaoPecuariaCorte/idPecuariaCorte': 'solicitacao-pecuaria-bovina-corte/:idPecuariaCorte',
        'solicitacaoPecuariaCorte/porSolicitacao/idSolicitacao': 'solicitacao-pecuaria-bovina-corte/solicitacao/:idSolicitacao',
        'solicitacaoCalculoTotalPecuaria/idSolicitacao': 'solicitacoes/calculoTotalPecuaria/:idSolicitacao',
        'solicitacaoFaturamento/idSolicitacao': 'solicitacoes/faturamento/:idSolicitacao',
        'solicitacaoCalculoQuadroSafra/idSolicitacao': 'solicitacoes/calculoQuadroSafra/:idSolicitacao',
        'solicitacaoLimparProducao/idSolicitacao': 'solicitacoes/limparProducao/:idSolicitacao',
        'solicitacao/pessoa-juridica': 'solicitacoes-pessoa-juridica/:idPessoaJuridica',
        'requisicoes': 'requisicoes',
        'requisicoes/count': 'requisicoes/aux/countRequisicoes',
        'requisicoes/idRequisicao': 'requisicoes/:idRequisicao',
        'requisicoes/avaliar/parceiro/idRequisicao': 'requisicoes/avaliar/parceiro/:idRequisicao',
        'notificacoes': 'notificacoes',
        'notificacoes/count': 'notificacoes/aux/countNotificacoes',
        'notificacoes/idNotificacao': 'notificacoes/:idNotificacao',
        'notificacoesPorUsuario': 'notificacoes-usuario',
        'notificacoesPorUsuarioQuantidade': 'notificacoes-usuario/quantidade',
        'notificacoesPorUsuario/idNotificacao': 'notificacoes-usuario/:idNotificacao',
        'requisicoesResponsavel/idRequisicao': 'requisicoes/responsavel/:idRequisicao',
        'requisicoesDadosPessoais/idRequisicao': 'requisicoes/atualizar-dados-pessoais/:idRequisicao',
        'alteracaoSolicitada': 'alteracoes-solicitadas',
        'alteracaoSolicitada/idAlteracao': 'alteracoes-solicitadas/:idAlteracao',
        'alteracaoSolicitadaPorSolicitacao/idSolicitacao': 'alteracoes-solicitadas/solicitacao/:idSolicitacao',
        'usuarios': 'usuarios',
        'operacoesCredito': 'operacoes-credito',
        'operacoesCredito/idOperacao': 'operacoes-credito/:idOperacao',
        'operacoesCreditoPorSolicitacao/idSolicitacao': 'operacoes-credito/solicitacao/:idSolicitacao',
        'instituicoes': 'instituicoes-credito',
        'instituicoes/idInstituicao': 'instituicoes-credito/:idInstituicao',
        'usuarios/count': 'usuarios/aux/countUsuarios',
        'usuarios/porUsuario': 'usuarios/:idUsuario',
        'usuarios/porPerfil': 'usuarios/buscar/perfis?:idsPerfil',
        'usuarios/porNome': 'usuarios/buscar/nome',
        'usuarios/porParceiro': 'usuarios/buscar/solicitante-parceiro',
        'usuarios/porCPF': 'usuarios/cpf/:cpf',
        'usuarios/buscar/cpfPerfil': 'usuarios/buscar/cpfPerfil/:cpf/:perfil',
        'usuarios/buscar/atendentes': 'usuarios/buscar/atendentes',
        'usuarios/idUsuario': 'usuarios/:idUsuario',
        'historicos': 'historicos',
        'historicos/idHistorico': 'historicos/:idHistorico',
        'historicos/idSolicitacao': 'historicos/solicitacao/:idSolicitacao',
        'referencias': 'referencias-comerciais',
        'referencias/idReferencia': 'referencias-comerciais/:idReferencia',
        'referencias/idSolicitacao': 'referencias-comerciais/solicitacao/:idSolicitacao',
        'endividamentos': 'endividamento',
        'endividamentos/idEndividamento': 'endividamento/:idEndividamento',
        'ativoEstoques': 'ativo-estoque',
        'ativoEstoques/idAtivoEstoque': 'ativo-estoque/:idAtivoEstoque',
        'ativoRecebiveis': 'ativo-recebivel',
        'ativoRecebiveis/idAtivoRecebivel': 'ativo-recebivel/:idAtivoRecebivel',
        'ativoPenhorSafra': 'ativo-penhor-safra',
        'ativoPenhorSafra/idAtivoPenhorSafra': 'ativo-penhor-safra/:idAtivoPenhorSafra',
        'ativoAval': 'ativo-aval',
        'ativoAval/idAtivoAval': 'ativo-aval/:idAtivoAval',
        'ativoPropriedadesRurais': 'ativo-propriedade-rural',
        'ativoPropriedadesRurais/idAtivoPropriedadeRural': 'ativo-propriedade-rural/:idAtivoPropriedadeRural',
        'ativos': 'ativo',
        'ativos/idAtivo': 'ativo/:idAtivo',
        'ativos/garantias/idSolicitacao': 'ativo/solicitacao/garantia/:idSolicitacao',
        'armazens': 'ativo-armazem',
        'armazens/idArmazem': 'ativo-armazem/:idArmazem',
        'imoveisUrbanos': 'ativo-imoveis-urbanos',
        'imoveisUrbanos/idImovelUrbano': 'ativo-imoveis-urbanos/:idImovelUrbano',
        'maquinarios': 'ativo-maquinarios',
        'maquinarios/idMaquinario': 'ativo-maquinarios/:idMaquinario',
        'participacaoAcionaria': 'ativo-participacoes-acionarios',
        'participacaoAcionaria/idParticipacao': 'ativo-participacoes-acionarios/:idParticipacao',
        'aplicacaoFinanceira': 'ativo-aplicacoes-financeiras',
        'aplicacaoFinanceira/idAplicacao': 'ativo-aplicacoes-financeiras/:idAplicacao',
        'semovente': 'ativo-semoventes',
        'semovente/idSemovente': 'ativo-semoventes/:idSemovente',
        'estados': 'estados',
        'municipios/idEstado': 'municipios/estado/:idEstado',
        'financiamentos': 'financiamentos',
        'finalidades/idFinanciamento': 'finalidades/financiamento/:idFinanciamento',
        'producaoSafra': 'producao-safra',
        'tiposAtividade/principal': 'tipo-atividade?principal=:isPrincipal',
        'tiposAtividade/idCategoria': 'tipo-atividade/categoria/:idCategoria',
        'tiposAtividadeCategoria/idCategoria': 'atividade-categoria/categoria/:idCategoria',
        'tiposModalidadeDivida': 'tipo-modalidade-divida',
        'tiposGaratia': 'tipo-garantia',
        'tiposAnimais': 'tipo-animal',
        'tiposFormaPagamento': 'tipo-forma-pagamento',
        'tiposMoeda': 'tipo-moeda',
        'tiposSolo': 'tipo-solo',
        'tipoTitulos': 'tipo-titulo',
        'tipoCulturas': 'cultura',
        'tipoAgricultura': 'tipo-agricultura',
        'tipoCancelamento': 'tipo-cancelamento',
        'statusTransacaoCredito': 'status-transacao-credito',
        'tiposGarantiaCredito': 'tipos-garantia-credito',
        'vinculos': 'vinculo',
        'grauParentesco': 'grau-parentesco',
        'quantidadeSafrasAno': 'quantidade-safras-ano',
        'ramosAtividade': 'ramo-atividade',
        'clientesFornecedores': 'clientes-fornecedores',
        'clientesFornecedores/id': 'clientes-fornecedores/:id',
        'sistemasProducao': 'sistemas-producao',
        'ciclosReprodutivos': 'ciclos-reprodutivos',
        'compradores': 'comprador',
        'compradores/id': 'comprador/:id',
        'periodoSafra': 'periodo-safra',
        'periodoSafra/idTipoProducao': 'periodo-safra/tipo-producao/:idTipoProducao',
        'instituicaoBancaria': 'instituicao-bancaria',
        'documento': 'solicitacao-documento',
        'documento/idDocumento': 'solicitacao-documento/:idDocumento',
        'documento/raro': 'solicitacao-documento/download/solicitacao/:idSolicitacao',
        'documento/idSolicitacao': 'solicitacao-documento/:idSolicitacao',
        'documento/criarSolicitacao': 'solicitacao-documento/planilha/operacao',
        'arquivo': 'arquivo',
        'arquivo/porUsuario': 'arquivo/usuario',
        'arquivo/idSolicitacao': 'arquivo/:idSolicitacao',
        'arquivo/porUsuario/idSolicitacao': 'arquivo/usuario/:idSolicitacao',
        'cooperativas': 'cooperativas',
        'propriedades': 'propriedades',
        'buscaCep': 'cep/:cep',
        'cultura': 'cultura',
        'atividadePecuarista': 'atividade-pecuarista',
        'unidadeMedida': 'unidade-medida',
        'iniciativasSocioambientais': 'iniciativas-socioambientais',
        'certificados': 'certificados',
        'seguroRural': 'seguro-rural',
        'clienteFornecedor': 'cliente-fornecedor',
        'usuarioCondominio/idSolicitacao': 'usuario-condominio/solicitacao/:idSolicitacao',
        'tiposAplicacao': 'tipo-aplicacao',
        'tipoAtividadePerfilProdutor': 'tipo-atividade/perfilprodutor/:perfilprodutor',
        'tipoArmazenamento': 'tipo-armazenamento',
        'guias': 'guia',
        'formularios': 'formulario',
        'formulariosPorGuia': 'formulario/guia/id',
        'painelNumerosTotais': 'solicitacoes/painel/numeros-gerais',
        'painelConversaoRaro': 'solicitacoes/painel/conversao-raro',
        'painelOperacoes': 'solicitacoes/painel/operacoes',
        'painelCreditos': 'solicitacoes/painel/creditos',
        'painelResultadoOperacoes': 'solicitacoes/painel/creditos/resultado',
        'painelPerfilSolicitante': 'solicitacoes/painel/solicitante',
        'painelAreaProducao': 'solicitacoes/painel/area-producao',
        'painelCulturaArea': 'solicitacoes/painel/cultura-area',
        'melhoresRaros': 'solicitacoes/melhores/raros',
        'verificarPreenchimentoFormulario': 'verificar-preenchimento-formulario',
        'verificarPreenchimentoFormularioPorSolicitacao': 'verificar-preenchimento-formulario/solicitacao/:idSolicitacao',
        'preenchimentoValor': 'solicitacoes/preenchimento/:idSolicitacao',
        'preenchimentoGuias': 'solicitacoes/preenchimento/guias/:idSolicitacao',
        'preenchimentoAtualizar': 'solicitacoes/preenchimento/atualizar/:idSolicitacao',
        'atualizarAtendente': 'solicitacoes/atualizar/usuario-cadastro/:idSolicitante',
        'refreshLogin': 'auth/refresh-token',
        'informacaoAdicional': 'solicitante-informacoes-adicionais',
        'informacaoAdicional/porSolicitacao': 'solicitante-informacoes-adicionais/:idSolicitacao',
        'informacaoAdicional/solicitacao': 'solicitante-informacoes-adicionais/solicitacao/:idSolicitacao',
        'buscarRatingRaro/idSolicitacao': 'rating-raro/:idSolicitacao',
        'gerarRatingRaro/idSolicitacao': 'rating-raro/gerar/:idSolicitacao',
        'listaSolicitacoesParceiros': 'solicitacao-importacao-parceiro',
        'criarSolicitacaoParceiro': 'solicitacao-importacao-parceiro/solicitante/:idSolicitante',
        'documentoSolicitacaoParceiro': 'solicitacao-importacao-parceiro/:idSolicitacao/document/:documentType',
        'submeterSolicitacaoParceiro': 'solicitacao-importacao-parceiro/:idSolicitacao/enviar-analise',
        'atualizarSolicitacaoParceiro': 'solicitacao-importacao-parceiro/:idSolicitacao',
        'resetpassword': 'usuarios/enviar-recuperacao-senha',
      }
    },
    backend: {
      host: APP_BACKEND_URL,
      protocol: 'https',
      port: '',
      root: '/v1/',
      endpoints: {
        buscaCep: 'utils/busca-cep',
        upload: 'upload/:solicitanteId',
        uploadKmz: 'upload/:solicitanteId/kmz',
        uploadRobo: 'upload-robo',
        domain: 'domain'
      }
    },
    autenticacao: {
      host: URL_BASE_API,
      protocol: 'https',
      port: '',
      root: '/auth/',
      endpoints: {
        'accessToken': 'login',
        'current': 'current',
      }
    },
    usuario: {
      host: URL_BASE_API,
      protocol: 'https',
      port: '',
      root: '/usuario/v1/',
      endpoints: {
        'usuario': 'users/',
        'usuario/iduser': 'users/:iduser',
        'resetpassword': 'resetpassword',
        'changepassword': 'changepassword'
      }
    },
    formulario: {
      host: URL_BASE_API,
      protocol: 'https',
      port: '',
      root: '/formulario/v1/',
      endpoints: {
        'forms/list': 'forms',
        'forms/find': 'forms/find/:idForm',
        'requester/create': 'requester',
        'requester/update': 'requester/:idSolicitacao',
        'requester/list': 'requester',
        'requester/find': 'requester/:idSolicitacao',
        'form-data/create': 'formdata',
        'form-data/update': 'formdata',
        'pdf': 'pdf/:idSolicitacao/raro.pdf',
        'solicitarpdf': 'pdf/solicitar/:idSolicitacao',
        'rating': 'rating/:idSolicitacao',
        'domain': 'domain'
      }
    },
    documento: {
      host: URL_BASE_API,
      protocol: 'https',
      port: '',
      root: '/arquivoteca/v1/',
      endpoints: {
        'pastas/create': 'pastas',
        'pastas/find': 'pastas/:idPasta',
        'pastas/caminho': 'pastas',
        'arquivos/create': 'arquivos',
        'arquivos/pasta': 'arquivos',
        'arquivos/find': 'arquivos/:idArquivo'
      }
    },
    upload: {
      host: URL_BASE_API,
      protocol: 'https',
      port: '',
      root: '/upload/v1/',
      endpoints: {
        upload: 'upload'
      }
    },
    ibge: {
      host: 'servicodados.ibge.gov.br',
      protocol: 'https',
      port: '',
      root: '/api/',
      endpoints: {
        'brasil-geo': 'v3/malhas/paises/BR?formato=application/vnd.geo+json',
        'regiao-geo': 'v2/malhas/:idRegiao?resolucao=2&formato=application/vnd.geo+json',
        'uf-geo': 'v3/malhas/estados/:idUf?formato=application/vnd.geo+json',
        'municipio-geo': 'v3/malhas/municipios/:idMunicipio?formato=application/vnd.geo+json'
      }
    }
  },
  values: {
    'usuario-default': ''
  },
  terralogs: {
    email: USER_TERRALOGS || 'felix.telles@zello.tec.br',
    password: PASSWORD_TERRALOGS || 'z3lL0p4sS_2020'
  }
};

export function merge(...env: AppEnvironment[]): AppEnvironment {
  return _.merge({}, environment, ...env);
}

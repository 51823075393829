import { Solicitacao } from '@zellotec/terralogs_regras/models';
import { TipoReferenciaEnum } from '../enums/tipo-referencia.enum';
import { TipoEndividamentoEnum } from '../enums/tipo-endividamento.enum';
import { TipoDocumentoEnum } from '../enums/tipo-documento.enum';
import { TipoCategoriaEnum } from '../enums/tipo-categoria.enum';
import { TipoProprietarioEnum } from '../enums/tipo-proprietario.enum';
import { CondominioComponent } from '../components/form-producao/condominio/condominio.component';
import { UsuarioCondominio } from '@zellotec/terralogs_regras/models/usuario-condominio';
import { CulturaMunicipioComponent } from '../components/form-producao/cultura-municipio/cultura-municipio.component';
import { SolicitacaoCulturaAtividade } from '@zellotec/terralogs_regras/models/solicitacao-cultura-atividade';
import { PerfilProdutor } from '@zellotec/terralogs_regras/models/perfil-produtor';
import { IniciativasSocioambientaisComponent } from '../components/form-producao/iniciativas-socioambientais/iniciativas-socioambientais.component';
import { SelosCertificacoesComponent } from '../components/form-producao/selos-certificacoes/selos-certificacoes.component';
import { SeguroRuralComponent } from '../components/form-producao/seguro-rural/seguro-rural.component';
import { FornecedoresClientesComponent } from '../components/form-producao/fornecedores-clientes/fornecedores-clientes.component';
import { ArmazenagemComponent } from '../components/form-producao/armazenagem/armazenagem.component';
import { AreaProducaoComponent } from '../components/form-producao/area-producao/area-producao.component';
import { QuadroSafraComponent } from '../components/form-producao/quadro-safra/quadro-safra.component';
import { TIPO_PRODUCAO } from '../services/atividade-area-producao.service';
import { PecuariaCorteComponent } from '../components/form-producao/pecuaria-corte/pecuaria-corte.component';

export class SolicitacaoSessao {
  id = null;
  situacaoSolicitacao = null;
  ultimaRequisicao = null;
  menuObrigatorio = false;
  menuTodos = false;
  anoBase = null;
  percentualSocietarioSolicitante = 0;
  nomeGrupo = '';
  informacoesGerais = {
    titulo: 'Informações Gerais',
    concluido: 0,
    id: null,
    campos: {
      nome: null,
      cpf: null,
      telefone: null,
      email: null,
      dataNascimento: null,
      permiteAlterar: null
    }
  };
  endereco = {
    titulo: 'Endereço',
    concluido: 0,
    id: null,
    campos: {
      estado: null,
      municipio: null,
      cep: null,
      endereco: null,
      numero: null,
      complemento: null,
      tempoResidencia: null
    }
  };
  atividade = {
    titulo: 'Atividade',
    concluido: 0,
    campos: {
      atividadePrincipal: null,
      tempoExercicio: null,
      outraAtividade: null,
      ufAtividade: null,
      municipioAtividade: null
    }
  };
  associacoes = {
    titulo: 'Associações',
    concluido: 0,
    id: null,
    campos: {
      associacaoCooperativa: null,
      participacaoGrupo: null
    }
  };
  informacoesAdicionais = {
    titulo: 'Informações Adicionais',
    concluido: 0,
    id: null,
    campos: {
      sucessaoFamiliar: null,
      ppe: null,
      estadoCivil: null,
      regimeCasamento: null
    }
  };
  parceiro = {
    titulo: 'Parceiro Terralogs',
    concluido: 0,
    id: null,
    campos: {
      nome: null,
      telefone: null,
      email: null,
      cpf: null
    }
  };

  financiamento = {
    titulo: 'Financiamento',
    concluido: 0,
    campos: {
      financiamento: null,
      valorSolicitado: null
    }
  };
  finalidade = {
    titulo: 'Finalidade',
    concluido: 0,
    campos: {
      financiamento: null,
      finalidades: [],
      finalidadesOutra: []
    }
  };
  condicoes = {
    titulo: 'Condições',
    concluido: 0,
    campos: {
      prazoPagamento: null,
      prazoCarencia: null
    }
  };

  historicos = {
    titulo: 'Históricos',
    concluido: 0,
    campos: {
      texto: null
    }
  };

  garantias = {
    titulo: 'Garantias',
    concluido: 0,
    campos: []
  };

  ativosArmazem = {
    titulo: 'Armazéns',
    concluido: 0,
    campos: []
  };
  ativosImoveisUrbanos = {
    titulo: 'Imóveis Urbanos',
    concluido: 0,
    campos: []
  };
  ativosSemoventes = {
    titulo: 'Semoventes',
    concluido: 0,
    campos: []
  };
  ativosMaquinarios = {
    titulo: 'Maquinários, Equipamentos e Veículos',
    concluido: 0,
    campos: []
  };
  ativosParticipacoesAcionarias = {
    titulo: 'Participações Acionárias',
    concluido: 0,
    campos: []
  };
  ativosAplicacoesFinanceiras = {
    titulo: 'Aplicações Financeiras',
    concluido: 0,
    campos: []
  };
  ativosRecebiveis = {
    titulo: 'Recebíveis',
    concluido: 0,
    campos: []
  };
  ativosEstoque = {
    titulo: 'Estoque',
    concluido: 0,
    campos: []
  };
  ativosPropriedadesRuraisArrendadas = {
    titulo: 'Propriedades Rurais Arrendadas',
    concluido: 0,
    campos: []
  };
  ativosPropriedadesRuraisProprias = {
    titulo: 'Propriedades Rurais Próprias',
    concluido: 0,
    campos: []
  };

  dividasBancosFundos = {
    titulo: 'Dívidas com Bancos e Fundos',
    concluido: 0,
    campos: []
  };
  dividasFornecedoresTradings = {
    titulo: 'Dívidas com Fornecedores e Tradings',
    concluido: 0,
    campos: []
  };
  dividasAquisicaoPropriedadesRurais = {
    titulo: 'Dívidas com Aquisição de Propriedades Rurais',
    concluido: 0,
    campos: []
  };

  referenciasComerciais = {
    titulo: 'Referências Comerciais',
    concluido: 0,
    campos: []
  };
  referenciasBancarias = {
    titulo: 'Referências Bancárias',
    concluido: 0,
    campos: []
  };

  documentosPessoais = {
    titulo: 'Documentos Pessoais',
    camposPreenchidos: null,
    camposTotais: null,
    campos: [
      {
        titulo: 'CNH RG e CPF',
        tipoDocumento: TipoDocumentoEnum.CnhRgCpf,
        documentos: []
      },
      {
        titulo: 'Comprovante de Residência',
        tipoDocumento: TipoDocumentoEnum.ComprovanteResidencia,
        documentos: []
      },
      {
        titulo: 'Declaração IRPF Atualizada',
        tipoDocumento: TipoDocumentoEnum.DeclaracaoIrpfAtualizada,
        documentos: []
      },
      {
        titulo: 'Recibo IRPF',
        tipoDocumento: TipoDocumentoEnum.ReciboIrpf,
        documentos: []
      },
      {
        titulo: 'Certidão de Casamento ou Nascimento',
        tipoDocumento: TipoDocumentoEnum.CertidaoCasamentoOuNascimento,
        documentos: []
      },
      {
        titulo: 'Outros Documentos',
        tipoDocumento: TipoDocumentoEnum.OutrosDocumentosPessoais,
        documentos: []
      }
    ]
  };
  documentosAtividade = {
    titulo: 'Atividade',
    camposPreenchidos: null,
    camposTotais: null,
    campos: [
      {
        titulo: 'Apresentação Institucional',
        tipoDocumento: TipoDocumentoEnum.ApresentacaoInstitucional,
        documentos: []
      },
      {
        titulo: 'Organograma Societário',
        tipoDocumento: TipoDocumentoEnum.OrganogramaSocietario,
        documentos: []
      },
      {
        titulo: 'Quadro Safra / Pecuário',
        tipoDocumento: TipoDocumentoEnum.QuadroSafraPecuario,
        documentos: []
      },
      {
        titulo: 'Relação de Principais Clientes',
        tipoDocumento: TipoDocumentoEnum.RelacaoPrincipaisClientes,
        documentos: []
      },
      {
        titulo: 'Relação de Principais Fornecedores',
        tipoDocumento: TipoDocumentoEnum.RelacaoPrincipaisFornecedores,
        documentos: []
      },
      {
        titulo: 'Outros Documentos',
        tipoDocumento: TipoDocumentoEnum.OutrosDocumentosAtividade,
        documentos: []
      }
    ]
  };
  documentosPropriedadesOutrosBens = {
    titulo: 'Propriedades e Outros Bens',
    camposPreenchidos: null,
    camposTotais: null,
    campos: [
      {
        titulo: 'Matrícula da Propriedade',
        tipoDocumento: TipoDocumentoEnum.MatriculaPropriedade,
        documentos: []
      },
      {
        titulo: 'Avaliação da Propriedade',
        tipoDocumento: TipoDocumentoEnum.AvaliacaoPropriedade,
        documentos: []
      },
      {
        titulo: 'KMZ da Propriedade',
        tipoDocumento: TipoDocumentoEnum.KmzPropriedade,
        documentos: []
      },
      {
        titulo: 'Contrato de Arrendamento',
        tipoDocumento: TipoDocumentoEnum.ContratoArrendamento,
        documentos: []
      },
      {
        titulo: 'Outros Documentos',
        tipoDocumento: TipoDocumentoEnum.OutrosDocumentosPropriedadesEOutrosBens,
        documentos: []
      },
    ]
  };
  documentosFinanceiro = {
    titulo: 'Financeiro',
    camposPreenchidos: null,
    camposTotais: null,
    campos: [
      {
        titulo: 'Endividamento',
        tipoDocumento: TipoDocumentoEnum.Endividamento,
        documentos: []
      },
      {
        titulo: 'Contratos / Recebíveis',
        tipoDocumento: TipoDocumentoEnum.ContratosRecebiveis,
        documentos: []
      },
      {
        titulo: 'Outros Documentos',
        tipoDocumento: TipoDocumentoEnum.OutrosDocumentosFinanceiro,
        documentos: []
      }
    ]
  };
  documentosImagens = {
    titulo: 'Imagens',
    camposPreenchidos: null,
    camposTotais: null,
    campos: [
      {
        titulo: 'Fotos da Propriedade',
        tipoDocumento: TipoDocumentoEnum.FotosPropriedade,
        documentos: []
      },
      {
        titulo: 'Fotos da Produção / Atividade',
        tipoDocumento: TipoDocumentoEnum.FotosProducaoAtividade,
        documentos: []
      },
      {
        titulo: 'Fotos de Perfil do Solicitante',
        tipoDocumento: TipoDocumentoEnum.FotosPerfilSolicitante,
        documentos: []
      },
      {
        titulo: 'Outros Documentos',
        tipoDocumento: TipoDocumentoEnum.OutrosDocumentosImagens,
        documentos: []
      }
    ]
  };
  documentosOutros = {
    titulo: 'Outros',
    camposPreenchidos: null,
    camposTotais: null,
    campos: [
      {
        titulo: 'Mandato TerraLogs',
        tipoDocumento: TipoDocumentoEnum.MandatoTerraLogs,
        documentos: []
      },
      {
        titulo: 'Outros Documentos',
        tipoDocumento: TipoDocumentoEnum.OutrosDocumentosOutros,
        documentos: []
      }
    ]
  };

  producaoCondominioAgropecuario = {
    titulo: 'Condomínio Agropecuário',
    concluido: 0,
    componente: () => CondominioComponent,
    campos: []
  };
  producaoAtividadeMunicipio = {
    titulo: 'Atividade por Município',
    concluido: 0,
    componente: () => CulturaMunicipioComponent,
    campos: []
  };
  producaoIniciativasSocioambientais = {
    titulo: 'Iniciativas Socioambientais',
    concluido: 0,
    componente: () => IniciativasSocioambientaisComponent,
    campos: []
  };
  producaoSelosCertificacoes = {
    titulo: 'Selos e Certificações',
    concluido: 0,
    componente: () => SelosCertificacoesComponent,
    campos: []
  };
  producaoArmazenagem = {
    titulo: 'Armazenagem',
    concluido: 0,
    componente: () => ArmazenagemComponent,
    campos: []
  };
  producaoSeguroRural = {
    titulo: 'Seguro Rural',
    concluido: 0,
    componente: () => SeguroRuralComponent,
    campos: []
  };
  producaoFornecedorCliente = {
    titulo: 'Fornecedores e Clientes',
    concluido: 0,
    componente: () => FornecedoresClientesComponent,
    campos: {}
  };
  producaoAreaProducao = {
    titulo: 'Área de Produção',
    concluido: 0,
    componente: () => AreaProducaoComponent,
    campos: {}
  };
  producaoQuadroSafra = {
    titulo: 'Quadro Safra',
    concluido: 0,
    componente: () => QuadroSafraComponent,
    campos: []
  };
  producaoPecuariaCorte = {
    titulo: 'Pecuária Bovina de Corte',
    concluido: 0,
    componente: () => PecuariaCorteComponent,
    campos: []
  };
  solicitacaoCulturaAtividade: {
    titulo: 'Solicitação Cultura Atividade',
    concluido: 0,
    componente: () => null,
    campos: SolicitacaoCulturaAtividade
  };

  perfilProdutor: {
    titulo: 'Perfil Produtor',
    concluido: 0,
    componente: () => null,
    campos: PerfilProdutor
  };
}

export class SolicitacaoCamposObrigatorios {
  camposObrigatoriosDadosSolicitante = [
    'informacoesGerais',
    'endereco',
    'atividade'
  ];
  camposObrigatoriosCredito = [
    'financiamento',
    'finalidade',
    'condicoes'
  ];
}

export function mapearSolicitacao(solicitacao?: Solicitacao): SolicitacaoSessao {
  // TODO mapear apenas o necessario para a pagina atual (msm por hora, trazendo tudo da solicitacao na requisicao
  return {
    id: solicitacao?.id,
    situacaoSolicitacao: solicitacao?.situacaoSolicitacao?.id,
    ultimaRequisicao: solicitacao?.ultimaRequisicao,

    menuObrigatorio: null,
    menuTodos: null,
    anoBase: (typeof solicitacao?.anoBase === 'string') ? +solicitacao?.anoBase : solicitacao?.anoBase,
    percentualSocietarioSolicitante: solicitacao?.percentualSocietarioSolicitante,
    nomeGrupo: solicitacao?.nomeCondominio,
    informacoesGerais: {
      titulo: 'Informações Gerais',
      concluido: 0,
      id: solicitacao?.usuarioSolicitante?.id,
      campos: {
        nome: solicitacao?.usuarioSolicitante?.nome,
        cpf: solicitacao?.usuarioSolicitante?.cpf,
        telefone: solicitacao?.usuarioSolicitante?.telefone,
        email: solicitacao?.usuarioSolicitante?.email,
        dataNascimento: solicitacao?.usuarioSolicitante?.dataNascimento ?
          solicitacao?.usuarioSolicitante?.dataNascimento.toString().split('T')[0]
          : null,
        permiteAlterar: solicitacao?.usuarioSolicitante?.permiteAlterar
      }
    },
    endereco: {
      titulo: 'Endereço',
      concluido: 0,
      id: solicitacao?.usuarioSolicitante?.id,
      campos: {
        estado: solicitacao?.usuarioSolicitante?.estado,
        municipio: solicitacao?.usuarioSolicitante?.municipio,
        cep: solicitacao?.usuarioSolicitante?.cep,
        endereco: solicitacao?.usuarioSolicitante?.endereco,
        numero: solicitacao?.usuarioSolicitante?.numero,
        complemento: solicitacao?.usuarioSolicitante?.complemento,
        tempoResidencia: solicitacao?.usuarioSolicitante?.tempoResidencia
      }
    },
    atividade: {
      titulo: 'Atividade',
      concluido: 0,
      campos: {
        atividadePrincipal: solicitacao?.atividadePrincipal,
        tempoExercicio: solicitacao?.tempoExercicio,
        outraAtividade: solicitacao?.outraAtividade,
        ufAtividade: solicitacao?.ufAtividade,
        municipioAtividade: solicitacao?.municipioAtividade
      }
    },
    associacoes: {
      titulo: 'Associações',
      concluido: 0,
      id: solicitacao?.usuarioSolicitante?.id,
      campos: {
        associacaoCooperativa: solicitacao?.usuarioSolicitante?.associacaoCooperativa,
        participacaoGrupo: solicitacao?.usuarioSolicitante?.participacaoGrupo
      }
    },
    informacoesAdicionais: {
      titulo: 'Informações Adicionais',
      concluido: 0,
      id: solicitacao?.usuarioSolicitante?.id,
      campos: {
        sucessaoFamiliar: solicitacao?.usuarioSolicitante?.sucessaoFamiliar,
        ppe: solicitacao?.usuarioSolicitante?.ppe,
        estadoCivil: solicitacao?.usuarioSolicitante?.estadoCivil,
        regimeCasamento: solicitacao?.usuarioSolicitante?.regimeCasamento
      }
    },
    parceiro: {
      titulo: 'Parceiro Terralogs',
      concluido: 0,
      id: solicitacao?.usuarioParceiro?.id,
      campos: {
        nome: solicitacao?.usuarioParceiro?.nome,
        telefone: solicitacao?.usuarioParceiro?.telefone,
        email: solicitacao?.usuarioParceiro?.email,
        cpf: solicitacao?.usuarioParceiro?.cpf
      }
    },

    financiamento: {
      titulo: 'Financiamento',
      concluido: 0,
      campos: {
        financiamento: solicitacao?.financiamento,
        valorSolicitado: +solicitacao?.valorSolicitado
      }
    },
    finalidade: {
      titulo: 'Finalidade',
      concluido: 0,
      campos: {
        financiamento: solicitacao?.financiamento,
        finalidades: solicitacao?.finalidades,
        finalidadesOutra: solicitacao?.finalidadesOutra
      }
    },
    condicoes: {
      titulo: 'Condições',
      concluido: 0,
      campos: {
        prazoPagamento: solicitacao?.prazoPagamento,
        prazoCarencia: solicitacao?.prazoCarencia
      }
    },

    historicos: {
      titulo: 'Históricos',
      concluido: 0,
      campos: {
        texto: solicitacao?.historicos
      }
    },

    garantias: {
      titulo: 'Garantias',
      concluido: 0,
      campos: solicitacao?.ativos
        .filter(ativo => ativo.ibGarantia && ativo.tipoCategoria)
        .sort((a, b) => order(a.tipoCategoria.nome, b.tipoCategoria.nome))
        .sort((a, b) => order(a.id, b.id))
    },

    ativosArmazem: {
      titulo: 'Armazéns',
      concluido: 0,
      campos: solicitacao?.ativos.filter((ativo: any) => ativo.tipoCategoria && ativo.tipoCategoria.id === TipoCategoriaEnum.Armazem
        && ativo.objeto?.dados?.tipoProprietario?.id === TipoProprietarioEnum.Propria)
    },
    ativosImoveisUrbanos: {
      titulo: 'Imóveis Urbanos',
      concluido: 0,
      campos: solicitacao?.ativos.filter((ativo: any) => ativo.tipoCategoria && ativo.tipoCategoria.id === TipoCategoriaEnum.ImoveisUrbanos
        && ativo.objeto?.dados?.tipoProprietario?.id === TipoProprietarioEnum.Propria)
    },
    ativosSemoventes: {
      titulo: 'Semoventes',
      concluido: 0,
      campos: solicitacao?.semoventes
    },
    ativosMaquinarios: {
      titulo: 'Maquinários, Equipamentos e Veículos',
      concluido: 0,
      campos: solicitacao?.ativos.filter(ativo => ativo.tipoCategoria &&
        ativo.tipoCategoria.id.valueOf() === TipoCategoriaEnum.MaquinariosEquipamentosVeiculos)
    },
    ativosParticipacoesAcionarias: {
      titulo: 'Participações Acionárias',
      concluido: 0,
      campos: solicitacao?.participacoesAcionarias
    },
    ativosAplicacoesFinanceiras: {
      titulo: 'Aplicações Financeiras',
      concluido: 0,
      campos: solicitacao?.aplicacoesFinanceiras
    },
    ativosRecebiveis: {
      titulo: 'Recebíveis',
      concluido: 0,
      campos: solicitacao?.ativos.filter(ativo => ativo.tipoCategoria &&
        ativo.tipoCategoria.id.valueOf() === TipoCategoriaEnum.Recebivel)
    },
    ativosEstoque: {
      titulo: 'Estoque',
      concluido: 0,
      campos: solicitacao?.ativos.filter(ativo => ativo.tipoCategoria &&
        ativo.tipoCategoria.id.valueOf() === TipoCategoriaEnum.Estoque)
    },
    ativosPropriedadesRuraisArrendadas: {
      titulo: 'Propriedades Rurais Arrendadas',
      concluido: 0,
      campos: solicitacao?.ativos.filter((ativo: any) => ativo.tipoCategoria &&
        ativo.tipoCategoria.id === TipoCategoriaEnum.PropriedadeRural
        && ativo.objeto?.dados?.tipoPropriedadeRural?.id === TipoProprietarioEnum.Arrendada)
    },
    ativosPropriedadesRuraisProprias: {
      titulo: 'Propriedades Rurais Próprias',
      concluido: 0,
      campos: solicitacao?.ativos.filter((ativo: any) => ativo.tipoCategoria &&
        ativo.tipoCategoria.id === TipoCategoriaEnum.PropriedadeRural
        && ativo.objeto?.dados?.tipoPropriedadeRural?.id === TipoProprietarioEnum.Propria)
    },

    dividasBancosFundos: {
      titulo: 'Dívidas com Bancos e Fundos',
      concluido: 0,
      campos: solicitacao?.endividamento.filter(divida =>
        divida.tipoEndividamento.id.valueOf() === TipoEndividamentoEnum.BancosFundos)
    },
    dividasFornecedoresTradings: {
      titulo: 'Dívidas com Fornecedores e Tradings',
      concluido: 0,
      campos: solicitacao?.endividamento.filter(divida =>
        divida.tipoEndividamento.id.valueOf() === TipoEndividamentoEnum.FornecedoresTradings)
    },
    dividasAquisicaoPropriedadesRurais: {
      titulo: 'Dívidas com Aquisição de Propriedades Rurais',
      concluido: 0,
      campos: solicitacao?.endividamento.filter(divida =>
        divida.tipoEndividamento.id.valueOf() === TipoEndividamentoEnum.AquisicaoPropriedadesRurais)
    },

    referenciasComerciais: {
      titulo: 'Referências Comerciais',
      concluido: 0,
      campos: solicitacao?.referenciasComerciais.filter(referencia => referencia.tipoReferencia.id === TipoReferenciaEnum.Comercial)
    },
    referenciasBancarias: {
      titulo: 'Referências Bancárias',
      concluido: 0,
      campos: solicitacao?.referenciasComerciais.filter(referencia => referencia.tipoReferencia.id === TipoReferenciaEnum.Bancaria)
    },

    documentosPessoais: {
      titulo: 'Documentos Pessoais',
      camposPreenchidos: null,
      camposTotais: null,
      campos: [
        {
          titulo: 'CNH RG e CPF',
          tipoDocumento: TipoDocumentoEnum.CnhRgCpf,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.CnhRgCpf
          ),
        },
        {
          titulo: 'Comprovante de Residência',
          tipoDocumento: TipoDocumentoEnum.ComprovanteResidencia,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.ComprovanteResidencia
          )
        },
        {
          titulo: 'Declaração IRPF Atualizada',
          tipoDocumento: TipoDocumentoEnum.DeclaracaoIrpfAtualizada,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.DeclaracaoIrpfAtualizada
          )
        },
        {
          titulo: 'Recibo IRPF',
          tipoDocumento: TipoDocumentoEnum.ReciboIrpf,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.ReciboIrpf
          )
        },
        {
          titulo: 'Certidão de Casamento ou Nascimento',
          tipoDocumento: TipoDocumentoEnum.CertidaoCasamentoOuNascimento,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.CertidaoCasamentoOuNascimento
          )
        },
        {
          titulo: 'Outros Documentos',
          tipoDocumento: TipoDocumentoEnum.OutrosDocumentosPessoais,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.ApresentacaoInstitucional
          ),
        }
      ]
    },
    documentosAtividade: {
      titulo: 'Atividade',
      camposPreenchidos: null,
      camposTotais: null,
      campos: [
        {
          titulo: 'Apresentação Institucional',
          tipoDocumento: TipoDocumentoEnum.ApresentacaoInstitucional,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.ApresentacaoInstitucional
          ),
        },
        {
          titulo: 'Organograma Societário',
          tipoDocumento: TipoDocumentoEnum.OrganogramaSocietario,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.OrganogramaSocietario
          )
        },
        {
          titulo: 'Quadro Safra / Pecuário',
          tipoDocumento: TipoDocumentoEnum.QuadroSafraPecuario,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.QuadroSafraPecuario
          )
        },
        {
          titulo: 'Relação de Principais Clientes',
          tipoDocumento: TipoDocumentoEnum.RelacaoPrincipaisClientes,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.RelacaoPrincipaisClientes
          )
        },
        {
          titulo: 'Relação de Principais Fornecedores',
          tipoDocumento: TipoDocumentoEnum.RelacaoPrincipaisFornecedores,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.RelacaoPrincipaisFornecedores
          )
        },
        {
          titulo: 'Outros Documentos',
          tipoDocumento: TipoDocumentoEnum.OutrosDocumentosAtividade,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.OutrosDocumentosAtividade
          )
        }
      ]
    },
    documentosPropriedadesOutrosBens: {
      titulo: 'Propriedades e Outros Bens',
      camposPreenchidos: null,
      camposTotais: null,
      campos: [
        {
          titulo: 'Matrícula da Propriedade',
          tipoDocumento: TipoDocumentoEnum.MatriculaPropriedade,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.MatriculaPropriedade
          ),
        },
        {
          titulo: 'Avaliação da Propriedade',
          tipoDocumento: TipoDocumentoEnum.AvaliacaoPropriedade,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.AvaliacaoPropriedade
          ),
        },
        {
          titulo: 'KMZ da Propriedade',
          tipoDocumento: TipoDocumentoEnum.KmzPropriedade,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.KmzPropriedade
          ),
        },
        {
          titulo: 'Contrato de Arrendamento',
          tipoDocumento: TipoDocumentoEnum.ContratoArrendamento,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.ContratoArrendamento
          ),
        },
        {
          titulo: 'Outros Documentos',
          tipoDocumento: TipoDocumentoEnum.OutrosDocumentosPropriedadesEOutrosBens,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.OutrosDocumentosPropriedadesEOutrosBens
          ),
        }
      ]
    },
    documentosFinanceiro: {
      titulo: 'Financeiro',
      camposPreenchidos: null,
      camposTotais: null,
      campos: [
        {
          titulo: 'Endividamento',
          tipoDocumento: TipoDocumentoEnum.Endividamento,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.Endividamento
          ),
        },
        {
          titulo: 'Contratos / Recebíveis',
          tipoDocumento: TipoDocumentoEnum.ContratosRecebiveis,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.ContratosRecebiveis
          ),
        },
        {
          titulo: 'Outros Documentos',
          tipoDocumento: TipoDocumentoEnum.OutrosDocumentosFinanceiro,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.OutrosDocumentosFinanceiro
          ),
        }
      ]
    },
    documentosImagens: {
      titulo: 'Imagens',
      camposPreenchidos: null,
      camposTotais: null,
      campos: [
        {
          titulo: 'Fotos da Propriedade',
          tipoDocumento: TipoDocumentoEnum.FotosPropriedade,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.FotosPropriedade
          ),
        },
        {
          titulo: 'Fotos da Produção / Atividade',
          tipoDocumento: TipoDocumentoEnum.FotosProducaoAtividade,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.FotosProducaoAtividade
          ),
        },
        {
          titulo: 'Fotos de Perfil do Solicitante',
          tipoDocumento: TipoDocumentoEnum.FotosPerfilSolicitante,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.FotosPerfilSolicitante
          ),
        },
        {
          titulo: 'Outros Documentos',
          tipoDocumento: TipoDocumentoEnum.OutrosDocumentosImagens,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.OutrosDocumentosImagens
          ),
        }
      ]
    },
    documentosOutros: {
      titulo: 'Outros',
      camposPreenchidos: null,
      camposTotais: null,
      campos: [
        {
          titulo: 'Mandato TerraLogs',
          tipoDocumento: TipoDocumentoEnum.MandatoTerraLogs,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.MandatoTerraLogs
          ),
        },
        {
          titulo: 'Outros Documentos',
          tipoDocumento: TipoDocumentoEnum.OutrosDocumentosOutros,
          documentos: solicitacao?.solicitacaoDocumentos.filter(
            documento => documento.tipoDocumento?.id === TipoDocumentoEnum.OutrosDocumentosOutros
          ),
        }
      ]
    },

    producaoCondominioAgropecuario: {
      titulo: 'Condomínio Agropecuário',
      concluido: 0,
      componente: () => CondominioComponent,
      campos: tratarCondominioAgropecuario(solicitacao)
    },
    producaoAtividadeMunicipio: {
      titulo: 'Atividade por Município',
      concluido: 0,
      componente: () => CulturaMunicipioComponent,
      campos: solicitacao?.solicitacaoAtividadeMunicipio
    },
    producaoIniciativasSocioambientais: {
      titulo: 'Iniciativas Socioambientais',
      concluido: 0,
      componente: () => IniciativasSocioambientaisComponent,
      campos: tratarValoresRetornoComPossiveisOutros(solicitacao, 'solicitacaoIniciativasSocioambientais', 'iniciativasSocioambientais', 'iniciativasSocioambientaisOutro'),
    },
    producaoSelosCertificacoes: {
      titulo: 'Selos e Certificações',
      concluido: 0,
      componente: () => SelosCertificacoesComponent,
      campos: tratarValoresRetornoComPossiveisOutros(solicitacao, 'solicitacaoCertificado', 'certificacao', 'certificacaoOutros')
    },
    producaoArmazenagem: {
      titulo: 'Armazenagem',
      concluido: 0,
      componente: () => ArmazenagemComponent,
      campos: solicitacao?.solicitacaoArmazenagem,
    },
    producaoSeguroRural: {
      titulo: 'Seguro Rural',
      concluido: 0,
      componente: () => SeguroRuralComponent,
      campos: solicitacao?.solicitacaoSeguroRural
    },
    producaoFornecedorCliente: {
      titulo: 'Fornecedores e Clientes',
      concluido: 0,
      componente: () => FornecedoresClientesComponent,
      campos: tratarValoresRetornoFornecedoresClientes(solicitacao),
    },
    producaoAreaProducao: {
      titulo: 'Área de Produção',
      concluido: 0,
      componente: () => AreaProducaoComponent,
      campos: tratarValoresAreaProducao(solicitacao?.solicitacaoAreaProducao)
    },
    producaoQuadroSafra: {
      titulo: 'Quadro Safra',
      concluido: 0,
      componente: () => QuadroSafraComponent,
      campos: solicitacao?.solicitacaoQuadroSafra
    },
    producaoPecuariaCorte: {
      titulo: 'Pecuária Bovina de Corte',
      concluido: 0,
      componente: () => PecuariaCorteComponent,
      campos: solicitacao?.solicitacaoPecuariaBovinaCorte
    },
    solicitacaoCulturaAtividade: {
      titulo: 'Solicitação Cultura Atividade',
      concluido: 0,
      componente: () => null,
      campos: solicitacao?.solicitacaoCulturaAtividade.length > 0 ? solicitacao?.solicitacaoCulturaAtividade[0] : null
    },
    perfilProdutor: {
      titulo: 'Perfil Produtor',
      concluido: 0,
      componente: () => null,
      campos: solicitacao?.perfilProdutor
    },
  };
}

export function tratarCondominioAgropecuario(solicitacao: Solicitacao) {
  return solicitacao?.usuarioCondominio.length > 0 ?
    [
      {
        percentualSolicitante: calcularPercentualSolicitante(solicitacao?.usuarioCondominio),
        participantes: solicitacao?.usuarioCondominio,
      }
    ] : [];
}

export function calcularPercentualSolicitante(usuarioCondominio: UsuarioCondominio[]) {
  let porcentagemUsuariosCondominios = 0;
  usuarioCondominio.map((usuario) => {
    porcentagemUsuariosCondominios += usuario?.percentualSocietario;
  });
  return (100 - porcentagemUsuariosCondominios) > 100 ? 100 : (100 - porcentagemUsuariosCondominios);
}

export function validarObrigatorios(solicitacao: SolicitacaoSessao) {
  const solicitacaoCamposObrigatorios = new SolicitacaoCamposObrigatorios();
  const preenchimentoCompletoDadosSolicitante = !solicitacaoCamposObrigatorios.camposObrigatoriosDadosSolicitante
    .find(campo => solicitacao[campo] && solicitacao[campo].concluido <= 0);
  const preenchimentoCompletoCredito = !solicitacaoCamposObrigatorios.camposObrigatoriosCredito
    .find(campo => solicitacao[campo] && solicitacao[campo].concluido <= 0);
  solicitacao.menuObrigatorio = preenchimentoCompletoDadosSolicitante;
  solicitacao.menuTodos = preenchimentoCompletoDadosSolicitante && preenchimentoCompletoCredito;
  return solicitacao;
}

export function calcularPreenchimentoSessao(solicitacao: any) {
  Object.keys(solicitacao).forEach(key => {
    if (solicitacao[key]) {
      calcularPreenchimento(solicitacao[key]);
    }
  });

  return solicitacao;
}

export function tratarValoresRetornoComPossiveisOutros(solicitacao: any, objSolicitacao: any, atributo: any, atributoOutros) {
  let retorno = [];

  if (solicitacao[objSolicitacao].length > 0) {
    retorno = solicitacao[objSolicitacao].map((iniciativa) => {
      let valorIniciativa = iniciativa[atributo]?.nome;
      if (iniciativa[atributo]?.nome === 'Outro') {
        valorIniciativa = valorIniciativa + ' (';
        if (iniciativa[atributoOutros] && iniciativa[atributoOutros].length > 0) {
          iniciativa[atributoOutros].forEach((outro) => {
            valorIniciativa = valorIniciativa + ' ' + outro?.nome + ', ';
          });
        }
        valorIniciativa = valorIniciativa.slice(0, -2);
        valorIniciativa = valorIniciativa + ' )';
      }

      if (atributo === 'seguroRural') {
        valorIniciativa = {
          seguroRural: valorIniciativa,
          seguradora: iniciativa?.nomeSeguradora,
          tipo: iniciativa[atributo]?.nome
        };
      }
      return valorIniciativa;
    });
  }

  return retorno;
}

export function tratarValoresAreaProducao(areaProducao: any) {
  const agricultura = areaProducao.filter((area) => area.tipoProducao?.id === TIPO_PRODUCAO.agricultura);
  const pecuaria = areaProducao.filter((area) => area.tipoProducao?.id === TIPO_PRODUCAO.pecuaria);
  return {
    agricultura,
    pecuaria
  };
}

export function tratarValoresRetornoFornecedoresClientes(solicitacao: any) {
  const retorno = {
    fornecedor: [],
    cliente: []
  };

  if (solicitacao.solicitacaoFornecedorCliente && solicitacao.solicitacaoFornecedorCliente.length > 0) {
    solicitacao.solicitacaoFornecedorCliente.forEach((iniciativa) => {
      if (iniciativa.tipoFornecedorCliente === 'C') {
        retorno.cliente.push(iniciativa);
      } else if (iniciativa.tipoFornecedorCliente === 'F') {
        retorno.fornecedor.push(iniciativa);
      }
    });
  }

  return retorno;
}

export function calcularPreenchimento(obj: any) {
  if (!obj.campos) {
    return;
  } else if (obj.concluido !== undefined) {
    const values = Object.values(obj.campos);
    const preenchidos = values.filter((value: any) => isPreenchido(value));
    obj.concluido = preenchidos.length / values.length;
  } else {
    /** Cálculo específico para Documentos */
    const preenchidos = obj.campos.filter(campo => campo.documentos.length);
    obj.camposPreenchidos = preenchidos.length;
    obj.camposTotais = obj.campos.length;
  }

  return obj;
}

export function isPreenchido(item: any) {
  switch (typeof item) {
    case 'object':
    case 'string':
    case 'number':
      return !(!item || (Array.isArray(item) && !item.length));
    case 'boolean':
      return true; // se for null = 'object'
  }
}

export function order(a: any, b: any) {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
}

import { Component, Input, OnInit } from '@angular/core';
import { showToastMessage } from '../../../util/utils';
import { ModalController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Usuario } from '@zellotec/terralogs_regras/models';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import {
  INICIATIVA_SOCIOAMBIENTAL,
  IniciativaSociambientalService
} from '../../../services/iniciativa-socioambiental.service';
import { loading } from '../../../util/loading';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-iniciativas-socioambientais',
  templateUrl: './iniciativas-socioambientais.component.html',
  styleUrls: [ './iniciativas-socioambientais.component.scss' ],
})
export class IniciativasSocioambientaisComponent implements OnInit {

  @Input() item: any;
  @Input() idSolicitacao: number;
  @Input() usuarioLogado: Usuario;
  opcaoOutras = false;
  iniciativasSocioambientais: any[] = [];

  idUsuarioEditar: number;
  form: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private iniciativaSocioambientalService: IniciativaSociambientalService
  ) {
    this.form = new FormGroup({
      iniciativaSocioambientalOutroCampo: new FormControl(''),
      outrasIniciativas: new FormArray([])
    });
  }

  ngOnInit() {
    this.idUsuarioEditar = this.item?.id;

    loading(
      this.iniciativaSocioambientalService.buscarIniciativasSocioambientais().pipe(
        mergeMap((iniciativasSocioambientais) => {
          this.iniciativasSocioambientais = iniciativasSocioambientais;
          this.iniciativasSocioambientais = this.iniciativasSocioambientais.map((iniciativa) => {
            iniciativa.checked = false;
            return iniciativa;
          });

          let obs = of(null);
          if (this.idSolicitacao) {
            obs = this.iniciativaSocioambientalService.buscarSolicitacoesIniciativasSocioambientais(this.idSolicitacao);
          }
          return obs;
        })
      ).subscribe((solicitacaoIniciativasSocioambientais) => {
        if (solicitacaoIniciativasSocioambientais) {
          solicitacaoIniciativasSocioambientais.result.forEach((iniciativa) => {
            const buscar = this.buscarNoArrayPorId('iniciativasSocioambientais', iniciativa.iniciativasSocioambientais);
            buscar.selecionado.checked = true;
            this.iniciativasSocioambientais[buscar.index] = buscar.selecionado;

            if (iniciativa?.iniciativasSocioambientaisOutro.length > 0) {

              iniciativa.iniciativasSocioambientaisOutro.forEach((outro) => {
                const iniciativas = this.form.get('outrasIniciativas') as FormArray;
                iniciativas.insert(this.form.get('outrasIniciativas').value.length, this.criarFormOutrasIniciativas(outro?.nome));
              });
            }
          });
          if (this.form.get('outrasIniciativas')['controls'].length > 0) {
            this.opcaoOutras = true;
          }
        }
      })
    );
  }


  buscarNoArrayPorId(array, comparacao) {
    const selecionado = this[array].find((opcao) => opcao.id === comparacao);
    const index = this[array].indexOf(selecionado);
    return {
      index,
      selecionado
    };
  }


  async selecionarOpcao(evento: any) {
    const buscar = this.buscarNoArrayPorId('iniciativasSocioambientais', evento.value);
    const outrasIniciativasSocioambientais = this.form.get('outrasIniciativas') as FormArray;
    // Não possui
    if (evento.id === INICIATIVA_SOCIOAMBIENTAL.naoPossui && evento.checked) {
      const retornoModal = await this.confirmOpcaoModal();
      if (retornoModal) {
        this.opcaoOutras = false;
        outrasIniciativasSocioambientais.clear();
        return this.iniciativasSocioambientais = this.iniciativasSocioambientais.map((iniciativa) => {
          if (iniciativa.id !== INICIATIVA_SOCIOAMBIENTAL.naoPossui) {
            iniciativa.checked = false;
          }

          return iniciativa;
        });
      } else {
        evento.checked = false;
      }
    } else if (evento.id !== INICIATIVA_SOCIOAMBIENTAL.naoPossui && evento.checked) {
      // Retirar o não possui
      const buscarNaoPossui = this.buscarNoArrayPorId('iniciativasSocioambientais', INICIATIVA_SOCIOAMBIENTAL.naoPossui);
      buscarNaoPossui.selecionado.checked = false;
      this.iniciativasSocioambientais[buscarNaoPossui.index] = buscarNaoPossui.selecionado;
      // this.form.get('iniciativaSocioambientalOutroCampo').setValue('');
    }
    this.iniciativasSocioambientais[buscar.index] = evento;
    // Outras opcoes
    this.opcaoOutras = !!this.iniciativasSocioambientais.find((opcao) => opcao?.id === INICIATIVA_SOCIOAMBIENTAL.outros)?.checked;
    if (!this.opcaoOutras) {
      if (outrasIniciativasSocioambientais) {
        outrasIniciativasSocioambientais.clear();
      }
      this.form.get('iniciativaSocioambientalOutroCampo').setValue('');
    }
  }

  criarFormOutrasIniciativas(value?): FormGroup {
    return new FormGroup({
      nome: new FormControl(value)
    });
  }

  async novoIniciativaOutro(item?) {
    if (item && !item.value) {
      await showToastMessage('É necessário informar o tipo de iniciativa socioambienta.', 'danger');
      return;
    }
    const iniciativas = this.form.get('outrasIniciativas') as FormArray;
    iniciativas.insert(this.form.get('outrasIniciativas').value.length, this.criarFormOutrasIniciativas(item.value));
    this.form.get('iniciativaSocioambientalOutroCampo').setValue('');
    setTimeout(() => {
      const id = document.getElementById('outro_' + (iniciativas.length - 1));
      this.scrollToElement(id);
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  async delIniciativaOutro(index: number) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Iniciativa Socioambiental!',
        mensagem: 'Tem certeza que deseja esta iniciativa?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const iniciativas = this.form.get('outrasIniciativas') as FormArray;
      iniciativas.removeAt(index);
    }
  }

  async confirmOpcaoModal() {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Atenção_Confirmar',
        titulo: 'Iniciativas Socioambientais',
        mensagem: `Tem certeza que deseja selecionar a opção <b>Não Possui</b>? Ao confirmar você perderá todas as outras marcações.`,
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      return data;
    }
  }


  async salvar() {

    const iniciativas = [];
    let nenhumMarcado = true;
    this.iniciativasSocioambientais.forEach((iniciativa) => {
      if (iniciativa.checked) {
        iniciativas.push(iniciativa);
        nenhumMarcado = false;
      }
    });

    if (nenhumMarcado) {
      return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
    }

    const valorOutroCampo = this.form.get('iniciativaSocioambientalOutroCampo').value;
    if ((this.opcaoOutras && this.form.get('outrasIniciativas')['controls'].length === 0) && !valorOutroCampo) {
      return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
    }

    // Outros
    const outrosNome = [];
    if (valorOutroCampo) {
      outrosNome.push(valorOutroCampo);
    }
    if (this.form.get('outrasIniciativas')['controls'] && this.form.get('outrasIniciativas')['controls'].length > 0) {
      this.form.get('outrasIniciativas')['controls'].forEach((outro) => {
        outrosNome.push(outro.value.nome);
      });
    }

    const obs = [ of(null) ];
    let obsOutro = of(null);
    iniciativas.forEach((value) => {
      // 8 => outro
      if (value.id !== INICIATIVA_SOCIOAMBIENTAL.outros) {
        obs.push(
          this.iniciativaSocioambientalService.salvarSolicitacaoIniciativasSocioambientais({
            iniciativasSocioambientais: value.id,
            solicitacao: this.idSolicitacao
          })
        );
      } else {
        obsOutro = this.iniciativaSocioambientalService.salvarSolicitacaoIniciativasSocioambientais({
          iniciativasSocioambientais: value.id,
          solicitacao: this.idSolicitacao
        });
      }
    });

    loading(
      this.iniciativaSocioambientalService.deletarSolicitacoesIniciativasSocioambientais(this.idSolicitacao)
        .pipe(
          mergeMap(() => {
            return forkJoin(obs);
          }),
          mergeMap(() => {
            return obsOutro;
          }),
          mergeMap((solicitacaoOutro) => {
            const outros = [ of(null) ];
            if (solicitacaoOutro) {
              outrosNome.forEach((outro) => {
                outros.push(
                  this.iniciativaSocioambientalService.salvarSolicitacaoIniciativasSocioambientaisOutros({
                    solicitacaoIniciativasSocioambientais: solicitacaoOutro?.id,
                    nome: outro
                  })
                );
              });
            }
            return forkJoin(outros);
          }),
        )
        .subscribe(async () => {
          await showToastMessage('Registro salvo com sucesso.', 'success');
          await this.modalCtrl.dismiss(true);
        }, async error => {
          await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
        })
    );

  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }
}

export enum TipoAtividadeEnum {
  CultivoGraos = 1,
  CultivoAlgodao,
  CultivoCana,
  Cafeicultura,
  Citricultura,
  FruticulturaHorticultura,
  GraosFibras,
  GraosPecuaria,
  Pecuaria,
  Reflorestamento,
  OutraPropriedadeRuralOuEndividamento,
  SemAtividade,
  ArmazemConvencional,
  ArmazemEstrutural,
  ArmazemInflavel,
  ArmazemGraneleiro,
  ArmazemGranelizado,
  Silo,
  OutroArmazem,
  Casa,
  Apartamento,
  Terreno,
  Escritorio,
  Loja,
  PredioComercial,
  GalpaoIndustrial,
  Galpao,
  CentroDistribuicao,
  OutroImoveisUrbanos,
  AcucarVHP,
  AlgodaoCaroco,
  AlgodaoPluma,
  Arroz,
  Boi,
  Cafe,
  Citros,
  EtanolAnidro,
  EtanolHidratado,
  Feijao,
  Milho,
  Soja,
  Trigo,
  OutroEstoqueOuRecebivel,
  Semeadeira,
  Plantadeira,
  Colheitadeira,
  Grade,
  Subsoladores,
  Pulverizador,
  Distribuidor,
  Aeronave,
  Automovel,
  Caminhonete,
  Caminhao,
  Trator,
  OutroMaquinariosEquipamentosVeiculos,
  Plataforma,
  Guincho,
  Carreta,
  PaCarregadeira,
  Algodao,
  CanaDeAcucar,
  OutroProduto = 43,
}

// tslint:disable-next-line: max-line-length
import { ChangeDetectorRef, Directive, ElementRef, Host, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { LIFECYCLE_DID_ENTER } from '@ionic/core';
import { BehaviorSubject, fromEvent, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { FonteDados, TesteVazio } from './dados';
import { IncluindoStatus } from './incluir-status';
import { incluirVazio } from './incluir-vazio';

/**
 * Monitora um Observable, para apresentar seus vários estados possíveis.
 *
 * @example
 * ```html
 *  <ng-container [appApresentarDados]="observable">
 *    <div *casoResultado="let resultado">{{resultado}}</div>
 *    <div *casoCarregando><ion-spinner name="dots"></ion-spinner></div>
 *    <div *casoErro="let erro">Erro!</div>
 *  </ng-container>
 * ```
 */
@Directive({
  selector: '[appApresentarDados]',
})
export class ApresentarDadosDirective<T> implements OnChanges, OnDestroy, OnInit {
  fonte$ = new BehaviorSubject<FonteDados<T> | undefined>(undefined);
  testeVazio = new BehaviorSubject<TesteVazio<T> | undefined>(undefined);
  private sub?: Subscription;
  private primeiraVisualizacaoTimeout?: number;

  /** Valores da fonte de dados, com informação de status e vazio */
  comStatus = this.fonte$.pipe(
    switchMap((obs) => obs?.incluindoStatus() || of<IncluindoStatus<T>>({
      timestamp: Date.now(),
      carregando: false,
    })),
    incluirVazio(this.testeVazio),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  @Input() atualizarAoMudarDePagina = true;

  @Input('appApresentarDados') set apresentarDados(fonte: FonteDados<T>) {
    this.fonte$.next(fonte);
  }

  constructor(
    private elt: ElementRef<Node>,
  ) {}

  ngOnInit() {
    this.primeiraVisualizacaoTimeout = Date.now() + 10_000;
    this.atualizarAoMudarDePaginaChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.atualizarAoMudarDePagina) {
      this.atualizarAoMudarDePaginaChange();
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  private atualizarAoMudarDePaginaChange() {
    this.sub?.unsubscribe();
    if (this.atualizarAoMudarDePagina) {
      this.sub = fromEvent<Event & {target: HTMLElement}>(document, LIFECYCLE_DID_ENTER, {capture: true}).subscribe(ev => {
        if (!ev.target.contains(this.elt.nativeElement)) {
          return;
        }
        if (this.primeiraVisualizacaoTimeout && Date.now() < this.primeiraVisualizacaoTimeout) {
          this.primeiraVisualizacaoTimeout = undefined;
        } else {
          this.fonte$.value?.recarregar();
        }
      });
    } else {
      this.sub = undefined;
    }
  }
}

/**
 * Bloco apresentado quando a fonte de dados emitiu ao menos um resultado.
 *
 * É possível saber se a fonte ainda não terminou de carregar através da
 * variável `carregando`.
 *
 * @example
 * ```html
 *  <ng-container [appApresentarDados]="infoAsync">
 *    <ng-container *casoResultado="let info, carregando = carregando">
 *      <h1>
 *        Info
 *        <ion-spinner *ngIf="carregando"></ion-spinner>
 *      </h1>
 *      {{info|json}}
 *    </ng-container>
 *    ...
 *  </ng-container>
 * ```
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[casoResultado]',
})
export class CasoResultadoDirective<T> implements OnDestroy, OnInit {
  private subscription!: Subscription;
  private context?: CasoResultadoContext<T>;

  constructor(
    @Host() private apresentarDados: ApresentarDadosDirective<T>,
    private cd: ChangeDetectorRef,
    private templateRef: TemplateRef<CasoResultadoContext<T>>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.subscription = this.apresentarDados.comStatus.pipe(
      distinctUntilChanged((x, y) => x.resultado === y.resultado &&
                                     x.carregando === y.carregando &&
                                     x.vazio === y.vazio),
    ).subscribe((x) => {
      if (x.resultado !== undefined && !x.vazio) {
        if (!this.context) {
          this.context = {
            $implicit: x.resultado,
            carregando: x.carregando,
          };
          this.viewContainer.createEmbeddedView(this.templateRef, this.context);
        } else {
          this.context.$implicit = x.resultado;
          this.context.carregando = x.carregando;
        }
      } else if (this.context) {
        this.viewContainer.clear();
        this.context = undefined;
      }
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

export interface CasoResultadoContext<T> {
  /** Valor emitido pelo `next()` */
  $implicit: T;
  /** `true` caso o Observable da fonte ainda não tenha notificado `complete()` */
  carregando: boolean;
}


/**
 * Bloco apresentado quando a fonte de dados ainda não emitiu nada.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[casoCarregando]',
})
export class CasoCarregandoDirective implements OnDestroy, OnInit {
  private subscription!: Subscription;

  constructor(
    @Host() private apresentarDados: ApresentarDadosDirective<any>,
    private templateRef: TemplateRef<void>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.subscription = this.apresentarDados.comStatus.pipe(
      map((x) => x.carregando && x.resultado === undefined),
      distinctUntilChanged(),
    ).subscribe((carregando) => {
      this.viewContainer.clear();

      if (carregando) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}


/**
 * Bloco apresentado quando a fonte de dados emite uma notificação `error()`.
 *
 * A função `repetir()` pode ser usada para recarregar a fonte.
 *
 * @example
 * ```html
 *  <ng-container [appApresentarDados]="infoAsync">
 *    ...
 *    <ng-container *casoErro="let erro, repetir = repetir">
 *      <h1>Erro</h1>
 *      <p>{{erro}}</p>
 *      <button (click)="repetir()">Tentar novamente</button>
 *    </ng-container>
 *  </ng-container>
 * ```
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[casoErro]',
})
export class CasoErroDirective implements OnDestroy, OnInit {
  private subscription!: Subscription;

  constructor(
    @Host() private apresentarDados: ApresentarDadosDirective<any>,
    private templateRef: TemplateRef<CasoErroContext>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.subscription = this.apresentarDados.comStatus.pipe(
      map((x) => x.erro),
      distinctUntilChanged(),
    ).subscribe((erro) => {
      this.viewContainer.clear();

      if (erro !== undefined) {
        this.viewContainer.createEmbeddedView(this.templateRef, {
          $implicit: erro,
          repetir: () => this.apresentarDados.fonte$.value?.recarregar(),
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

interface CasoErroContext {
  /** Erro resultante da fonte de dados */
  $implicit: any;
  /** Função para recarregar a fonte de dados */
  repetir: () => void;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { resolve } from 'src/app/util/resolve';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AtividadeQuadroSafraService {
  constructor(
    private http: HttpClient
  ) {}


  buscarAtividadesPorPerfilProdutor() {
    const url = resolve('refactory://tipoAtividadePerfilProdutor',  1 );
    return this.http.get(url).pipe(
      map((result: any) => result.items)
    );
  }

  salvarSolicitacaoQuadroSafra(solicitacao: SolicitacaoQuadroSafra) {
    const url = resolve('refactory://solicitacaoQuadroSafra');
    return this.http.post(url, solicitacao);
  }

  buscarSolicitacoesQuadroSafra(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoQuadroSafra/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }

  excluirQuadroSafra(id: number): Observable<any> {
    const url = resolve('refactory://solicitacaoQuadroSafra/id', { id });
    return this.http.delete<any>(url);
  }
}

export interface SolicitacaoQuadroSafra {
  solicitacao: number;
  culturas: any[];
}

export enum TIPO_ATIVIDADE {
  citros = 36,
  arroz = 33,
  cafe = 35,
  feijao = 39,
  milho = 40,
  soja = 41,
  trigo = 42,
  algodao = 61,
  cana_acucar = 62,
  pecuaria_bovina = 63,
  outra = 11
}

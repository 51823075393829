import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SelectComponentModule } from './components/select/select.module';
import { BrMaskerModule } from 'br-mask';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AutorizacaoInterceptor } from 'src/app/interceptors/autorizacao.interceptor';
import { IonicStorageModule } from '@ionic/storage-angular';
import { DroppableModule } from '@ctrl/ngx-droppable';
import { LightboxModule } from 'ngx-lightbox';
import { DirectivesModule } from './directives/directives.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectNovoComponentModule } from './components/select-novo/select-novo.module';
import { AlertaModalComponentModule } from './components/alerta-modal/alerta-modal.module';
import { InfoUserMenuComponentModule } from './components/info-user-menu/info-user-menu.module';
import { SolicitarAlteracaoPessoalComponentModule } from './components/solicitar-alteracao-pessoal/solicitar-alteracao-pessoal.module';
import { AlterarDadoPessoalComponentModule } from './components/alterar-dado-pessoal/alterar-dado-pessoal.module';
import { CancelarOperacaoComponentModule } from './components/cancelar-operacao/cancelar-operacao.module';
import { RecusaCancelarOperacaoComponentModule } from './components/recusa-cancelar-operacao/recusa-cancelar-operacao.module';
import { ReprovarRaroComponentModule } from './components/reprovar-raro/reprovar-raro.module';
import { ModalSelecionarFiltroComponentModule } from './modal-components/modal-selecionar-filtro/modal-selecionar-filtro.module';
import { ModalVisualizarVideoModule } from './modal-components/modal-visualizar-video/modal-visualizar-video.module';
import { ModalSolicitacaoParceiroComponentModule } from './modal-components/modal-solicitacao-parceiro/modal-solicitacao-parceiro.module';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: '',
    }),
    DroppableModule,
    LightboxModule,
    BrMaskerModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    SelectComponentModule,
    SelectNovoComponentModule,
    IonicStorageModule.forRoot({
      name: 'TERRALOGS_ST'
    }),
    DirectivesModule,
    BrowserAnimationsModule,
    AlertaModalComponentModule,
    InfoUserMenuComponentModule,
    SolicitarAlteracaoPessoalComponentModule,
    AlterarDadoPessoalComponentModule,
    CancelarOperacaoComponentModule,
    RecusaCancelarOperacaoComponentModule,
    InfoUserMenuComponentModule,
    ReprovarRaroComponentModule,
    ModalSelecionarFiltroComponentModule,
    ModalVisualizarVideoModule,
    ModalSolicitacaoParceiroComponentModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AutorizacaoInterceptor, multi: true
    }
  ],
  bootstrap: [ AppComponent ],
  exports: [
    DirectivesModule
  ]
})
export class AppModule {
}

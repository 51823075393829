import { Injectable } from '@angular/core';
import { resolve } from '../util/resolve';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeguroRuralService {
  constructor(
    private http: HttpClient
  ) {}

  buscarSegurosRurais(): Observable<any> {
    const url = resolve('refactory://seguroRural');
    return this.http.get<any>(url).pipe(
      map((result: any) => result.items)
    );
  }

  salvarSeguroRural(solicitacao: SolicitacaoSeguroRural) {
    const url = resolve('refactory://solicitacaoSeguroRural');
    return this.http.post(url, solicitacao);
  }

  buscarSolicitacaoSeguroRural(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoSeguroRural/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }
}

export interface SolicitacaoSeguroRural {
  nomeSeguradora?: string;
  solicitacao?: number;
  seguroRural?: number;
}

export interface SolicitacaoSeguroRuralOutros {
  nome?: string;
  solicitacaoSeguroRural?: number;
}

export enum SEGURO {
  outros = 10,
  naoPossui = 11
}




import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IncluindoStatus } from './incluir-status';

export interface IncluindoVazio<T> extends IncluindoStatus<T> {
  vazio?: boolean;
}

export function incluirVazio<T>(testeVazio: Observable<((valor: T) => boolean) | undefined>) {
  return (obs: Observable<IncluindoStatus<T>>) => combineLatest([
    obs,
    testeVazio,
  ]).pipe(
    // tslint:disable-next-line: no-shadowed-variable
    map(([x, testeVazio]): IncluindoVazio<T> => {
      // tslint:disable-next-line: no-non-null-assertion
      if (!testeVazio || x.erro || (x.carregando && !x.resultado) || !testeVazio(x.resultado!)) {
        return x;
      }

      return {...x, vazio: true};
    }),
  );
}

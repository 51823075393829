import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { resolve } from 'src/app/util/resolve';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PecuariaCorteService {
  constructor(
    private http: HttpClient
  ) {}

  salvarSolicitacaoPecuariaCorte(solicitacao: SolicitacaoPecuariaCorte) {
    const url = resolve('refactory://solicitacaoPecuariaCorte');
    return this.http.post(url, solicitacao);
  }

  deletarSolicitacaoPecuariaCorte(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoPecuariaCorte/porSolicitacao/idSolicitacao', { idSolicitacao });
    return this.http.delete(url);
  }

  buscarSolicitacoesPecuariasCortes(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoPecuariaCorte/porSolicitacao/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }

  excluirSolicitacaoPecuariaCorte(id: number): Observable<any> {
    const url = resolve('refactory://solicitacaoPecuariaCorte/idPecuariaCorte', { idPecuariaCorte: id });
    return this.http.delete<any>(url);
  }

}

export interface SolicitacaoPecuariaCorte {
  solicitacao?: number;
  safras?: any[];
}

export enum TIPO_ANIMAL_REBANHO {
  qtVacaMatrizRebanho= 1,
  qtFemeas12MesesRebanho = 2,
  qtFemeas1224MesesRebanho = 3,
  qtFemeas2436MesesRebanho = 4,
  qtFemeas36MesesRebanho = 5,
  qtTouroReprodutorRebanho = 6,
  qtMachos12MesesRebanho = 7,
  qtMachos1224MesesRebanho = 8,
  qtMachos2436MesesRebanho = 9,
  qtMachos36MesesRebanho = 10
}

export enum TIPO_ANIMAL_PRODUCAO_QUANTIDADE {
  qtVacaMatrizProducao         = 1,
  qtFemeas12MesesProducao      = 2,
  qtFemeas1224MesesProducao    = 3,
  qtFemeas2436MesesProducao    = 4,
  qtFemeas36MesesProducao      = 5,
  qtTouroReprodutorProducao    = 6,
  qtMachos12MesesProducao      = 7,
  qtMachos1224MesesProducao    = 8,
  qtMachos2436MesesProducao    = 9,
  qtMachos36MesesProducao      = 10
}

export enum TIPO_ANIMAL_PRODUCAO_VALOR {
  valorVacaMatrizProducao      = 1,
  valorFemeas12MesesProducao   = 2,
  valorFemeas1224MesesProducao = 3,
  valorFemeas2436MesesProducao = 4,
  valorFemeas36MesesProducao   = 5,
  valorTouroReprodutorProducao = 6,
  valorMachos12MesesProducao   = 7,
  valorMachos1224MesesProducao = 8,
  valorMachos2436MesesProducao = 9,
  valorMachos36MesesProducao   = 10
}

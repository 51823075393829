import { Params } from '@angular/router';
import * as moment from 'moment';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParamsPaginacao, RetornoApiPaginado } from '../models/api';

export function mapParamsPaginacao(limitPadrao?: number): (params: Params) => ParamsPaginacao {
  return params => {
    const resultado: ParamsPaginacao = {};
    if (+params.page) {
      resultado.page = params.page;
    }
    if (+params.limit) {
      resultado.limit = params.limit;
    } else if (limitPadrao) {
      resultado.limit = '' + limitPadrao;
    }
    return resultado;
  };
}

export function mapRetornoApiPaginado<K extends string, T>(
  chave: K,
  paramsPaginacao?: ParamsPaginacao,
): OperatorFunction<RetornoApiPaginado<K, T>, Pagina<T>> {
  return map(retorno => lerRetornoApiPaginado(retorno, chave, paramsPaginacao));
}

export function lerRetornoApiPaginado<K extends string, T>(
  retorno: RetornoApiPaginado<K, T>,
  chave: K,
  paramsPaginacao?: ParamsPaginacao,
): Pagina<T> {

  const pagina: Pagina<T> = {
    itens: retorno.data[chave],
    total: retorno.data.pagination.count,
  };
  pagina.itens.forEach(element => {
    element['dataCadastro'] = moment(element['dataCadastro']).format('DD/MM/YYYY');
    element['dataSolicitacao'] = moment(element['dataSolicitacao']).format('DD/MM/YYYY');
    element['dataAtualizacao'] = moment(element['dataAtualizacao']).format('DD/MM/YYYY HH:mm');
  });
  if (retorno.data.pagination.previous) {
    pagina.paramsAnterior = retorno.data.pagination.previous;
  }
  if (retorno.data.pagination.next) {
    pagina.paramsProxima = retorno.data.pagination.next;
  }
  const limit = pagina.paramsAnterior?.limit || pagina.paramsProxima?.limit;
  if (+limit) {
    pagina.itensPorPagina = +limit;
  } else if (+paramsPaginacao?.limit >= pagina.total) {
    pagina.itensPorPagina = +paramsPaginacao.limit;
  }
  return pagina;
}

export interface Pagina<T> {
  itens: T[];
  itensPorPagina?: number;
  total: number;
  paramsProxima?: ParamsPaginacao;
  paramsAnterior?: ParamsPaginacao;
}

function decodificarQueryString(queryParams: string): ParamsPaginacao | undefined {
  try {
    const url = new URL(queryParams, 'http://example.com');
    return {
      page: url.searchParams.get('page') || undefined,
      limit: url.searchParams.get('limit') || undefined,
    };
  } catch {
    return undefined;
  }
}

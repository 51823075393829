// tslint:disable-next-line: max-line-length
import { asapScheduler, BehaviorSubject, combineLatest, defer, from, interval, NEVER, Observable, of, Subscribable, SubscribableOrPromise, throwError } from 'rxjs';
import { audit, catchError, map, shareReplay, skip, startWith, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { IncluindoStatus, incluirStatus } from './incluir-status';

export type TesteVazio<T> = (item: T) => boolean;

export class FonteDados<T, P = void> {
  observable: Observable<IncluindoStatus<T>>;
  private trigger = new BehaviorSubject<null>(null);
  private firstResolves: Array<() => void> = [];
  private lastResolves: Array<() => void> = [];

  constructor(fonte: Observable<T> | (() => SubscribableOrPromise<T>));
  constructor(
    fonte: ((param: P) => SubscribableOrPromise<T>),
    param: Observable<P>,
  );
  constructor(
    fonte: Observable<T> | ((param: P) => SubscribableOrPromise<T>),
    param?: Observable<P>,
  ) {
    const fonteFn = typeof fonte === 'function' ? fonte : () => fonte;
    let valorPresente = false;

    let paramObs: Observable<{carregando?: boolean, param?: P, err?: unknown}>;
    if (param) {
      paramObs = param.pipe(
        // tslint:disable-next-line: no-shadowed-variable
        map((param) => ({param})),
        startWith({carregando: true}),
        // Em caso de erro, emitir este erro, mas observar trigger para
        // tentar novamente
        catchError((err, retry) => this.trigger.pipe(
          // Por ser um BehaviorSubject, trigger sempre vai retornar um valor
          // imediatamente após ser assinado; devemos ignorá-lo, e tentar
          // novamente só caso seja acionado depois
          skip(1),
          switchMapTo(retry),
          startWith({err}),
        )),
      );
    } else {
      paramObs = of({});
    }

    this.observable = combineLatest([paramObs, this.trigger]).pipe(
      switchMap(([p]) => {
        let obs: Observable<T>;
        if (p.carregando) {
          obs = NEVER;
        } else if (p.err) {
          obs = throwError(p.err);
        } else {
          // tslint:disable-next-line: no-non-null-assertion
          obs = from(fonteFn(p.param!));
        }
        return obs.pipe(
          incluirStatus(),
        );
      }),
      audit((status) => {
        if (status.resultado !== undefined) {
          valorPresente = true;
        } else if (status.erro) {
          valorPresente = false;
        }
        // Se fizermos um refresh por trigger e já houver dado presente,
        // aguardar um pouco para evitar um flicker de "carregando"
        return status.carregando && status.resultado === undefined && valorPresente
            ? interval(50)
            : interval(0, asapScheduler);
      }),
      catchError((erro) => of<IncluindoStatus<T>>({timestamp: Date.now(), carregando: false, erro})),
      tap((status) => {
        if (status.carregando && status.resultado === undefined) {
          return;
        }
        flushCallbacks(this.firstResolves);
        if (!status.carregando) {
          flushCallbacks(this.lastResolves);
        }
      }),
      shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  static consumindo<P, T>(
    param: Observable<P> | (() => Subscribable<P>),
    fonte: (param: P) => SubscribableOrPromise<T>,
  ) {
    return new FonteDados(fonte, typeof param === 'function' ? defer(param) : param);
  }

  incluindoStatus(): Observable<IncluindoStatus<T>> {
    return this.observable;
  }

  recarregar(momento?: Resolver): Promise<void> {
    return new Promise((resolve) => {
      switch (momento) {
        case Resolver.QuandoRetornarPrimeiroDado:
          this.firstResolves.push(resolve);
          break;
        case Resolver.QuandoConcluir:
          this.lastResolves.push(resolve);
          break;
        default:
          resolve();
      }
      this.trigger.next(null);
    });
  }
}

export const enum Resolver {
  QuandoRetornarPrimeiroDado,
  QuandoConcluir,
}

function flushCallbacks(callbacks: Array<() => void>) {
  while (true) {
    const resolve = callbacks.pop();
    if (!resolve) {
      break;
    }
    resolve();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { resolve } from 'src/app/util/resolve';
import { StorageService } from './storage.service';
import { AlertController, NavController } from '@ionic/angular';
import jwtDecode from 'jwt-decode';

export interface Autorizacao {
  data: any;
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

@Injectable({
  providedIn: 'root'
})
export class AutorizacaoService {
  public autorizacao$: ReplaySubject<Autorizacao>;
  public logout$: ReplaySubject<boolean>;

  tempoRestante;
  tempoAlerta;
  minutosPreExpiracao = 10;

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private alertController: AlertController,
    private navCtrl: NavController,
  ) {
    this.autorizacao$ = new ReplaySubject<Autorizacao>(1);
    this.logout$ = new ReplaySubject(1);

    this.storage.get<Autorizacao>('autorizacao').then(autorizacao => {
      this.autorizacao$.next(autorizacao);
    });
  }

  login(username: string, password: string): Observable<Autorizacao> {
    const url = resolve('refactory://login');
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify({ 'username': username, 'password': password });
    //  `email=${username}&password=${password}`;

    return this
      .http
      .post<Autorizacao>(url, body, { headers })
      .pipe(
        catchError(err => {
          if (err.error) {
            if (err.error.result.code === 401) {
              return throwError('Endereço de e-mail ou senha inválidos.' || err.error.result.message);
            } else if (err.error.result.code === 400) {
              return throwError(err.error.result.message);
            } else if (err.error.result.code === 404) {
              return throwError(err.error.result.message);
            } else {
              return throwError('Não foi possível autenticar-se. Verifique sua conexão e tente novamente mais tarde.');
            }
          }
        })
      );
  }

  async autorizacao(): Promise<Autorizacao> {
    return this.autorizacao$.pipe(first()).toPromise();
  }

  usar(autorizacao: Autorizacao): Observable<Autorizacao> {
    if (autorizacao) {
      this.storage.set('autorizacao', autorizacao);
    } else {
      this.storage.remove('autorizacao');
    }
    this.autorizacao$.next(autorizacao);
    return of(autorizacao);
  }

  delete() {
    localStorage.removeItem('TERRALOGS_LOGIN');
    localStorage.removeItem('TERRALOGS-TOKEN');
    localStorage.removeItem('TERRALOGS-USER');
    localStorage.removeItem('TERRALOGS_NOTIFICACOES');
    return this.storage.remove('autorizacao');
  }

  iniciarSessao() {
    const user = localStorage.getItem('TERRALOGS-TOKEN');
    const tokenDecode: any = jwtDecode(user);

    this.tempoAlerta = this.minutosPreExpiracao * 60;
    const tempoAtual = Math.floor(new Date().getTime() / 1000);
    let tempoExpirar = tokenDecode.exp;

    const intervaloSessao = setInterval(() => {
      tempoExpirar--;
      this.tempoRestante = tempoExpirar - tempoAtual;

      if (this.tempoRestante > 0 && this.tempoRestante < this.tempoAlerta) {
        this.modalRefreshToken();
        clearInterval(intervaloSessao);
      }
    }, 1000);
  }

  modalRefreshToken() {
    setTimeout(async () => {
      await this.alertController.dismiss();
      this.apresentarModal(
        'Sessão Expirada!',
        'Sua sessão expirou. Você será redirecionado à tela de login novamente.',
        'Sair'
      );
    }, this.tempoAlerta * 1000);

    this.alertController.create({
      cssClass: 'alert-custom-class',
      mode: 'ios',
      header: 'Sessão Expirando!',
      message: 'Sua sessão vai expirar. Deseja se manter conectado?',
      backdropDismiss: false,
      keyboardClose: false,
      buttons: [
        {
          text: 'Manter conectado',
          handler: () => {
            this.alertController.dismiss();
            this.refreshToken();
          }
        }
      ]
    }).then((alert) => alert.present());
  }

  refresh(username: string, password: string) {
    return this.login(username, password)
      .pipe(
        mergeMap((auth) => this.usar(auth))
      );
  }

  refreshToken() {
    const url = resolve('refactory://refreshLogin');
    return this.http.post(url, {}).subscribe((refresh: any) => {
      const token = refresh.data.token;
      localStorage.setItem('TERRALOGS-TOKEN', token);
      this.autorizacao$.next(token);
      this.iniciarSessao();
    });
  }

  apresentarModal(header, message, textButton) {
    this.delete();
    this.navCtrl.navigateRoot('/login');
    this.alertController.create({
      cssClass: 'alert-custom-class',
      mode: 'ios',
      header,
      message,
      buttons: [
        {
          text: textButton,
          handler: () => {
            this.alertController.dismiss();
          }
        }
      ]
    }).then((alert) => alert.present());
  }

}

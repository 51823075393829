import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DinheiroSemCentavosPipe } from './dinheiro-sem-centavos.pipe';
import { AnoDuploPipe } from './ano-duplo.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DinheiroSemCentavosPipe,
    AnoDuploPipe,
    SafeHtmlPipe
  ],
  exports: [
    DinheiroSemCentavosPipe,
    AnoDuploPipe,
    SafeHtmlPipe
  ],
})
export class FormatacaoModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { resolve } from '../util/resolve';
import { Observable } from 'rxjs';
import { Estado, Finalidade, Financiamento, Municipio, TempoResidencia } from '@zellotec/terralogs_regras/models';
import {map, pluck} from 'rxjs/operators';
import { TipoAtividade } from '@zellotec/terralogs_regras/models/tipo-atividade';
import { InstituicaoBancaria } from '@zellotec/terralogs_regras/models/instituicao-bancaria';
import { TipoModalidadeDivida } from '@zellotec/terralogs_regras/models/tipo-modalidade-divida';
import { TipoGarantia } from '@zellotec/terralogs_regras/models/tipo-garantia';
import { TipoFormaPagamento } from '@zellotec/terralogs_regras/models/tipo-forma-pagamento';
import { TipoMoeda } from '@zellotec/terralogs_regras/models/tipo-moeda';
import { TipoAtividadeCategoria } from '@zellotec/terralogs_regras/models/tipo-atividade-categoria';
import { RamoAtividade } from '@zellotec/terralogs_regras/models/ramo-atividade';
import { TipoAplicacao } from '@zellotec/terralogs_regras/models/tipoAplicacao';
import { PeriodoSafra } from '@zellotec/terralogs_regras/models/periodo-safra';
import { TipoTitulo } from '@zellotec/terralogs_regras/models/tipo-titulo';
import { Comprador } from '@zellotec/terralogs_regras/models/comprador';
import { QuantidadeSafrasAno } from '@zellotec/terralogs_regras/models/quantidade-safras-ano';
import { GrauParentesco } from '@zellotec/terralogs_regras/models/grau-parentesco';
import { Vinculo } from '@zellotec/terralogs_regras/models/vinculo';
import { TipoCultura } from '@zellotec/terralogs_regras/models/tipo-cultura';
import { TipoSolo } from '@zellotec/terralogs_regras/models/tipo-solo';
import { TipoAgricultura } from '@zellotec/terralogs_regras/models/tipo-agricultura';
import { TipoProducaoSafra } from '@zellotec/terralogs_regras/models/tipo-producao-safra';
import { TipoAnimal } from '@zellotec/terralogs_regras/models/tipo-animal';
import { SistemaProducao } from '@zellotec/terralogs_regras/models/sistema-producao';
import { CicloReprodutivo } from '@zellotec/terralogs_regras/models/ciclo-reprodutivo';
import { Guia } from '@zellotec/terralogs_regras/models/guia';
import { Formulario } from '@zellotec/terralogs_regras/models/formulario';
import { StatusTransacaoCredito } from '@zellotec/terralogs_regras/models/status-transacao-credito';
import { TipoGarantiaCredito } from '@zellotec/terralogs_regras/models/tipo-garantia-credito';

@Injectable({
  providedIn: 'root'
})
export class DominioService {
  private cache = new Map<string, any>();

  constructor(
    private http: HttpClient
  ) { }

  private async carregar(target: string, params?: Record<string, any>, getFromCache: boolean = true) {
    const url = resolve(target, params);
    if (this.cache.has(url) && getFromCache) {
      return this.cache.get(url);
    }
    const data = await this.http.get(url).toPromise();
    this.cache.set(url, data);
    return data;
  }

  async find(name: string, type?: string, sortDomains?: 'ASC' | 'DESC', filter?: string, getFromCache?: boolean): Promise<any> {
    return this.carregar('backend://domain', { name, sortDomains, filter }, getFromCache).then((resp: any) => {
      if (resp.data && resp.data.forms) {
        const list = resp.data.forms[0].list;
        if (type && type === 'json') {
          return this.parseJSONToObject(list);
        } else {
          return list;
        }
      }
    });
  }

  parseJSONToObject(list: any[]) {
    const result = [];
    if (list) {
      for (let i = 0; i < list.length; i++) {
        try {
          result.push(JSON.parse(list[i]));
        } catch (err) {
        }
      }
    }
    return result;
  }

  buscarEstados(): Observable<Estado[]> {
    const url = resolve('refactory://estados');
    return this.http.get<Estado>(url).pipe(pluck('items'));
  }

  buscarMunicipios(idEstado: number): Observable<Municipio[]> {
    const url = resolve('refactory://municipios/idEstado', { idEstado });
    return this.http.get<Municipio>(url).pipe(pluck('items'));
  }

  buscarFinanciamentos(): Observable<Financiamento[]> {
    const url = resolve('refactory://financiamentos');
    return this.http.get<Financiamento>(url).pipe(pluck('items'));
  }

  buscarFinalidades(idFinanciamento: number): Observable<Finalidade[]> {
    const url = resolve('refactory://finalidades/idFinanciamento', { idFinanciamento });
    return this.http.get<Finalidade>(url).pipe(pluck('items'));
  }

  buscarTemposResidencia(): Observable<TempoResidencia[]> {
    const url = resolve('refactory://temposResidencia');
    return this.http.get<TempoResidencia>(url).pipe(pluck('items'));
  }

  buscarTemposExercicio(): Observable<TempoResidencia[]> {
    const url = resolve('refactory://temposExercicio');
    return this.http.get<TempoResidencia>(url).pipe(pluck('items'));
  }

  buscarInstituicoesBancarias(): Observable<InstituicaoBancaria[]> {
    const url = resolve('refactory://instituicaoBancaria');
    return this.http.get<InstituicaoBancaria>(url).pipe(pluck('items'));
  }

  buscarTiposModalidadeDivida(ibTrading: boolean): Observable<TipoModalidadeDivida[]> {
    const url = resolve('refactory://tiposModalidadeDivida', { ibTrading });
    return this.http.get<TipoModalidadeDivida>(url).pipe(pluck('items'));
  }

  buscarTiposGarantia(): Observable<TipoGarantia[]> {
    const url = resolve('refactory://tiposGaratia');
    return this.http.get<TipoGarantia>(url).pipe(pluck('items'));
  }

  buscarTiposFormaPagamento(): Observable<TipoFormaPagamento[]> {
    const url = resolve('refactory://tiposFormaPagamento');
    return this.http.get<TipoFormaPagamento>(url).pipe(pluck('items'));
  }

  buscarTiposMoeda(): Observable<TipoMoeda[]> {
    const url = resolve('refactory://tiposMoeda');
    return this.http.get<TipoMoeda>(url).pipe(pluck('items'));
  }

  buscarTiposSolo(): Observable<TipoSolo[]> {
    const url = resolve('refactory://tiposSolo');
    return this.http.get<TipoSolo>(url).pipe(pluck('items'));
  }

  buscarTiposAtividade(idCategoria = null): Observable<TipoAtividade[]> {
    let url;

    if (idCategoria) {
      url = resolve('refactory://tiposAtividade/idCategoria', { idCategoria });
    } else {
      url = resolve('refactory://tiposAtividade/principal', { isPrincipal: true });
    }

    return this.http.get<TipoAtividade>(url).pipe(pluck('items'));
  }

  buscarGrauParentesco(): Observable<GrauParentesco[]> {
    const url = resolve('refactory://grauParentesco');
    return this.http.get<GrauParentesco>(url).pipe(pluck('items'));
  }

  buscarQuantidadeSafrasAno(): Observable<QuantidadeSafrasAno[]> {
    const url = resolve('refactory://quantidadeSafrasAno');
    return this.http.get<QuantidadeSafrasAno>(url).pipe(pluck('items'));
  }

  buscarTiposAtividadeCategoria(idCategoria = null): Observable<TipoAtividadeCategoria[]> {
    const url = resolve('refactory://tiposAtividadeCategoria/idCategoria', { idCategoria });
    return this.http.get<TipoAtividadeCategoria>(url).pipe(pluck('items'));
  }

  buscarRamosAtividade(): Observable<RamoAtividade[]> {
    const url = resolve('refactory://ramosAtividade');
    return this.http.get<TipoAtividade>(url).pipe(pluck('items'));
  }

  buscarTiposAplicacao(): Observable<TipoAplicacao[]> {
    const url = resolve('refactory://tiposAplicacao');
    return this.http.get<TipoAtividade>(url).pipe(pluck('items'));
  }

  buscarTiposAnimais(): Observable<TipoAnimal[]> {
    const url = resolve('refactory://tiposAnimais');
    return this.http.get<TipoAnimal>(url).pipe(pluck('items'));
  }

  buscarSistemasProducao(): Observable<SistemaProducao[]> {
    const url = resolve('refactory://sistemasProducao');
    return this.http.get<SistemaProducao>(url).pipe(pluck('items'));
  }

  buscarCiclosReprodutivos(): Observable<CicloReprodutivo[]> {
    const url = resolve('refactory://ciclosReprodutivos');
    return this.http.get<CicloReprodutivo>(url).pipe(pluck('items'));
  }

  buscarTipoTitulos(): Observable<TipoTitulo[]> {
    const url = resolve('refactory://tipoTitulos');
    return this.http.get<TipoTitulo>(url).pipe(pluck('items'));
  }

  buscarTipoCulturas(): Observable<TipoCultura[]> {
    const url = resolve('refactory://tipoCulturas');
    return this.http.get<TipoCultura>(url).pipe(pluck('items'));
  }

  buscarTipoAgricultura(): Observable<TipoAgricultura[]> {
    const url = resolve('refactory://tipoAgricultura');
    return this.http.get<TipoAgricultura>(url).pipe(pluck('items'));
  }

  buscarTipoProducaoSafras(): Observable<TipoProducaoSafra[]> {
    const url = resolve('refactory://producaoSafra');
    return this.http.get<TipoProducaoSafra>(url).pipe(pluck('items'));
  }

  buscarPeriodosSafra(idTipoProducao = null): Observable<PeriodoSafra[]> {
    let url;

    if (idTipoProducao) {
      url = resolve('refactory://periodoSafra/idTipoProducao', { idTipoProducao });
    } else {
      url = resolve('refactory://periodoSafra');
    }

    return this.http.get<PeriodoSafra>(url).pipe(pluck('items'));
  }

  buscarVinculos(): Observable<Vinculo[]> {
    const url = resolve('refactory://vinculos');
    return this.http.get<Vinculo>(url).pipe(pluck('items'));
  }

  buscarCompradores(): Observable<Comprador[]> {
    const url = resolve('refactory://compradores');
    return this.http.get<Comprador>(url).pipe(pluck('items'));
  }

  buscarFornecedoresClientes(): Observable<any> {
    const url = resolve('refactory://clienteFornecedor');
    return this.http.get<any>(url).pipe(pluck('items'));
  }

  buscarTiposCancelamento(): Observable<TipoProducaoSafra[]> {
    const url = resolve('refactory://tipoCancelamento');
    return this.http.get<TipoProducaoSafra>(url).pipe(
      map((result: any) => result.items)
    );
  }

  buscarGuias(): Observable<Guia[]> {
    const url = resolve('refactory://guias');
    return this.http.get<Guia>(url).pipe(
      map((result: any) => result.items)
    );
  }

  buscarFormularios(): Observable<Formulario[]> {
    const url = resolve('refactory://formularios');
    return this.http.get<Formulario>(url).pipe(
      map((result: any) => result.items)
    );
  }

  buscarFormulariosPorGuia(idGuia: number): Observable<Formulario[]> {
    const url = resolve('refactory://formulariosPorGuia', { idGuia });
    return this.http.get<Formulario>(url, {
      params: { guia: String(idGuia) },
    }).pipe(
      map((result: any) => result.items)
    );
  }

  buscarStatusTransacaoCredito(): Observable<StatusTransacaoCredito[]> {
    const url = resolve('refactory://statusTransacaoCredito');
    return this.http.get<StatusTransacaoCredito>(url).pipe(
      map((result: any) => result.items)
    );
  }

  buscarTiposGarantiaCredito(): Observable<TipoGarantiaCredito[]> {
    const url = resolve('refactory://tiposGarantiaCredito');
    return this.http.get<TipoGarantiaCredito>(url).pipe(
      map((result: any) => result.items)
    );
  }
}

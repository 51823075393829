import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import { objectKeys } from 'codelyzer/util/objectKeys';
import { DominioService } from '../../../services/dominio.service';
import { TipoAnimal } from '@zellotec/terralogs_regras/models/tipo-animal';
import { SistemaProducao } from '@zellotec/terralogs_regras/models/sistema-producao';
import { CicloReprodutivo } from '@zellotec/terralogs_regras/models/ciclo-reprodutivo';
import { forkJoin } from 'rxjs';
import { loading } from '../../../util/loading';
import {
  PecuariaCorteService,
  TIPO_ANIMAL_PRODUCAO_QUANTIDADE,
  TIPO_ANIMAL_PRODUCAO_VALOR,
  TIPO_ANIMAL_REBANHO
} from '../../../services/pecuaria-corte.service';
import { parseTextToDouble, showToastMessage } from '../../../util/utils';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'app-pecuaria-corte',
  templateUrl: './pecuaria-corte.component.html',
  styleUrls: [ './pecuaria-corte.component.scss' ],
})
export class PecuariaCorteComponent implements OnInit {

  @Input() anoBase: string;
  @Input() idSolicitacao: number;
  inFocus;
  periodos = [];
  customPopoverOptions: any = {
    subHeader: 'Selecione uma opção',
  };
  form: FormGroup;
  tiposAnimais: TipoAnimal[] = [];
  sistemasProducao: SistemaProducao[] = [];
  ciclosReprodutivos: CicloReprodutivo[] = [];
  periodosSafras = [];

  constructor(
    private modalCtrl: ModalController,
    private pecuariaCorteService: PecuariaCorteService,
    private utilService: UtilService,
    private dominioService: DominioService
  ) {
    this.form = new FormGroup({
      pecuariaCorte: new FormArray([]),
    });
  }

  async ngOnInit() {
    await this.getPeriodosSafra();
    this.periodos = this.criarPeriodoPecuaria();

    loading(
      forkJoin([
        this.dominioService.buscarTiposAnimais(),
        this.dominioService.buscarSistemasProducao(),
        this.dominioService.buscarCiclosReprodutivos()
      ]).subscribe(([ tipoAnimais, sistemasProducao, ciclosReprodutivos ]) => {
        this.tiposAnimais = tipoAnimais;
        this.sistemasProducao = sistemasProducao;
        this.ciclosReprodutivos = ciclosReprodutivos;
      })
    );

    if (this.idSolicitacao) {
      loading(
        this.pecuariaCorteService
          .buscarSolicitacoesPecuariasCortes(this.idSolicitacao).subscribe((solicitacao) => {
          if (solicitacao.result.length > 0) {
            for (const pecuariaCorte of solicitacao.result) {
              const anoBaseIndex = this.periodos.findIndex((periodo) => pecuariaCorte.anoBase.toString() === periodo.nome);
              this.periodos[anoBaseIndex].checked = true;
              const formsPecuariaCorte = this.form.get('pecuariaCorte') as FormArray;
              const estoqueRebanhoSol = this.retornarSolicitacaoEstoqueRebanho(pecuariaCorte.solicitacaoEstoqueRebanho);
              const custoProducaoSol = this.retornarSolicitacaoCustoProducao(pecuariaCorte.solicitacaoCustoProducao);
              const animalComercializado = this.retornarSolicitacaoAnimalComercializado
              (pecuariaCorte.solicitacaoAnimalComercializado).arrayAnimalComercializado;
              const arrayIdTipoAnimais = this.retornarSolicitacaoAnimalComercializado
              (pecuariaCorte.solicitacaoAnimalComercializado).arrayIdTipoAnimais;
              formsPecuariaCorte.insert(this.form.get('pecuariaCorte').value.length,
                this.criarFormPecuariaCorte(
                  pecuariaCorte.anoBase.toString(),
                  pecuariaCorte.cicloReprodutivo?.id,
                  pecuariaCorte.sistemaProducao?.id,
                  estoqueRebanhoSol.qtVacaMatrizRebanho ? estoqueRebanhoSol.qtVacaMatrizRebanho.quantidade : null,
                  estoqueRebanhoSol.qtTouroReprodutorRebanho ? estoqueRebanhoSol.qtTouroReprodutorRebanho.quantidade : null,
                  estoqueRebanhoSol.qtFemeas12MesesRebanho ? estoqueRebanhoSol.qtFemeas12MesesRebanho.quantidade : null,
                  estoqueRebanhoSol.qtFemeas1224MesesRebanho ? estoqueRebanhoSol.qtFemeas1224MesesRebanho.quantidade : null,
                  estoqueRebanhoSol.qtFemeas2436MesesRebanho ? estoqueRebanhoSol.qtFemeas2436MesesRebanho.quantidade : null,
                  estoqueRebanhoSol.qtFemeas36MesesRebanho ? estoqueRebanhoSol.qtFemeas36MesesRebanho.quantidade : null,
                  estoqueRebanhoSol.qtMachos12MesesRebanho ? estoqueRebanhoSol.qtMachos12MesesRebanho.quantidade : null,
                  estoqueRebanhoSol.qtMachos1224MesesRebanho ? estoqueRebanhoSol.qtMachos1224MesesRebanho.quantidade : null,
                  estoqueRebanhoSol.qtMachos2436MesesRebanho ? estoqueRebanhoSol.qtMachos2436MesesRebanho.quantidade : null,
                  estoqueRebanhoSol.qtMachos36MesesRebanho ? estoqueRebanhoSol.qtMachos36MesesRebanho.quantidade : null,
                  custoProducaoSol.qtVacaMatrizProducao ? custoProducaoSol.qtVacaMatrizProducao.quantidade : null,
                  custoProducaoSol.qtVacaMatrizProducao ? this.qtdFormatada(custoProducaoSol.qtVacaMatrizProducao.valor) : null,
                  custoProducaoSol.qtFemeas12MesesProducao ? custoProducaoSol.qtFemeas12MesesProducao.quantidade : null,
                  custoProducaoSol.qtFemeas12MesesProducao ? this.qtdFormatada(custoProducaoSol.qtFemeas12MesesProducao.valor) : null,
                  custoProducaoSol.qtFemeas1224MesesProducao ? custoProducaoSol.qtFemeas1224MesesProducao.quantidade : null,
                  custoProducaoSol.qtFemeas1224MesesProducao ? this.qtdFormatada(custoProducaoSol.qtFemeas1224MesesProducao.valor) : null,
                  custoProducaoSol.qtFemeas2436MesesProducao ? custoProducaoSol.qtFemeas2436MesesProducao.quantidade : null,
                  custoProducaoSol.qtFemeas2436MesesProducao ? this.qtdFormatada(custoProducaoSol.qtFemeas2436MesesProducao.valor) : null,
                  custoProducaoSol.qtFemeas36MesesProducao ? custoProducaoSol.qtFemeas36MesesProducao.quantidade : null,
                  custoProducaoSol.qtFemeas36MesesProducao ? this.qtdFormatada(custoProducaoSol.qtFemeas36MesesProducao.valor) : null,
                  custoProducaoSol.qtTouroReprodutorProducao ? custoProducaoSol.qtTouroReprodutorProducao.quantidade : null,
                  custoProducaoSol.qtTouroReprodutorProducao ? this.qtdFormatada(custoProducaoSol.qtTouroReprodutorProducao.valor) : null,
                  custoProducaoSol.qtMachos12MesesProducao ? custoProducaoSol.qtMachos12MesesProducao.quantidade : null,
                  custoProducaoSol.qtMachos12MesesProducao ? this.qtdFormatada(custoProducaoSol.qtMachos12MesesProducao.valor) : null,
                  custoProducaoSol.qtMachos1224MesesProducao ? custoProducaoSol.qtMachos1224MesesProducao.quantidade : null,
                  custoProducaoSol.qtMachos1224MesesProducao ? this.qtdFormatada(custoProducaoSol.qtMachos1224MesesProducao.valor) : null,
                  custoProducaoSol.qtMachos2436MesesProducao ? custoProducaoSol.qtMachos2436MesesProducao.quantidade : null,
                  custoProducaoSol.qtMachos2436MesesProducao ? this.qtdFormatada(custoProducaoSol.qtMachos2436MesesProducao.valor) : null,
                  custoProducaoSol.qtMachos36MesesProducao ? custoProducaoSol.qtMachos36MesesProducao.quantidade : null,
                  custoProducaoSol.qtMachos36MesesProducao ? this.qtdFormatada(custoProducaoSol.qtMachos36MesesProducao.valor) : null,
                  this.qtdFormatada(pecuariaCorte?.custoPastagemProducao),
                  this.qtdFormatada(pecuariaCorte?.diariaConfinamentoProducao),
                  pecuariaCorte?.diasConfinamentoProducao?.toString(),
                  pecuariaCorte?.animaisConfinadosProducao?.toString(),
                  animalComercializado,
                  arrayIdTipoAnimais,
                  pecuariaCorte?.capaciadadeLotacaoConfinamento?.toString(),
                  pecuariaCorte?.pesoConfinamento,
                  pecuariaCorte?.diasConfinamento?.toString(),
                  pecuariaCorte?.taxaDescarteZootecnicos ? this.qtdFormatada(pecuariaCorte?.taxaDescarteZootecnicos) : null,
                  pecuariaCorte?.taxaDesfruteZootecnicos ? this.qtdFormatada(pecuariaCorte?.taxaDesfruteZootecnicos) : null,
                  pecuariaCorte?.diasConfinamentoZootecnicos ? this.qtdFormatada(pecuariaCorte?.diasConfinamentoZootecnicos) : null)
              );
            }
          }
        })
      );
    }
  }

  qtdFormatada(value) {
    if (!value) {
      return null;
    }
    const options = { minimumFractionDigits: 2 };
    return new Intl.NumberFormat('pt-BR', options).format(parseFloat(value));
  }

  getPeriodosSafra() {
    this.periodosSafras = this.utilService.gerarSafras(+this.anoBase, true, 2, 2, true);
  }

  criarFormPecuariaCorte(nomeForm?, cicloProdutivo?, sistemaProducao?, qtVacaMatrizRebanho?,
                         qtTouroReprodutorRebanho?, qtFemeas12MesesRebanho?,
                         qtFemeas1224MesesRebanho?, qtFemeas2436MesesRebanho?, qtFemeas36MesesRebanho?,
                         qtMachos12MesesRebanho?, qtMachos1224MesesRebanho?, qtMachos2436MesesRebanho?,
                         qtMachos36MesesRebanho?, qtVacaMatrizProducao?, valorVacaMatrizProducao?,
                         qtFemeas12MesesProducao?, valorFemeas12MesesProducao?, qtFemeas1224MesesProducao?,
                         valorFemeas1224MesesProducao?, qtFemeas2436MesesProducao?, valorFemeas2436MesesProducao?,
                         qtFemeas36MesesProducao?, valorFemeas36MesesProducao?, qtTouroReprodutorProducao?,
                         valorTouroReprodutorProducao?, qtMachos12MesesProducao?, valorMachos12MesesProducao?,
                         qtMachos1224MesesProducao?, valorMachos1224MesesProducao?, qtMachos2436MesesProducao?,
                         valorMachos2436MesesProducao?, qtMachos36MesesProducao?, valorMachos36MesesProducao?,
                         custoProducaoPastagem?, diariaConfinamento?, diasConfinamento?, qtAnimaisConfinados?,
                         tipoAnimaisComercializados = [], tipoAnimaisComercializadosSelect?, capacidadeLotacaoConfinamento?,
                         ganhoPesoMedioDiarioAnimal?, diasConfinamentoLote?, taxaDescarteFemeas?,
                         taxaDesfruteRebanho?, diasConfinamentoLoteZootecnicos?): FormGroup {
    return new FormGroup({
      nomeForm: new FormControl(nomeForm),
      cicloProdutivo: new FormControl(cicloProdutivo),
      sistemaProducao: new FormControl(sistemaProducao),
      qtVacaMatrizRebanho: new FormControl(qtVacaMatrizRebanho),
      qtTouroReprodutorRebanho: new FormControl(qtTouroReprodutorRebanho),
      qtFemeas12MesesRebanho: new FormControl(qtFemeas12MesesRebanho),
      qtFemeas1224MesesRebanho: new FormControl(qtFemeas1224MesesRebanho),
      qtFemeas2436MesesRebanho: new FormControl(qtFemeas2436MesesRebanho),
      qtFemeas36MesesRebanho: new FormControl(qtFemeas36MesesRebanho),
      qtMachos12MesesRebanho: new FormControl(qtMachos12MesesRebanho),
      qtMachos1224MesesRebanho: new FormControl(qtMachos1224MesesRebanho),
      qtMachos2436MesesRebanho: new FormControl(qtMachos2436MesesRebanho),
      qtMachos36MesesRebanho: new FormControl(qtMachos36MesesRebanho),
      qtVacaMatrizProducao: new FormControl(qtVacaMatrizProducao),
      valorVacaMatrizProducao: new FormControl(valorVacaMatrizProducao),
      qtFemeas12MesesProducao: new FormControl(qtFemeas12MesesProducao),
      valorFemeas12MesesProducao: new FormControl(valorFemeas12MesesProducao),
      qtFemeas1224MesesProducao: new FormControl(qtFemeas1224MesesProducao),
      valorFemeas1224MesesProducao: new FormControl(valorFemeas1224MesesProducao),
      qtFemeas2436MesesProducao: new FormControl(qtFemeas2436MesesProducao),
      valorFemeas2436MesesProducao: new FormControl(valorFemeas2436MesesProducao),
      qtFemeas36MesesProducao: new FormControl(qtFemeas36MesesProducao),
      valorFemeas36MesesProducao: new FormControl(valorFemeas36MesesProducao),
      qtTouroReprodutorProducao: new FormControl(qtTouroReprodutorProducao),
      valorTouroReprodutorProducao: new FormControl(valorTouroReprodutorProducao),
      qtMachos12MesesProducao: new FormControl(qtMachos12MesesProducao),
      valorMachos12MesesProducao: new FormControl(valorMachos12MesesProducao),
      qtMachos1224MesesProducao: new FormControl(qtMachos1224MesesProducao),
      valorMachos1224MesesProducao: new FormControl(valorMachos1224MesesProducao),
      qtMachos2436MesesProducao: new FormControl(qtMachos2436MesesProducao),
      valorMachos2436MesesProducao: new FormControl(valorMachos2436MesesProducao),
      qtMachos36MesesProducao: new FormControl(qtMachos36MesesProducao),
      valorMachos36MesesProducao: new FormControl(valorMachos36MesesProducao),
      custoProducaoPastagem: new FormControl(custoProducaoPastagem),
      diariaConfinamento: new FormControl(diariaConfinamento),
      diasConfinamento: new FormControl(diasConfinamento),
      qtAnimaisConfinados: new FormControl(qtAnimaisConfinados),
      tipoAnimaisComercializados: new FormArray(tipoAnimaisComercializados),
      tipoAnimaisComercializadosSelect: new FormControl(tipoAnimaisComercializadosSelect),
      capacidadeLotacaoConfinamento: new FormControl(capacidadeLotacaoConfinamento),
      ganhoPesoMedioDiarioAnimal: new FormControl(ganhoPesoMedioDiarioAnimal),
      diasConfinamentoLote: new FormControl(diasConfinamentoLote),
      taxaDescarteFemeas: new FormControl(taxaDescarteFemeas),
      taxaDesfruteRebanho: new FormControl(taxaDesfruteRebanho),
      diasConfinamentoLoteZootecnicos: new FormControl(diasConfinamentoLoteZootecnicos),
    });
  }

  criarFormAnimalComercializado(nomeTipoAnimalComercializado?, idTipoAnimalComercializado?,
                                quantidade?, precoVenda?): FormGroup {
    return new FormGroup({
      nomeTipoAnimalComercializado: new FormControl(nomeTipoAnimalComercializado),
      idTipoAnimalComercializado: new FormControl(idTipoAnimalComercializado),
      quantidade: new FormControl(quantidade),
      precoVenda: new FormControl(precoVenda),
    });
  }

  retornarSolicitacaoEstoqueRebanho(estoqueRebanho) {
    if (!estoqueRebanho.length) {
      return {};
    }
    const objRebanho: any = {};
    for (const rebanho of estoqueRebanho) {
      if (rebanho.tipoAnimal.id === TIPO_ANIMAL_REBANHO[TIPO_ANIMAL_REBANHO[rebanho.tipoAnimal.id]]) {
        objRebanho[TIPO_ANIMAL_REBANHO[rebanho.tipoAnimal.id]] = {
          quantidade: rebanho.quantidade.toString()
        };
      }
    }
    return objRebanho;
  }

  retornarSolicitacaoCustoProducao(custoProducao) {
    const objCustoProducao: any = {};
    if (!custoProducao.length) {
      return objCustoProducao;
    }
    for (const producao of custoProducao) {
      if (producao.tipoAnimal.id === TIPO_ANIMAL_PRODUCAO_QUANTIDADE[TIPO_ANIMAL_PRODUCAO_QUANTIDADE[producao.tipoAnimal.id]]) {
        objCustoProducao[TIPO_ANIMAL_PRODUCAO_QUANTIDADE[producao.tipoAnimal.id]] = {
          quantidade: (producao.quantidade).toString(),
          valor: (producao.valor || 0).toString()
        };
      }
    }
    return objCustoProducao;
  }

  retornarSolicitacaoAnimalComercializado(animalComercializado) {
    const arrayAnimalComercializado = [];
    const arrayIdTipoAnimais = [];
    if (animalComercializado.length > 0) {
      for (const animal of animalComercializado) {
        arrayAnimalComercializado.push(
          this.criarFormAnimalComercializado(
            animal.tipoAnimal?.nome,
            animal.tipoAnimal?.id,
            animal.quantidade?.toString(),
            this.qtdFormatada(animal.preco?.toString())
          )
        );
        arrayIdTipoAnimais.push(animal.tipoAnimal?.id);
      }
    }

    return {
      arrayAnimalComercializado,
      arrayIdTipoAnimais
    };
  }

  focusInput(evt, tipo) {
    this.inFocus = tipo;
  }

  adicionarFormPecuariaCorte(nomeForm) {
    const formPecuariaCorte = this.form.get('pecuariaCorte') as FormArray;
    formPecuariaCorte.insert(this.form.get('pecuariaCorte').value.length, this.criarFormPecuariaCorte(nomeForm));
    setTimeout(() => {
      const id = document.getElementById('pecuariaCorte_' + (formPecuariaCorte.length - 1));
      this.scrollToElement(id);
    });
  }

  alterarAnimaisComercializados(evento: any, indexForm: any) {
    const tipoAnimaisComercializados =
      this.form.get('pecuariaCorte').get(indexForm.toString()).get('tipoAnimaisComercializados') as FormArray;
    tipoAnimaisComercializados.clear();
    if (evento.detail.value.length > 0) {
      for (const tipoAnimal of evento.detail.value) {
        const tipoAnimalRef = this.tiposAnimais.find((tipo) => tipo.id === tipoAnimal);
        tipoAnimaisComercializados.insert(tipoAnimaisComercializados.length,
          this.criarFormAnimalComercializado(tipoAnimalRef.nome, tipoAnimalRef.id));
      }
    }
  }

  async removerFormPecuariaCorte(index: number, evento) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir periodo pecuaria de corte!',
        mensagem: 'Tem certeza que deseja excluir este periodo do pecuaria de corte?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const formPecuariaCorte = this.form.get('pecuariaCorte') as FormArray;
      formPecuariaCorte.removeAt(index);
    } else {
      evento.checked = !evento.checked;
    }
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  async selecionarOpcao(evento) {
    const formsPecuariaCorte = this.form.get('pecuariaCorte') as FormArray;
    const indexPecuariaCorte = formsPecuariaCorte.controls.findIndex((form) => evento.nome === form.get('nomeForm').value);

    if (evento.checked && indexPecuariaCorte === -1) {
      this.adicionarFormPecuariaCorte(evento.nome);
    } else {
      this.removerFormPecuariaCorte(indexPecuariaCorte, evento);
    }
  }

  criarPeriodoPecuaria() {
    return this.periodosSafras.map((periodo, id) => {
      return {
        id,
        nome: periodo.label,
        checked: false
      };
    });
  }

  async salvar() {

    const safras = [];
    const formsPecuariaCorte = this.form.get('pecuariaCorte') as FormArray;

    if (formsPecuariaCorte.length === 0) {
      return await showToastMessage('Selecione pelo menos um período do formulário.', 'danger');
    }

    for (const form1 of formsPecuariaCorte.controls) {
      const values = objectKeys(form1.value);
      if (!form1.value['cicloProdutivo'.toString()]) {
        return await showToastMessage('Ciclo produtivo do formulário não foi preenchido.', 'danger');
      }
      const safra = {
        anoBase: form1.value['nomeForm'.toString()],
        cicloReprodutivo: form1.value['cicloProdutivo'.toString()],
        sistemaProducao: form1.value['sistemaProducao'.toString()],
        custoPastagemProducao: parseTextToDouble(form1.value['custoProducaoPastagem'.toString()]),
        diariaConfinamentoProducao: parseTextToDouble(form1.value['diariaConfinamento'.toString()]),
        diasConfinamentoProducao: parseTextToDouble(form1.value['diasConfinamento'.toString()]),
        animaisConfinadosProducao: parseTextToDouble(form1.value['qtAnimaisConfinados'.toString()]),
        capaciadadeLotacaoConfinamento: parseTextToDouble(form1.value['capacidadeLotacaoConfinamento'.toString()]),
        pesoConfinamento: parseTextToDouble(form1.value['ganhoPesoMedioDiarioAnimal'.toString()]),
        diasConfinamento: parseTextToDouble(form1.value['diasConfinamento'.toString()]),
        taxaDescarteZootecnicos: parseTextToDouble(form1.value['taxaDescarteFemeas'.toString()]),
        taxaDesfruteZootecnicos: parseTextToDouble(form1.value['taxaDesfruteRebanho'.toString()]),
        diasConfinamentoZootecnicos: parseTextToDouble(form1.value['diasConfinamentoLoteZootecnicos'.toString()]),
        animaisComercializados: [],
        estoqueRebanho: [],
        custoProducao: [],
      };
      for (const campo of values) {
        if (form1.value[campo.toString()] == null) {
          continue;
        }
        // Tipo Animal Rebanho
        if (TIPO_ANIMAL_REBANHO[campo.toString()]) {
          safra.estoqueRebanho.push(
            {
              tipoAnimal: TIPO_ANIMAL_REBANHO[campo.toString()],
              quantidade: parseTextToDouble(form1.value[campo.toString()])
            }
          );
        }
        // Tipo Animal Producao Quantidade
        if (TIPO_ANIMAL_PRODUCAO_QUANTIDADE[campo.toString()]) {
          safra.custoProducao.push(
            {
              tipoAnimal: TIPO_ANIMAL_PRODUCAO_QUANTIDADE[campo.toString()],
              quantidade: parseTextToDouble(form1.value[campo.toString()])
            }
          );
        }
        // Tipo Animal Producao Valor
        if (TIPO_ANIMAL_PRODUCAO_VALOR[campo.toString()]) {
          const safraId = safra.custoProducao.findIndex((safraCusto) =>
            safraCusto.tipoAnimal === TIPO_ANIMAL_PRODUCAO_VALOR[campo.toString()]);
          safra.custoProducao[safraId].valor = parseTextToDouble(form1.value[campo.toString()]);
        }
      }
      // Animais comercializados
      if (form1.value['tipoAnimaisComercializados'.toString()].length > 0) {
        for (const animalComercializado of form1.value['tipoAnimaisComercializados'.toString()]) {
          safra.animaisComercializados.push(
            {
              tipoAnimal: animalComercializado.idTipoAnimalComercializado,
              quantidade: parseTextToDouble(animalComercializado.quantidade),
              preco: parseTextToDouble(animalComercializado.precoVenda)
            }
          );
        }
      }
      safras.push(safra);
    }
    loading(
      this.pecuariaCorteService.salvarSolicitacaoPecuariaCorte(
        {
          solicitacao: this.idSolicitacao,
          safras
        }
      ).subscribe(async () => {
        await showToastMessage('Registro salvo com sucesso.', 'success');
        await this.modalCtrl.dismiss(true);
      }, async () => {
        await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
      })
    );
  }

  async fechar() {
    return this.modalCtrl.dismiss();
  }

  async validarPorcentagem(item) {
    const valor = item.value.replaceAll(',', '.');
    if (+valor > 100) {
      item.setValue(null);
      await showToastMessage('O percentual informado deve ser menor ou igual a 100%.', 'danger');
      return;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { isCpfValid, parseTextToDouble, showToastMessage, validarCNPJ } from '../../../util/utils';
import { ModalController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Usuario } from '@zellotec/terralogs_regras/models';
import { last } from 'lodash';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import { CondominioAgropecuarioService } from '../../../services/condominio-agropecuario.service';
import { loading } from '../../../util/loading';

@Component({
  selector: 'app-condominio',
  templateUrl: './condominio.component.html',
  styleUrls: [ './condominio.component.scss' ],
})
export class CondominioComponent implements OnInit {

  @Input() item: any;
  @Input() idSolicitacao: number;
  @Input() usuarioLogado: Usuario;
  @Input() nomeGrupo: string;
  @Input() percencualSocietario: any;

  idUsuarioEditar: number;
  form: FormGroup;
  submitted: boolean;
  novosParticipantes = false;
  btnDesabilitadoNovosParticipante = true;
  percentual;

  constructor(
    private modalCtrl: ModalController,
    private condominioAgropecuarioService: CondominioAgropecuarioService
  ) {
    this.form = new FormGroup({
      nomeGrupo: new FormControl('', Validators.required),
      percentualSocietarioSolicitante: new FormControl('', [ Validators.required, Validators.maxLength(14) ]),
      outrosParticipantes: new FormArray([])
    });
  }

  ngOnInit() {
    this.idUsuarioEditar = this.item?.id;
    this.carregarFormulario();
  }

  carregarFormulario() {
    this.form.get('nomeGrupo').setValue(this.nomeGrupo);
    this.form.get('percentualSocietarioSolicitante').setValue(this.qtdFormatada(this.percencualSocietario) || null);

    if (this.idSolicitacao) {
      this.condominioAgropecuarioService.buscarCondominioAgropecuario(this.idSolicitacao)
        .subscribe((solicitacao) => {
          if (solicitacao) {
            const solicitacoes = this.form.get('outrosParticipantes') as FormArray;
            solicitacao.result.forEach((pessoa) => {

              const obj: any = {
                percentualSocietario: this.qtdFormatada(pessoa.percentualSocietario)
              };

              if (pessoa.cnpj) {
                obj.nome = pessoa.razaoSocial;
                obj.cpfCnpj = pessoa.cnpj;
              } else {
                obj.nome = pessoa.nome;
                obj.cpfCnpj = pessoa.cpf;
              }

              solicitacoes.insert(this.form.get('outrosParticipantes').value.length, this.criarFormOutrosParticipantes(obj));
            });

            if (this.form.get('outrosParticipantes')['controls'].length > 0) {
              this.novosParticipantes = true;
            }

            if (solicitacao && solicitacao.result?.length) {
              this.validarPercentual();
            }
          }
        });
    }
  }

  alterarPercentual(evento: any) {
    const percentual = parseTextToDouble(evento.detail.value);
    this.btnDesabilitadoNovosParticipante = percentual === 100;
  }

  criarFormOutrosParticipantes(solicitacao?): FormGroup {
    return new FormGroup({
      nome: new FormControl(solicitacao?.nome, null),
      cpfCnpj: new FormControl(solicitacao?.cpfCnpj, null),
      percentualSocietario: new FormControl(solicitacao?.percentualSocietario, null)
    });
  }

  async novoParticipante() {
    const participantes = this.form.get('outrosParticipantes') as FormArray;

    if (!this.novosParticipantes) {
      this.novosParticipantes = true;
      return participantes.insert(this.form.get('outrosParticipantes')
        .value.length, this.criarFormOutrosParticipantes());
    }

    const item = last<any>(this.form.get('outrosParticipantes').value);
    if (item && (!item.nome && !item.cpfCnpj)) {
      await showToastMessage('É necessário informar dados do participante.', 'danger');
      return;
    }

    participantes.insert(this.form.get('outrosParticipantes').value.length, this.criarFormOutrosParticipantes());
    setTimeout(() => {
      const id = document.getElementById('outro_' + (participantes.length - 1));
      this.scrollToElement(id);
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  async excluirParticipante(index: number) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Participante!',
        mensagem: 'Tem certeza que deseja excluir este participante?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const participantes = this.form.get('outrosParticipantes') as FormArray;
      participantes.removeAt(index);
      this.novosParticipantes = this.form.get('outrosParticipantes').value.length !== 0;
      await this.validarPercentual();
    }
  }

  async validarCNPJCPF(evt, itemForm) {
    let msg;
    if (itemForm.value) {
      if ((itemForm.value.length === 14 && !isCpfValid(itemForm.value))) {
        msg = 'O CPF informado é inválido.';
      } else if ((itemForm.value.length === 18 && !validarCNPJ(itemForm.value))) {
        msg = 'O CNPJ informado é inválido.';
      } else if (!isCpfValid(itemForm.value) && !validarCNPJ(itemForm.value)) {
        msg = 'O campo informado está inválido.';
      }
    } else {
      return;
    }

    if (this.form.get('outrosParticipantes').value.filter(participante => participante.cpfCnpj === itemForm.value).length > 1) {
      msg = 'O CPF/CNPJ já foi informado.';
    }

    if (msg) {
      itemForm.value = null;
      await showToastMessage(msg, 'danger');
      return;
    }
  }

  async validarPercentual(status?) {
    let msg;
    let total = 0;
    let tt = 0;
    const percentualSocietarioSolicitante = parseTextToDouble(this.form.get('percentualSocietarioSolicitante').value);
    if ((percentualSocietarioSolicitante && percentualSocietarioSolicitante >= 1)) {
      total += percentualSocietarioSolicitante;
      this.percentual = total;
    } else {
      msg = 'Informe o percentual societário do solicitante.';
    }

    if ((this.form.get('outrosParticipantes').value.length &&
      this.form.get('outrosParticipantes').value[0].percentualSocietario)) {
      for (const i in this.form.get('outrosParticipantes').value) {
        const outroParticipante = parseTextToDouble(this.form.get('outrosParticipantes').value[i].percentualSocietario);
        this.percentual += outroParticipante;

        if (outroParticipante && outroParticipante !== 0) {

          if (typeof outroParticipante !== 'number' || isNaN(outroParticipante)) {
            this.form.get('outrosParticipantes').value[i].percentualSocietario = null;
          } else {
            tt += outroParticipante;
          }
        } else {
          msg = 'Informe o percentual societário.';
          this.form.get('outrosParticipantes').value[i].percentualSocietario = null;
          break;
        }
      }
      if ((total + tt) > 100) {
        msg = 'A somatória dos percentuais está acima de 100%.';
      } else {
        total += tt;
      }
    }

    if (total > 100) {
      msg = 'O percentual informado está acima de 100%.';
    } else if (status && (total <= 99)) {
      msg = 'O percentual total informado deve ser igual a 100%.';
    }

    if (msg) {
      await showToastMessage(msg, 'danger');
      return msg;
    }
  }

  qtdFormatada(value) {
    if (!value) {
      return null;
    }
    const options = { minimumFractionDigits: 2 };
    return new Intl.NumberFormat('pt-BR', options).format(parseFloat(value));
  }

  async salvar() {
    let msg;
    const percentualSocietarioSolicitante = parseTextToDouble(this.form.get('percentualSocietarioSolicitante').value);
    if (this.form.get('nomeGrupo').status === 'INVALID') {
      await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
      return;
    } else if ((this.form.get('nomeGrupo').value && percentualSocietarioSolicitante <= 100)
      || (percentualSocietarioSolicitante.toString().length > 0)) {
      msg = await this.validarPercentual('salvar');
    } else {
      this.form.get('percentualSocietarioSolicitante').setValue(null);
    }

    if (msg) {
      return;
    }

    if ((!msg || this.form.get('outrosParticipantes').value.toString().length > 0)) {
      if (this.form.get('outrosParticipantes').value.toString().length > 0) {
        let item;
        for (const i in this.form.get('outrosParticipantes').value) {
          item = this.form.get('outrosParticipantes').value[i];
          if ((!item.nome && !item.cpfCnpj && !item.percentual)) {
            this.form.get('outrosParticipantes').value.splice(i, 1);
          } else if ((!item.nome || !item.percentualSocietario)) {
            await showToastMessage('É necessário informar dados do participante.', 'danger');
            return;
          } else if (item.cpfCnpj && !isCpfValid(item.cpfCnpj) && !validarCNPJ(item.cpfCnpj)) {
            await showToastMessage('CPF/CNPJ informado é inválido.', 'danger');
            return;
          }
        }
      }
    }

    const obj = {
      nomeCondominio: this.form.value.nomeGrupo,
      percentualSocietarioSolicitante: parseTextToDouble(this.form.value.percentualSocietarioSolicitante),
      solicitacao: this.idSolicitacao,
      outrosParticipantes: this.form.value.outrosParticipantes.map(outro => {

        const retorno: any = {
          percentualSocietario: parseTextToDouble(outro.percentualSocietario)
        };

        // CPF
        if (outro.cpfCnpj) {
          if (outro.cpfCnpj.length <= 14) {
            retorno.cpf = outro.cpfCnpj;
            retorno.nome = outro.nome;
          } else if (outro.cpfCnpj.length > 14) {
            retorno.cnpj = outro.cpfCnpj;
            retorno.razaoSocial = outro.nome;
          }
        } else {
          retorno.nome = outro.nome;
        }

        return retorno;
      })
    };

    loading(
      this.condominioAgropecuarioService.registrarCondominioAgropecuario(
        this.idSolicitacao, obj
      ).subscribe(async () => {
        await showToastMessage('Registro salvo com sucesso.', 'success');
        await this.modalCtrl.dismiss(true);
      }, async error => {
        await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
      })
    );

  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }
}




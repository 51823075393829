import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, merge, of, Subject } from 'rxjs';
import { FonteDados } from '../components/apresentar-dados';
import { isEqual } from 'lodash';
import {
  calcularPreenchimentoSessao,
  mapearSolicitacao,
  SolicitacaoSessao,
  validarObrigatorios
} from '../util/mapeamento-solicitacao';
import { ActivatedRoute } from '@angular/router';
import { TabsService } from './tabs.service';
import { SolicitacaoService } from './solicitacao.service';
import { environment } from '../../environments/environment';
import { Solicitacao } from '@zellotec/terralogs_regras/models';
import { SituacaoSolicitacaoEnum } from '../enums/situacao-solicitacao.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private safras: any[] = [];
  private safras$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(this.safras);
  private recarregar$: Subject<any> = new Subject<any>();
  private atualizarUrl$: Subject<Solicitacao> = new Subject<Solicitacao>();

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private solicitacaoService: SolicitacaoService,
    private tabsService: TabsService
  ) {}

  recarregar() {
    this.recarregar$.next(Math.random());
  }

  private atualizarUrl(solicitacao) {
    return this.atualizarUrl$.next(solicitacao);
  }

  getAtualizacaoUrl() {
    return this.atualizarUrl$;
  }

  getDados() {
    return merge(
      this.activatedRoute.queryParams.pipe(
        map(params => this.solicitacaoService.obterParamsGetSolicitacao(params)),
        distinctUntilChanged(isEqual)
      ),
      this.recarregar$.asObservable().pipe()
    );
  }

  async carregarDados(idSolicitacao?: number) {
    if (idSolicitacao) {
      localStorage.setItem('MODAL_PREENCHIMENTO_OBRIGATORIO', JSON.stringify(true));
      return FonteDados.consumindo(
        this.getDados(),
        () => this.solicitacaoService.buscar(idSolicitacao)
          .pipe(
            distinctUntilChanged(isEqual),
            map((solicitacao) => mapearSolicitacao(solicitacao)),
            map((solicitacao) => calcularPreenchimentoSessao(solicitacao)),
            map((solicitacao) => validarObrigatorios(solicitacao)),
            tap(solicitacao => this.alterarSituacaoSolicitacao(solicitacao)),
            tap((solicitacao) => this.atualizarUrl(solicitacao)),
            tap(solicitacao => this.tabsService.liberarTabs(solicitacao)),
            tap(solicitacao => this.tabsService.alterarTitulo(
                solicitacao.informacoesGerais.campos.nome,
                solicitacao.menuTodos,
                solicitacao.id
              )
            ),
            tap(solicitacao => this.tabsService.habilitarResumo(solicitacao.situacaoSolicitacao))
          ),
      );
    } else {
      return FonteDados.consumindo(
        this.activatedRoute.queryParams.pipe(
          map(params => this.solicitacaoService.obterParamsGetSolicitacoes(params)),
        ),
        () => of(new SolicitacaoSessao()).pipe(
          map((solicitacao) => validarObrigatorios(solicitacao)),
          tap(solicitacao => this.tabsService.liberarTabs(solicitacao)),
          tap((solicitacao) => this.atualizarUrl(solicitacao))
        ),
      );
    }
  }

  getAnoBase(mesBase: number) {
    const mesAtual = new Date().getMonth() + 1;
    const anoAtual = new Date().getFullYear();
    return mesBase <= mesAtual ? anoAtual - 1 : anoAtual - 2;
  }

  getSafras() {
    return this.safras$.asObservable();
  }

  gerarSafras(anoBase = new Date().getFullYear(),
              anoUnico = true,
              qtdAnosPassados = 0,
              qtdAnosFuturos = 4,
              contemRetorno = false) {
    if (typeof anoBase !== 'number') {
      console.error('Ano informado é inválido para calcular os períodos de safra');
      return;
    }

    const safras = [];

    for (let i = 1; i <= qtdAnosPassados; i++) {
      const value = anoBase - i;
      const label = anoUnico ? `${ value }` : `${ value }/${ String(value + 1).slice(2) }`;
      safras.push({ value, label });
    }

    safras.reverse();

    for (let i = 0; i <= qtdAnosFuturos; i++) {
      const value = anoBase + i;
      const label = anoUnico ? `${ value }` : `${ value }/${ String(value + 1).slice(2) }`;
      safras.push({ value, label });
    }
    this.safras$.next(safras);

    if (contemRetorno) {
      return safras;
    }
  }

  getUrl() {
    return `${ environment.hosts.refactory.protocol }://${ environment.hosts.refactory.host }`;
  }

  getUrlImagem() {
    return `${ this.getUrl() }/arquivo/download/`;
  }

  getUrlImagemUsuario() {
    return `${ this.getUrl() }/arquivo/download/usuario/`;
  }

  alterarSituacaoSolicitacao(solicitacao) {
    const isPreSolicitacao = solicitacao.situacaoSolicitacao === SituacaoSolicitacaoEnum.SolicitacaoIniciada;
    if (solicitacao.menuTodos && isPreSolicitacao) {
      this.solicitacaoService.preencherRaro(solicitacao.id)
        .subscribe(() => {}, error => {
          console.error(error.message || 'Não foi possível alterar a situação desta solicitação!');
        });
    }
  }
}

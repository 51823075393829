export enum TipoCategoriaEnum {
  PropriedadeRural = 1,
  Armazem,
  ImoveisUrbanos,
  Semoventes,
  MaquinariosEquipamentosVeiculos,
  ParticipacoesAcionarias,
  AplicacoesFinanceiras,
  Estoque,
  Recebivel,
  Endividamento,
  PropriedadeUrbana,
  PenhorSafra,
  Aval
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { resolve } from 'src/app/util/resolve';

@Injectable({
  providedIn: 'root'
})
export class AtividadeAreaProducaoService {
  constructor(
    private http: HttpClient
  ) {}


  salvarSolicitacaoAreaProducao(solicitacao: SolicitacaoAreaProducao) {
    const url = resolve('refactory://solicitacaoAreaProducao');
    return this.http.post(url, solicitacao);
  }

  buscarSolicitacoesAreaProducao(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoAreaProducao/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }

  buscarSolicitacoesAreaProducaoPorPeriodo(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoAreaProducao/areaProducao/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data.result)
    );
  }

}

export interface SolicitacaoAreaProducao {
  solicitacao: number;
  safras: any[];
}

export enum TIPO_PRODUCAO {
  agricultura = 1,
  pecuaria = 2,
  agricultura_pecuaria = 3
}


import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PerfilEnum } from 'src/app/enums/perfil.enum';
import { SolicitacaoParceiro, SolicitacoesParceiroService } from 'src/app/services/solicitacoes-parceiro.service';
import { showToastMessage } from 'src/app/util/utils';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'modal-solicitacao-parceiro',
  templateUrl: './modal-solicitacao-parceiro.component.html',
  styleUrls: [ './modal-solicitacao-parceiro.component.scss' ],
})
export class ModalSolicitacaoParceiroComponent implements OnInit {

  @Input() solicitacao: SolicitacaoParceiro;

  form: FormGroup;
  usuarioLogado;
  perfilParceiro = false;
  statusOpt: SelectOptions[] = [
    { value: 'analise', label: 'Em Análise' },
    { value: 'aprovado', label: 'Aprovado' },
    { value: 'reprovado', label: 'Reprovado' },
  ];
  documentos = [];

  constructor(private modalCtrl: ModalController,
              private usuarioService: UsuarioService,
              private solicitacaoService: SolicitacoesParceiroService
  ) {
    this.form = new FormGroup({
      id: new FormControl(''),
      dataCadastro: new FormControl(''),
      usuarioParceiroNome: new FormControl(''),
      enderecoPlanilha: new FormControl(''),
      status: new FormControl(''),
      motivo: new FormControl(''),
      dataAtualizacao: new FormControl(''),
      usuarioAtendenteNome: new FormControl(''),
    })
  }

  ngOnInit() {
    this.usuarioLogado = this.usuarioService.buscarUsuarioLogado();
    this.perfilParceiro = this.usuarioLogado.perfil === PerfilEnum.Parceiro;
    this.form.setValue({
      id: this.solicitacao?.id,
      dataCadastro: this.solicitacao?.dataCadastro,
      usuarioParceiroNome: this.solicitacao?.usuarioParceiro?.nome,
      enderecoPlanilha: this.solicitacao?.enderecoPlanilha,
      status: this.solicitacao?.status,
      motivo: this.solicitacao?.motivo??'',
      dataAtualizacao: this.solicitacao?.dataAtualizacao??'',
      usuarioAtendenteNome: this.solicitacao?.usuarioAtendente?.nome??'',
    });
    if (this.solicitacao?.enderecoDocumentos?.length) {
      this.solicitacao?.enderecoDocumentos.split(';;;').map(endereco => {
        this.documentos.push({
          'label': 'Documento pessoal (RG ou CNH)',
          'value': endereco
        });
      })
    }
    if (this.solicitacao?.enderecoComprovantesEnderecos?.length) {
      this.solicitacao?.enderecoComprovantesEnderecos.split(';;;').map(endereco => {
        this.documentos.push({
          'label': 'Comprovante de endereço',
          'value': endereco
        });
      })
    }
    if (this.solicitacao?.enderecoImpostoRenda?.length) {
      this.solicitacao?.enderecoImpostoRenda.split(';;;').map(endereco => {
        this.documentos.push({
          'label': 'Declaração e recibo do Imposto de Renda',
          'value': endereco
        });
      })
    }
    if (this.solicitacao?.enderecoCertidaoCasamento?.length) {
      this.solicitacao?.enderecoCertidaoCasamento.split(';;;').map(endereco => {
        this.documentos.push({
          'label': 'Certidão de Casamento',
          'value': endereco
        });
      })
    }
    if (this.solicitacao?.enderecoMatriculas?.length) {
      this.solicitacao?.enderecoMatriculas.split(';;;').map(endereco => {
        this.documentos.push({
          'label': 'Matrícula Imóvel',
          'value': endereco
        });
      })
    }
  }

  async confirmar() {
    const data: any = {
      status: this.form.get('status').value,
      motivo: this.form.get('motivo').value
    };
    this.solicitacaoService.updateSolicitacao(data, this.solicitacao?.id).then(async () => {
      await showToastMessage('Solicitação atualizada com sucesso!', 'success');
      await this.modalCtrl.dismiss(true);
    });
  }

  async fechar() {
    await this.modalCtrl.dismiss(false);
  }

  motivoLiberadoParaPreenchimento() {
    return this.form.get('status').value === 'reprovado';
  }

  solicitacaoLiberadaParaPreenchimento() {
    return this.solicitacao?.status === 'analise';
  }

  updateStatus() {
    this.form.get('motivo').clearValidators();
    this.form.patchValue({ motivo: '' });
    if (this.form.get('status').value === 'reprovado') {
      this.form.get('motivo').setValidators([Validators.required, Validators.minLength(5)]);
    }
    this.form.get('motivo').updateValueAndValidity();
  }
}

export interface SelectOptions {
  label: String;
  value: String;
}

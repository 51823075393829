import { asyncScheduler, defer, NEVER, of, throwError } from 'rxjs';
import { delay, subscribeOn } from 'rxjs/operators';
import { FonteDados } from './dados';

export const FonteDadosMock = {
  carregando: () => new FonteDados(NEVER),
  resultado: <T>(resultado: T, tempo?: number) => new FonteDados(defer(() => of(resultado).pipe(
    delay(tempo ?? (Math.random() * 200)),
  ))),
  erro: (err?: unknown, tempo?: number) => new FonteDados(defer(() => throwError(err || new Error()).pipe(
    subscribeOn(asyncScheduler, tempo ?? (Math.random() * 200))
  ))),
};

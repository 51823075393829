import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'painel',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'esqueci-senha',
    loadChildren: () => import('./pages/esqueci-senha/esqueci-senha.module').then(m => m.EsqueciSenhaPageModule)
  },
  {
    path: 'home',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'usuarios',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosPageModule)
  },
  {
    path: 'cadastro',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/cadastro/cadastro.module').then(m => m.CadastroPageModule)
  },
  {
    path: 'solicitantes/:idSolicitacao/editar',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/cadastro/cadastro.module').then(m => m.CadastroPageModule)
  },
  {
    path: 'operacoes',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/solicitantes/solicitantes.module').then(m => m.SolicitantesPageModule)
  },
  {
    path: 'solicitantes',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/operacoes/operacoes.module').then(m => m.OperacoesPageModule)
  },
  {
    path: 'requisicoes',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/requisicoes/requisicoes.module').then(m => m.RequisicoesPageModule)
  },
  {
    path: 'requisicoes/solicitacao-pj/:id',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/solicitacao-pj/solicitacao-pj.module').then(m => m.SolicitacaoPJPageModule)
  },
  {
    path: 'solicitantes/operacao-credito/:idSolicitacao',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/operacao-credito/operacao-credito.module').then(m => m.OperacaoCreditoPageModule)
  },
  {
    path: 'solicitantes/form-operacao-credito/:idSolicitacao',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/form-operacao-credito/form-operacao-credito.module').then(m => m.FormOperacaoCreditoPageModule)
  },
  {
    path: 'solicitantes/form-operacao-credito/:idSolicitacao/:editar',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/form-operacao-credito/form-operacao-credito.module').then(m => m.FormOperacaoCreditoPageModule)
  },
  {
    path: 'solicitantes/resumo-operacao/:idSolicitacao',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/resumo-operacao/resumo-operacao.module').then(m => m.ResumoOperacaoPageModule)
  },
  {
    path: 'solicitantes/resumo-operacao/:idSolicitacao/:idRequisicao',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/resumo-operacao/resumo-operacao.module').then(m => m.ResumoOperacaoPageModule)
  },
  {
    path: 'solicitantes/solicitacao-aprovacao-raro',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/solicitacao-aprovacao-raro/solicitacao-aprovacao-raro.module').then(m => m.SolicitacaoAprovacaoRaroPageModule)
  },
  {
    path: 'solicitantes/solicitacao-aprovacao-raro/:idSolicitacao',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/solicitacao-aprovacao-raro/solicitacao-aprovacao-raro.module').then(m => m.SolicitacaoAprovacaoRaroPageModule)
  },
  {
    path: 'solicitantes/solicitacao-aprovacao-raro/:idSolicitacao/:idRequisicao',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/solicitacao-aprovacao-raro/solicitacao-aprovacao-raro.module').then(m => m.SolicitacaoAprovacaoRaroPageModule)
  },
  {
    path: 'painel',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/painel/painel.module').then(m => m.PainelPageModule)
  },
  {
    path: 'me-ajude',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/me-ajude/me-ajude.module').then(m => m.MeAjudePageModule)
  },
  {
    path: 'me-ajude/glossario/:idSessao',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/glossario/glossario.module').then(m => m.GlossarioPageModule)
  },
  {
    path: 'solicitantes/tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'solicitantes/tabs/solicitante/:idSolicitacao',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'solicitacoes-parceiro',
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./pages/solicitacoes-parceiro/solicitacoes-parceiro.module').then(m => m.SolicitacoesParceiroPageModule)
  },
  { path: '**', redirectTo: 'painel' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}

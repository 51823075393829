import { Injectable } from '@angular/core';
import { resolve } from '../util/resolve';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArmazenagemService {
  constructor(
    private http: HttpClient
  ) {}

  buscarTipoArmazenamento() {
    const url = resolve('refactory://tipoArmazenamento');
    return this.http.get(url).pipe(
      map((result: any) => result.items)
    );
  }

  registrarArmazenagem(solicitacao: SolicitacaoArmazenagem): Observable<any> {
    const url = resolve('refactory://solicitacaoArmazenagem');
    return this.http.post<any>(url, solicitacao);
  }

  buscarSolicitacaoArmazenagem(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoArmazenagem/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }
}

export interface SolicitacaoArmazenagem {
  solicitacao?: number;
  percentualSocietarioSolicitante?: number;
  nomeCondominio?: string;
  outrosParticipantes?: [
    {
      cpf?: string,
      nome?: string,
      cnpj?: string,
      razaoSocial?: string,
      percentualSocietario?: number
    }
  ];
}

export interface TipoArmazenagem {
  id?: number;
  nome?: string;
}

export enum TIPO_ARMAZENAGEM {
  nao_armazeno = 4
}

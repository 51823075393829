import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalVisualizarVideoComponent } from './modal-visualizar-video.component';
import { FormatacaoModule } from '../../components/formatacao/formatacao.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FormatacaoModule,
  ],
  exports: [
    ModalVisualizarVideoComponent
  ],
  declarations: [ModalVisualizarVideoComponent],
  entryComponents: [ModalVisualizarVideoComponent],
})
export class ModalVisualizarVideoModule {
}

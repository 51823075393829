export enum TipoDocumentoEnum {
  CnhRgCpf = 1,
  ComprovanteResidencia,
  DeclaracaoIrpfAtualizada,
  ReciboIrpf,
  CertidaoCasamentoOuNascimento,
  OutrosDocumentosPessoais,
  ApresentacaoInstitucional,
  OrganogramaSocietario,
  QuadroSafraPecuario,
  RelacaoPrincipaisClientes,
  RelacaoPrincipaisFornecedores,
  OutrosDocumentosAtividade,
  MatriculaPropriedade,
  AvaliacaoPropriedade,
  KmzPropriedade,
  ContratoArrendamento,
  OutrosDocumentosPropriedadesEOutrosBens,
  Endividamento,
  ContratosRecebiveis,
  OutrosDocumentosFinanceiro,
  FotosPropriedade,
  FotosProducaoAtividade,
  FotosPerfilSolicitante,
  OutrosDocumentosImagens,
  MandatoTerraLogs,
  OutrosDocumentosOutros,
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ReprovarRaroComponent } from './reprovar-raro.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
  ],
  exports: [
    ReprovarRaroComponent
  ],
  declarations: [ReprovarRaroComponent]
})
export class ReprovarRaroComponentModule {
}

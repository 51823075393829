import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-estado-vazio',
  templateUrl: './estado-vazio.component.html',
  styleUrls: ['./estado-vazio.component.scss'],
})
export class EstadoVazioComponent implements OnInit {

  @Input() imagem = 'assets/imgs/lista-vazia.svg';
  @Input() titulo: string;
  @Input() mensagem: string;

  constructor() {
  }

  ngOnInit() {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { showToastMessage, validarEmail } from '../../util/utils';
import { TipoAlteracaoPessoalEnum } from '../../enums/tipo-alteracao-pessoal.enum';

@Component({
  selector: 'app-solicitar-alteracao-pessoal',
  templateUrl: './solicitar-alteracao-pessoal.component.html',
  styleUrls: [ './solicitar-alteracao-pessoal.component.scss' ],
})

export class SolicitarAlteracaoPessoalComponent implements OnInit {

  @Input() perfilAdm = false;

  tiposAlteracao: any = [];

  customPopoverOptions: any = {
    subHeader: 'Selecione uma opção',
  };
  form: FormGroup;
  quantidadeCaracteres = 0;
  tipo: number;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private modalController: ModalController) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      motivo: new FormControl('', Validators.required),
      tipoAlteracao: new FormControl(''),
      valorAlterado: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      telefone: new FormControl(''),
    });
    this.buscarTiposAlteracao();
  }

  buscarTiposAlteracao() {
    this.tiposAlteracao = [
      {
        id: TipoAlteracaoPessoalEnum.Email,
        label: 'Alterar e-mail'
      },
      {
        id: TipoAlteracaoPessoalEnum.Senha,
        label: 'Alterar senha'
      },
      {
        id: TipoAlteracaoPessoalEnum.Telefone,
        label: 'Alterar telefone pessoal'
      }
    ];
  }

  calcularQuantidade(campo: any) {
    this.quantidadeCaracteres = campo.value.length || 0;
  }

  alterarTipoAlteracao(evento: any) {
    this.tipo = +evento.detail.value;
    this.setValidators(this.tipo);
  }

  async fechar() {
    await this.modalController.dismiss();
  }

  async enviar() {
    if (!this.form.valid) {
      return await showToastMessage('Preencha corretamente o formulário', 'danger');
    }
    let campoPreenchido;
    if (this.tipo === 1) {
      campoPreenchido = this.form.get('email').value;
      if (!validarEmail(campoPreenchido)) {
        return showToastMessage('E-mail inválido.', 'danger');
      }
      this.form.get('valorAlterado').setValue(campoPreenchido);
    } else if (this.tipo === 2) {
      campoPreenchido = this.form.get('senha').value;
      this.form.get('valorAlterado').setValue(campoPreenchido);
    } else if (this.tipo === 3) {
      campoPreenchido = this.form.get('telefone').value;
      this.form.get('valorAlterado').setValue(campoPreenchido);
    }
    // await showToastMessage('Solicitação enviada com sucesso.', 'success');
    await this.modalController.dismiss(this.form.value);
  }

  setValidators(tipo) {
    let campo;
    if (tipo === 1) {
      campo = this.form.get('email');
      this.form.get('senha').setValue(null);
      this.form.get('telefone').setValue(null);
      this.form.get('senha').clearValidators();
      this.form.get('telefone').clearValidators();
      campo.setValidators([ Validators.required, Validators.email ]);
    } else if (tipo === 2) {
      this.form.get('email').setValue(null);
      this.form.get('telefone').setValue(null);
      this.form.get('email').clearValidators();
      this.form.get('telefone').clearValidators();
      campo = this.form.get('senha');
      campo.setValidators(Validators.required);
    } else if (tipo === 3) {
      this.form.get('email').setValue(null);
      this.form.get('senha').setValue(null);
      this.form.get('email').clearValidators();
      this.form.get('senha').clearValidators();
      campo = this.form.get('telefone');
      campo.setValidators([ Validators.required, Validators.maxLength(16) ]);
    }
    campo.updateValueAndValidity();
  }
}

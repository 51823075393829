import { Pipe, PipeTransform } from '@angular/core';
import { FORMATADORES } from '@zellotec/terralogs_regras/formatacao';
import { lerDinheiro } from '@zellotec/terralogs_regras/util';

@Pipe({
  name: 'dinheiroSemCentavos',
})
export class DinheiroSemCentavosPipe implements PipeTransform {
  private formatador = FORMATADORES.dinheiro({centavos: false});

  transform(value: string | number, semCifrao = false) {
    if (semCifrao) {
      const valor: any = this.formatador(lerDinheiro(value)) ?? value;
      return valor.replaceAll('R$', '').trim();
    }
    return this.formatador(lerDinheiro(value)) ?? value;

  }
}

export enum SituacaoSolicitacaoEnum {
  TodasOperacoes = 0,
  SolicitacaoIniciada = 1,
  EmPreenchimentoDoRaro,
  AguardandoAprovacaoDoRaro,
  RaroAprovado,
  PendenteDeCorrecao,
  PendenteDeDocumentacao,
  CancelamentoSolicitado,
  OperacaoCancelada,
  CreditoEmAnalise,
  PendenteDeDocumentacaoDeCredito,
  CreditoAprovado,
  CreditoReprovado
}

import { Component, Input, OnInit } from '@angular/core';
import { showToastMessage } from '../../../util/utils';
import { ModalController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Usuario } from '@zellotec/terralogs_regras/models';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import { loading } from '../../../util/loading';
import { SELOS_CERTIFICADOS, SelosCertificadosService } from '../../../services/selos-certificados.service';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-selos-certificacoes',
  templateUrl: './selos-certificacoes.component.html',
  styleUrls: [ './selos-certificacoes.component.scss' ],
})
export class SelosCertificacoesComponent implements OnInit {

  @Input() item: any;
  @Input() idSolicitacao: number;
  @Input() usuarioLogado: Usuario;
  opcaoOutras = false;
  selosCertificacoes: any[] = [];

  idUsuarioEditar: number;
  form: FormGroup;
  submitted: boolean;

  constructor(
    private modalCtrl: ModalController,
    private selosCertificadosService: SelosCertificadosService
  ) {
    this.form = new FormGroup({
      selosCertificacoesOutroCampo: new FormControl(''),
      outrosSelosCertificacoes: new FormArray([])
    });
  }

  ngOnInit() {
    this.idUsuarioEditar = this.item?.id;
    this.form.reset(this.item?.campos);

    loading(
      this.selosCertificadosService.buscarSelosCertificados()
        .pipe(
          mergeMap((selosCertificacoes) => {
            this.selosCertificacoes = selosCertificacoes;
            this.selosCertificacoes = this.selosCertificacoes.map((selosCertificado) => {
              selosCertificado.checked = false;
              return selosCertificado;
            });

            let obs = of(null);
            if (this.idSolicitacao) {
              obs = this.selosCertificadosService.buscarSolicitacoesSelosCertificacoes(this.idSolicitacao);
            }
            return obs;
          })
        )
        .subscribe((solicitacaoSelosCertificacoes) => {
          if (solicitacaoSelosCertificacoes) {
            solicitacaoSelosCertificacoes.result.forEach((selos) => {
              const buscar = this.buscarNoArrayPorId('selosCertificacoes', selos.certificacao);
              buscar.selecionado.checked = true;
              this.selosCertificacoes[buscar.index] = buscar.selecionado;

              if (selos?.certificacaoOutros.length > 0) {

                selos.certificacaoOutros.forEach((outro) => {
                  const iniciativas = this.form.get('outrosSelosCertificacoes') as FormArray;
                  iniciativas.insert(this.form.get('outrosSelosCertificacoes').value.length, this.criarFormOutras(outro?.nome));
                });
              }
            });
            if (this.form.get('outrosSelosCertificacoes')['controls'].length > 0) {
              this.opcaoOutras = true;
            }
          }
        })
    );
  }


  async selecionarOpcao(evento: any) {
    const buscar = this.buscarNoArrayPorId('selosCertificacoes', evento.value);
    const outroSelosCertificados = this.form.get('outrosSelosCertificacoes') as FormArray;
    // Não possui
    if (evento.id === SELOS_CERTIFICADOS.naoPossui && evento.checked) {
      const retornoModal = await this.confirmOpcaoModal();
      if (retornoModal) {
        this.opcaoOutras = false;
        if (outroSelosCertificados) {
          outroSelosCertificados.clear();
        }
        return this.selosCertificacoes = this.selosCertificacoes.map((selosCertificado) => {
          if (selosCertificado.id !== SELOS_CERTIFICADOS.naoPossui) {
            selosCertificado.checked = false;
          }

          return selosCertificado;
        });
      } else {
        evento.checked = false;
      }
    } else if (evento.id !== SELOS_CERTIFICADOS.naoPossui && evento.checked) {
      // Retirar o não possui
      const buscarNaoPossui = this.buscarNoArrayPorId('selosCertificacoes', SELOS_CERTIFICADOS.naoPossui);
      buscarNaoPossui.selecionado.checked = false;
      this.selosCertificacoes[buscarNaoPossui.index] = buscarNaoPossui.selecionado;
      // this.form.get('selosCertificacoesOutroCampo').setValue('');
    }
    this.selosCertificacoes[buscar.index] = evento;
    // Outras opcoes
    this.opcaoOutras = !!this.selosCertificacoes.find((opcao) => opcao?.id === SELOS_CERTIFICADOS.outros)?.checked;
    if (!this.opcaoOutras) {
      if (outroSelosCertificados) {
        outroSelosCertificados.clear();
      }
      this.form.get('selosCertificacoesOutroCampo').setValue('');
    }
  }

  criarFormOutras(value?): FormGroup {
    return new FormGroup({
      nome: new FormControl(value)
    });
  }

  buscarNoArrayPorId(array, comparacao) {
    const selecionado = this[array].find((opcao) => opcao.id === comparacao);
    const index = this[array].indexOf(selecionado);
    return {
      index,
      selecionado
    };
  }

  async novoSeloCertificacaoOutro(item?) {
    if (item && !item.value) {
      await showToastMessage('É necessário informar o nome do selo e certificação.', 'danger');
      return;
    }
    const selosCertificados = this.form.get('outrosSelosCertificacoes') as FormArray;
    selosCertificados.insert(this.form.get('outrosSelosCertificacoes').value.length, this.criarFormOutras(item.value));
    this.form.get('selosCertificacoesOutroCampo').setValue('');
    setTimeout(() => {
      const id = document.getElementById('outro_' + (selosCertificados.length - 1));
      this.scrollToElement(id);
    });

  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  async delSelosCertificacoes(index: number) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Selo e Certificação!',
        mensagem: 'Tem certeza que deseja excluir este Selo e Certificação?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const selosCertificados = this.form.get('outrosSelosCertificacoes') as FormArray;
      selosCertificados.removeAt(index);
    }
  }

  async confirmOpcaoModal() {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Atenção_Confirmar',
        titulo: 'Selos e Certificações',
        mensagem: `Tem certeza que deseja selecionar a opção <b>Não Possui</b>? Ao confirmar você perderá todas as outras marcações.`,
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      return data;
    }
  }


  async salvar() {

    const selos = [];
    let nenhumMarcado = true;
    this.selosCertificacoes.forEach((selosCertificado) => {
      if (selosCertificado.checked) {
        selos.push(selosCertificado);
        nenhumMarcado = false;
      }
    });

    if (nenhumMarcado) {
      return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
    }

    const valorOutroCampo = this.form.get('selosCertificacoesOutroCampo').value;
    if ((this.opcaoOutras && this.form.get('outrosSelosCertificacoes')['controls'].length === 0) && !valorOutroCampo) {
      return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
    }

    // Outros
    const outrosNome = [];
    if (valorOutroCampo) {
      outrosNome.push(valorOutroCampo);
    }
    if (this.form.get('outrosSelosCertificacoes')['controls'] && this.form.get('outrosSelosCertificacoes')['controls'].length > 0) {
      this.form.get('outrosSelosCertificacoes')['controls'].forEach((outro) => {
        outrosNome.push(outro.value.nome);
      });
    }

    const obs = [ of(null) ];
    let obsOutro = of(null);
    selos.forEach((value) => {
      // 9 => outro
      if (value.id !== SELOS_CERTIFICADOS.outros) {
        obs.push(
          this.selosCertificadosService.salvarSolicitacaoSelosCertificacoes({
            certificacao: value.id,
            solicitacao: this.idSolicitacao
          })
        );
      } else {
        obsOutro = this.selosCertificadosService.salvarSolicitacaoSelosCertificacoes({
          certificacao: value.id,
          solicitacao: this.idSolicitacao
        });
      }
    });

    loading(
      this.selosCertificadosService.deletarSolicitacoesSelosCertificacoes(this.idSolicitacao)
        .pipe(
          mergeMap(() => {
            return forkJoin(obs);
          }),
          mergeMap(() => {
            return obsOutro;
          }),
          mergeMap((solicitacaoOutro) => {
            const outros = [ of(null) ];
            if (solicitacaoOutro) {
              outrosNome.forEach((outro) => {
                outros.push(
                  this.selosCertificadosService.salvarSolicitacaoSelosCertificacoesOutros({
                    solicitacaoCertificado: solicitacaoOutro?.id,
                    nome: outro
                  })
                );
              });
            }
            return forkJoin(outros);
          }),
        )
        .subscribe(async () => {
          await showToastMessage('Registro salvo com sucesso.', 'success');
          await this.modalCtrl.dismiss(true);
        }, async error => {
          await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
        })
    );


  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }
}

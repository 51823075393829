import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-visualizar-video',
  templateUrl: './modal-visualizar-video.component.html',
  styleUrls: ['./modal-visualizar-video.component.scss'],
})
export class ModalVisualizarVideoComponent{

  @Input() urlId;

  constructor(
    private modalController: ModalController,
  ) {
  }

  async fechar() {
    await this.modalController.dismiss();
  }

}

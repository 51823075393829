import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalSolicitacaoParceiroComponent } from './modal-solicitacao-parceiro.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
  ],
  exports: [
    ModalSolicitacaoParceiroComponent
  ],
  declarations: [ ModalSolicitacaoParceiroComponent ],
  entryComponents: [ ModalSolicitacaoParceiroComponent ],
})
export class ModalSolicitacaoParceiroComponentModule {
}

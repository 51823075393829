import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { showToastMessage } from '../../../util/utils';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import { FornecedorClienteServices } from '../../../services/fornecedor-cliente.service';
import { loading } from '../../../util/loading';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-fornecedores-clientes',
  templateUrl: './fornecedores-clientes.component.html',
  styleUrls: [ './fornecedores-clientes.component.scss' ],
})
export class FornecedoresClientesComponent implements OnInit {

  @Input() idSolicitacao: number;
  customPopoverOptions: any = {
    subHeader: 'Selecione uma ou mais opções',
  };
  listaFornecedoresClientes: any[] = [];
  badgesFornecedores: any[] = [];
  badgesClientes: any[] = [];
  fornecedorInput;
  clienteInput;
  formValido;
  inputFornecedores;
  inputClientes;

  constructor(private modalCtrl: ModalController,
              private fornecedoresClientesService: FornecedorClienteServices
  ) {
  }

  ngOnInit() {
    this.inputFornecedores = document.getElementsByClassName('searchbarComponent')[0];
    this.inputClientes = document.getElementsByClassName('searchbarComponent')[1];
    loading(
      this.fornecedoresClientesService.buscarFornecedoresClientes()
        .pipe(
          mergeMap((fornecedorCliente) => {
            this.listaFornecedoresClientes = fornecedorCliente;
            if (this.idSolicitacao) {
              return this.fornecedoresClientesService.buscarSolicitacaoFornecedoresClientes(this.idSolicitacao);
            }
            return of(null);
          })
        ).subscribe((solicitacaoFornecedorCliente) => {

        if (solicitacaoFornecedorCliente) {
          solicitacaoFornecedorCliente.result.forEach((solicitacao) => {


            let badge = {};

            if (solicitacao.clienteFornecedor) {
              badge = {
                nome: solicitacao.clienteFornecedor.nome,
                id: solicitacao.clienteFornecedor.id,
              };
            } else {
              badge = {
                nome: solicitacao.nome,
                id: solicitacao.id,
                outro: true
              };
            }

            if (solicitacao.tipoFornecedorCliente === 'F') {
              this.badgesFornecedores.push(badge);
            } else {
              this.badgesClientes.push(badge);
            }
          });
        }

      })
    );
  }

  selecionarAssociacao(badge: any, tipo: string) {
    // Outro - se não tiver id é um OUTRO
    if (!badge.id) {
      const idRandomico = this.gerarIdOutro(tipo === 'fornecedor' ? 'badgesFornecedores' : 'badgesClientes');
      badge = {
        nome: badge,
        id: idRandomico,
        outro: true
      };
    }
    if (tipo === 'fornecedor') {
      this.badgesFornecedores.push(badge);
      this.fornecedorInput = '';
      // GO HORSE pra limpar o campo
      this.limparInputFornecedores();
    } else {
      this.badgesClientes.push(badge);
      this.limparInputClientes();
    }
  }

  limparInputFornecedores() {
    const input = this.inputFornecedores.getElementsByClassName('searchbar-input sc-ion-searchbar-md');
    for (let t = 0; t < input.length; t ++) {
      setTimeout( () => {
        input[t].value = '';
      }, 100);
    }
  }

  limparInputClientes() {
    const input = this.inputClientes.getElementsByClassName('searchbar-input sc-ion-searchbar-md');
    for (let t = 0; t < input.length; t ++) {
      setTimeout( () => {
        input[t].value = '';
      }, 100);
    }
  }

  removerBadge(item: any, tipo: string) {
    if (tipo === 'fornecedor') {
      const badgeFornecedor = this.badgesFornecedores.find((badge) => badge.id === item.id);
      const index = this.badgesFornecedores.indexOf(badgeFornecedor);
      this.badgesFornecedores.splice(index, 1);
    } else {
      const badgeCliente = this.badgesClientes.find((badge) => badge.id === item.id);
      const index = this.badgesClientes.indexOf(badgeCliente);
      this.badgesClientes.splice(index, 1);
    }
  }

  gerarIdOutro(array) {
    const ids = this[array].map((value) => value.id);
    const numeroRandomico = this.randomizarId();
    if (ids.includes(numeroRandomico)) {
      return this.gerarIdOutro(array);
    }
    return numeroRandomico;
  }

  randomizarId() {
    return Math.floor(Math.random() * 999);
  }

  async excluirFornecedorCliente(tag, tipo) {
    const message = 'Tem certeza que deseja excluir este ' + (tipo === 'fornecedor' ? 'fornecedor' : 'cliente') + '?';
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: message
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.removerBadge(tag, tipo);
    }
  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }

  async confirmar() {
    this.formValido = true;

    if (this.badgesClientes.length === 0 && this.badgesFornecedores.length === 0) {
      this.formValido = false;
    }

    if (this.formValido) {

      const obsCliente = [];
      const obsFornecedor = [];
      this.badgesClientes.forEach((cliente) => {
        obsCliente.push(
          this.fornecedoresClientesService.salvarFornecedoresClientes({
            tipoFornecedorCliente: 'C',
            clienteFornecedor: cliente.outro ? null : cliente.id,
            solicitacao: this.idSolicitacao,
            nome: cliente.outro ? cliente.nome : null
          })
        );
      });

      this.badgesFornecedores.forEach((fornecedor) => {
        obsFornecedor.push(
          this.fornecedoresClientesService.salvarFornecedoresClientes({
            tipoFornecedorCliente: 'F',
            clienteFornecedor: fornecedor.outro ? null : fornecedor.id,
            solicitacao: this.idSolicitacao,
            nome: fornecedor.outro ? fornecedor.nome : null
          })
        );
      });

      loading(
        this.fornecedoresClientesService.deletarSolicitacaoFornecedoresClientes(this.idSolicitacao)
          .pipe(
            mergeMap(() => {
              return forkJoin([].concat(obsCliente, obsFornecedor));
            })
          )
          .subscribe(async () => {
            await showToastMessage('Registro salvo com sucesso.', 'success');
            await this.modalCtrl.dismiss(true);
          }, async error => {
            await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
          })
      );


    } else {
      await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
      return;
    }
  }
}

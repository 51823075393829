import { Component, Input, OnInit } from '@angular/core';
import { formatarQuantidade, parseDecimals, parseTextToDouble, showToastMessage } from '../../../util/utils';
import { ModalController, PopoverController } from '@ionic/angular';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import { DominioService } from 'src/app/services/dominio.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Estado, IdPerfilProdutor, Municipio } from '@zellotec/terralogs_regras/models';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { ATIVIDADE_MUNICIPIO, AtividadeMunicipioService } from '../../../services/atividade-municipio.service';
import { loading } from '../../../util/loading';
import { TIPO_ATIVIDADE } from '../../../services/atividade-quadro-safra.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-cultura-municipio',
  templateUrl: './cultura-municipio.component.html',
  styleUrls: [ './cultura-municipio.component.scss' ],
})
export class CulturaMunicipioComponent implements OnInit {

  @Input() idSolicitacao: number;
  @Input() perfilProdutor: number;

  form: FormGroup;
  forms: FormArray;
  itemEstado$ = new Observable<any>();

  estados: Estado[] = [];
  municipios: Municipio[] = [];

  atividadesMunicipio = [];
  titulosFormsDinamicos = [];
  totalFormDinamico = [];
  mostrarFormsDinamicos = [];
  itemFocus: string;
  formSelecionado = 0;
  modeloAtividadeMunicipio = [];
  disabledSelects = true;

  constructor(private modalCtrl: ModalController,
              public popoverCtrl: PopoverController,
              private atividadeMunicipioService: AtividadeMunicipioService,
              private dominioService: DominioService) {
    this.form = new FormGroup({
      forms: new FormArray([]),
    });
    this.forms = this.form.get('forms') as FormArray;
  }

  ionViewDidLeave() {
    this.itemEstado$ = of([]);
  }

  async ngOnInit() {
    this.buscarUf();
    await loading(
      this.atividadeMunicipioService.buscarAtividadesPorPerfilProdutor()
        .pipe(
          mergeMap((atividades) => {
            this.modeloAtividadeMunicipio = atividades.map((atividade) => {
              atividade.checked = false;
              return atividade;
            }).filter((atividade) => {
              if (this.perfilProdutor === IdPerfilProdutor.Agricultor) {
                return atividade.id !== TIPO_ATIVIDADE.pecuaria_bovina;
              } else if (this.perfilProdutor === IdPerfilProdutor.Pecuarista) {
                return atividade.id === TIPO_ATIVIDADE.pecuaria_bovina || atividade.id === TIPO_ATIVIDADE.outra;
              } else {
                return atividade;
              }
            });
            return this.atividadeMunicipioService.buscarSolicitacoesAtividadeMunicipio(this.idSolicitacao);
          })
        ).subscribe(async (solicitacao: any) => {
        if (solicitacao.result.length > 0) {
          this.disabledSelects = false;
          for await (const atividadeMunicipioObj of solicitacao.result) {
            const index = solicitacao.result.indexOf(atividadeMunicipioObj);
            this.formSelecionado = index;
            await this.adicionarNovoMunicipio();

            this.itemEstado$ = this.forms.get(index.toString()).get('idEstado').valueChanges;
            this.itemEstado$.subscribe(id => {
              if (!id) {
                return;
              }

              this.dominioService.buscarMunicipios(id).pipe(
                distinctUntilChanged(),
              ).subscribe(res => {
                this.municipios = res;
              });
            });

            this.forms.get(index.toString()).get('estado').setValue(atividadeMunicipioObj.estado.nome);
            this.forms.get(index.toString()).get('idEstado').setValue(atividadeMunicipioObj.estado.id);
            this.forms.get(index.toString()).get('municipio').setValue(atividadeMunicipioObj.municipio.nome);
            this.forms.get(index.toString()).get('idMunicipio').setValue(atividadeMunicipioObj.municipio.id);

            if (atividadeMunicipioObj.solicitacaoAtividadeMunicipioCultura.length > 0) {
              for await (const atividadeCultura of atividadeMunicipioObj.solicitacaoAtividadeMunicipioCultura) {
                let outros = false;
                if (atividadeCultura.tipoAtividade.id === ATIVIDADE_MUNICIPIO.outras) {
                  outros = true;
                  this.mostrarFormsDinamicos[index][atividadeCultura.tipoAtividade.id] = true;
                  atividadeCultura.tipoAtividade.id = atividadeCultura.solicitacaoAtividadeMunicipio;
                  const outroCampo = this.forms.get(this.formSelecionado.toString()).get('formDinamico').get('campo_' +
                    atividadeCultura.tipoAtividade.id + '_nome');
                  if (!outroCampo) {
                    this.atividadesMunicipio[this.formSelecionado].push({
                      id: atividadeCultura.tipoAtividade.id,
                      nome: atividadeCultura.outraAtividade,
                      checked: true,
                      hidden: true
                    });
                    await this.estruturaFormDinamico({
                      id: atividadeCultura.tipoAtividade.id,
                      nome: atividadeCultura.outraAtividade
                    });
                  }
                }
                await this.novaAtividade(
                  { value: atividadeCultura?.nome },
                  { value: parseDecimals(atividadeCultura?.area) },
                  { value: atividadeCultura?.arrendada },
                  'solicitacao', atividadeCultura.tipoAtividade.id
                );
                this.mostrarFormsDinamicos[index][atividadeCultura.tipoAtividade.id] = true;
                this.atividadesMunicipio[this.formSelecionado] = this.atividadesMunicipio[this.formSelecionado].map((atv) => {
                  if (atv.id === atividadeCultura.tipoAtividade.id || (outros && atv.id === ATIVIDADE_MUNICIPIO.outras)) {
                    atv.checked = true;
                  }
                  return atv;
                });
              }
            }
          }
        } else {
          this.adicionarNovoMunicipio();
        }
        this.formSelecionado = 0;
      })
    );
  }

  modeloMatrixAtiviadeMunicipio() {
    const modeloReferencia = JSON.stringify(this.modeloAtividadeMunicipio);
    this.atividadesMunicipio[this.formSelecionado] = JSON.parse(modeloReferencia);
  }

  criarFormMunicipio(): FormGroup {
    return new FormGroup({
      estado: new FormControl(null),
      idEstado: new FormControl(null),
      municipio: new FormControl(null),
      idMunicipio: new FormControl(null),
      outraAtividade: new FormControl(null),
      formDinamico: new FormGroup({})
    });
  }

  criarFormAtividade(nome, area, propriedadeRural): FormGroup {
    return new FormGroup({
      nome: new FormControl(nome),
      area: new FormControl(area),
      propriedadeRural: new FormControl(propriedadeRural)
    });
  }

  verificarIdOutroDinamico(id) {
    let retorno = true;
    for (const atividade of this.modeloAtividadeMunicipio) {
      if (atividade.id === id) {
        retorno = false;
      }
    }
    return retorno;
  }

  buscarUf() {
    this.dominioService.buscarEstados().subscribe((result) => {
      this.estados = result;
    });
  }

  async adicionarOutra() {
    const idDinamico = this.gerarIdOutro(this.atividadesMunicipio[this.formSelecionado]);
    const nomeDinamico = this.forms.get(this.formSelecionado.toString()).get('outraAtividade').value;
    await this.estruturaFormDinamico({ id: idDinamico, nome: nomeDinamico });
    this.mostrarFormsDinamicos[this.formSelecionado][idDinamico] = true;
    this.atividadesMunicipio[this.formSelecionado].push({
      id: idDinamico,
      nome: nomeDinamico,
      checked: true,
      hidden: true
    });
    this.forms.get(this.formSelecionado.toString()).get('outraAtividade').setValue('');
  }

  adicionarFormDinamico() {
    this.atividadesMunicipio[this.formSelecionado].forEach(async (atividade) => {
      // Outro
      if (atividade.id !== ATIVIDADE_MUNICIPIO.outras) {
        await this.estruturaFormDinamico(atividade);
      }
    });
  }

  async removerAtividade(id) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Atividade!',
        mensagem: 'Tem certeza que deseja excluir esta atividade?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.deletarOutroPorId(id);
    }
  }

  async deletarOutroPorId(id) {
    await this.form.get('forms')['controls'][this.formSelecionado].controls.formDinamico.removeControl(id);
    await this.form.get('forms')['controls'][this.formSelecionado].controls.formDinamico.removeControl('campo_' + id.toString() + '_nome');
    await this.form.get('forms')['controls'][this.formSelecionado].controls.formDinamico.removeControl('campo_' + id.toString() + '_area');
    await this.form.get('forms')['controls'][this.formSelecionado].controls.formDinamico.removeControl('campo_' + id.toString() + '_propriedadeRural');

    delete this.titulosFormsDinamicos[this.formSelecionado][id];
    delete this.mostrarFormsDinamicos[this.formSelecionado][id];
    const valor = this.atividadesMunicipio[this.formSelecionado].find((atv) => atv.id === id);
    const index = this.atividadesMunicipio[this.formSelecionado].indexOf(valor);
    if (index !== -1) {
      this.atividadesMunicipio[this.formSelecionado].splice(index, 1);
    }
    const indexTotal = this.totalFormDinamico[this.formSelecionado].indexOf(id);
    if (indexTotal !== -1) {
      this.totalFormDinamico[this.formSelecionado].splice(indexTotal, 1);
    }
  }

  async estruturaFormDinamico(atividade) {
    await this.form.get('forms')['controls'][this.formSelecionado].controls.formDinamico.addControl(atividade.id, new FormArray([]));
    await this.form.get('forms')['controls'][this.formSelecionado].controls.formDinamico.addControl('campo_' + atividade.id.toString() + '_nome', new FormControl(''));
    await this.form.get('forms')['controls'][this.formSelecionado].controls.formDinamico.addControl('campo_' + atividade.id.toString() + '_area', new FormControl('', Validators.required));
    await this.form.get('forms')['controls'][this.formSelecionado].controls.formDinamico.addControl('campo_' + atividade.id.toString() + '_propriedadeRural', new FormControl(false));

    this.titulosFormsDinamicos[this.formSelecionado] = (this.titulosFormsDinamicos[this.formSelecionado]
      ? this.titulosFormsDinamicos[this.formSelecionado] : []);
    this.titulosFormsDinamicos[this.formSelecionado][atividade.id] = atividade.nome;

    this.mostrarFormsDinamicos[this.formSelecionado] = (this.mostrarFormsDinamicos[this.formSelecionado]
      ? this.mostrarFormsDinamicos[this.formSelecionado] : []);
    this.mostrarFormsDinamicos[this.formSelecionado][atividade.id] = false;

    this.totalFormDinamico[this.formSelecionado] =
      [ ...(this.totalFormDinamico[this.formSelecionado] ?
        this.totalFormDinamico[this.formSelecionado] : []) ].concat([ atividade.id ]);
  }

  gerarIdOutro(array) {
    const ids = array.map((value) => value.id);
    const numeroRandomico = this.randomizarId();
    if (ids.includes(numeroRandomico)) {
      return this.gerarIdOutro(array);
    }
    return numeroRandomico;
  }

  randomizarId() {
    return Math.floor(Math.random() * 999);
  }

  async novaAtividade(nome, area, propriedadeRural, tipo, formName?) {
    // if ((nome && !nome.value) || (!area)) {
    //   await showToastMessage('É necessário informar o valor do campo.', 'danger');
    //   return;
    // }
    const formArrayDinamico = this.forms.get(this.formSelecionado.toString()).get('formDinamico').get(formName.toString()) as FormArray;
    formArrayDinamico.insert(
      this.forms.get(this.formSelecionado.toString()).get('formDinamico')
        .get(formName.toString()).value.length, this.criarFormAtividade(nome.value, area.value, propriedadeRural.value)
    );
    this.forms.get(this.formSelecionado.toString()).get('formDinamico').get('campo_' + formName + '_nome').setValue('');
    this.forms.get(this.formSelecionado.toString()).get('formDinamico').get('campo_' + formName + '_area').setValue('');
    this.forms.get(this.formSelecionado.toString()).get('formDinamico').get('campo_' + formName + '_propriedadeRural').setValue(false);
    if (tipo !== 'solicitacao') {
      setTimeout(() => {
        const id = document.getElementById(
          'outro_dinamico_' + this.formSelecionado.toString() + '_' + formName + '_' + (formArrayDinamico.length - 1));
        this.scrollToElement(id);
      });
    }
  }

  async delSeguradora(index: number, tipo, formName?) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Atividade!',
        mensagem: 'Tem certeza que deseja excluir esta atividade?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const formArrayDinamico = this.forms.get(this.formSelecionado.toString()).get('formDinamico').get(formName.toString()) as FormArray;
      formArrayDinamico.removeAt(index);
    }
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  qtdFormatada(itemForm) {
    const qtd = formatarQuantidade(itemForm.value);
    setTimeout(() => {
      itemForm.value = qtd;
    }, 100);
  }

  onFocusItem(tipo) {
    this.itemFocus = tipo;
  }

  limparFocus() {
    this.itemFocus = null;
  }

  delMunicipio(indexArr) {
    const formsMunicipio = this.forms as FormArray;
    this.formSelecionado = (this.forms.length - 2);
    formsMunicipio.removeAt(indexArr);
  }

  selectMunicipioAba(evt) {
    this.formSelecionado = evt.index;
  }

  async selecionarOpcao(evento) {
    this.mostrarFormsDinamicos[this.formSelecionado][evento.id] = evento.checked;
    if (!evento.checked && evento.id !== ATIVIDADE_MUNICIPIO.outras) {
      const formArrayDinamico = this.forms.get(this.formSelecionado.toString()).get('formDinamico').get(evento.id.toString()) as FormArray;
      formArrayDinamico.clear();
    } else if (!evento.checked && evento.id === ATIVIDADE_MUNICIPIO.outras) {
      const keysSelecionado = Object.keys(this.mostrarFormsDinamicos[this.formSelecionado]);
      const keysModelo = this.modeloAtividadeMunicipio.map((atividadeMinicipio) => atividadeMinicipio.id.toString());
      const diferencaArray = keysSelecionado.filter(x => keysModelo.indexOf(x) === -1);
      diferencaArray.forEach((diferenca) => {
        this.deletarOutroPorId(+diferenca);
      });
    }
  }

  async addMunicipio() {
    if ((!this.forms.get(this.formSelecionado.toString()).get('estado').value || !this.forms.get(this.formSelecionado.toString()).get('municipio').value)) {
      await showToastMessage('É necessário informar a UF e o munícipio.', 'danger');
      return;
    }

    this.adicionarNovoMunicipio();
  }

  async adicionarNovoMunicipio() {
    const formsMunicipio = this.forms as FormArray;
    formsMunicipio.insert(
      this.forms.controls.length, this.criarFormMunicipio()
    );

    this.formSelecionado = (this.forms.length - 1);
    await this.modeloMatrixAtiviadeMunicipio();
    await this.adicionarFormDinamico();
  }

  selectUF(data) {
    if (data) {
      this.dominioService.buscarMunicipios(data.id).pipe(
        distinctUntilChanged(),
      ).subscribe(res => {
        this.municipios = res;
      });
    } else {
      this.municipios = [];
    }
    this.forms.get(this.formSelecionado.toString()).get('estado').setValue(data.nome);
    this.forms.get(this.formSelecionado.toString()).get('idEstado').setValue(data.id);
    this.forms.get(this.formSelecionado.toString()).get('municipio').setValue(null);
    this.forms.get(this.formSelecionado.toString()).get('idMunicipio').setValue(null);
  }

  limparMunicipios() {
    this.municipios = [];
    this.forms.get(this.formSelecionado.toString()).get('estado').setValue(null);
    this.forms.get(this.formSelecionado.toString()).get('idEstado').setValue(null);
    this.forms.get(this.formSelecionado.toString()).get('municipio').setValue(null);
    this.forms.get(this.formSelecionado.toString()).get('idMunicipio').setValue(null);
  }

  selectMunicipio(municipio) {
    this.forms.get(this.formSelecionado.toString()).get('municipio').setValue(municipio.nome);
    this.forms.get(this.formSelecionado.toString()).get('idMunicipio').setValue(municipio.id);
    this.disabledSelects = false;
  }

  async salvar() {
    const obj = {
      solicitacao: this.idSolicitacao,
      municipios: []
    };

    for (const abaAtividade of this.atividadesMunicipio) {
      const index = this.atividadesMunicipio.indexOf(abaAtividade);
      const municipio = this.forms.get(index.toString()).get('idMunicipio').value;
      const estado = this.forms.get(index.toString()).get('idEstado').value;
      const atividadeSelecionada = !!abaAtividade.find(x => x.checked);
      if (!municipio || !estado || !atividadeSelecionada) {
        return await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
      }
      const dadosMunicipio = {
        municipio,
        estado,
        atividades: []
      };

      for (const atividade of abaAtividade) {
        if ((atividade.checked && atividade.id !== ATIVIDADE_MUNICIPIO.outras) ||
          (atividade.checked && this.verificarIdOutroDinamico(atividade.id))) {
          const formArrayDinamico = this.forms.get(index.toString()).get('formDinamico').get(atividade.id.toString()) as FormArray;
          if (formArrayDinamico.controls.length > 0) {
            // Pegar valores dos campos
            const nomeForm = this.forms.get(index.toString()).get('formDinamico').get(`campo_${atividade.id.toString()}_nome`).value;
            const areaForm = this.forms.get(index.toString()).get('formDinamico').get(`campo_${atividade.id.toString()}_area`).value;
            const propriedadeRural = this.forms.get(index.toString()).get('formDinamico').get(`campo_${atividade.id.toString()}_propriedadeRural`).value;
            if (nomeForm && areaForm) {
              dadosMunicipio.atividades.push(
                {
                  tipoAtividade: this.verificarIdOutroDinamico(atividade.id) ? ATIVIDADE_MUNICIPIO.outras : atividade.id,
                  nomeFazenda: nomeForm,
                  area: areaForm ? parseTextToDouble(areaForm) : null,
                  arrendada: propriedadeRural,
                  outraAtividade: this.verificarIdOutroDinamico(atividade.id) ? this.titulosFormsDinamicos[index][atividade.id] : null
                }
              );
            }
            // Pegar valores dos array
            for (const control of formArrayDinamico.controls) {
              if (control.get('area').value) {
                dadosMunicipio.atividades.push(
                  {
                    tipoAtividade: this.verificarIdOutroDinamico(atividade.id) ? ATIVIDADE_MUNICIPIO.outras : atividade.id,
                    nomeFazenda: control.get('nome').value,
                    area: control.get('area').value ? parseTextToDouble(control.get('area').value) : null,
                    arrendada: control.get('propriedadeRural').value,
                    outraAtividade: this.verificarIdOutroDinamico(atividade.id) ? this.titulosFormsDinamicos[index][atividade.id] : null
                  }
                );
              }
            }
          } else {
            const nomeForm = this.forms.get(index.toString()).get('formDinamico').get(`campo_${atividade.id.toString()}_nome`).value;
            const areaForm = this.forms.get(index.toString()).get('formDinamico').get(`campo_${atividade.id.toString()}_area`).value;
            const propriedadeRural = this.forms.get(index.toString()).get('formDinamico').get(`campo_${atividade.id.toString()}_propriedadeRural`).value;
            if (areaForm) {
              dadosMunicipio.atividades.push(
                {
                  tipoAtividade: this.verificarIdOutroDinamico(atividade.id) ? ATIVIDADE_MUNICIPIO.outras : atividade.id,
                  nomeFazenda: nomeForm,
                  area: areaForm ? parseTextToDouble(areaForm) : null,
                  arrendada: propriedadeRural,
                  outraAtividade: this.verificarIdOutroDinamico(atividade.id) ? this.titulosFormsDinamicos[index][atividade.id] : null
                }
              );
            }
          }
        }
      }
      obj.municipios.push(dadosMunicipio);
    }
    loading(
      this.atividadeMunicipioService.salvarSolicitacaoAtividadeMunicipio(
        obj
      ).subscribe(async () => {
        await showToastMessage('Registro salvo com sucesso.', 'success');
        await this.modalCtrl.dismiss(true);
      }, async (error) => {
        await showToastMessage(error.message || 'Erro no serviço, tente denovo em outro momento!', 'danger');
      })
    );

  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }
}

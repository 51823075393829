import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { resolve } from 'src/app/util/resolve';
import { Estado } from '@zellotec/terralogs_regras/models';

@Injectable({
  providedIn: 'root'
})
export class AtividadeMunicipioService {
  constructor(
    private http: HttpClient
  ) {}


  buscarAtividadesPorPerfilProdutor() {
    const url = resolve('refactory://tipoAtividadePerfilProdutor',  3 );
    return this.http.get(url).pipe(
      map((result: any) => result.items)
    );
  }

  salvarSolicitacaoAtividadeMunicipio(solicitacao: SolicitacaoAtividadeMunicipio) {
    const url = resolve('refactory://solicitacaoAtividadeMunicipio');
    return this.http.post(url, solicitacao);
  }

  buscarSolicitacoesAtividadeMunicipio(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoAtividadeMunicipio/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }

}

export interface SolicitacaoAtividadeMunicipio {
  solicitacao?: number;
  municipios?: any[];
}

export enum ATIVIDADE_MUNICIPIO {
  outras = 11
}


import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PerfilEnum } from '../enums/perfil.enum';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private itens: Menu[] = [
    /** Admin */
    { titulo: 'Painel Gerencial', rota: '/painel', icon: 'painel-gerencial', role: PerfilEnum.Admin },
    { titulo: 'Operações', rota: '/solicitantes', icon: 'clientes', role: PerfilEnum.Admin },
    { titulo: 'Requisições', rota: '/requisicoes', icon: 'check', role: PerfilEnum.Admin },
    { titulo: 'Usuários', rota: '/usuarios', icon: 'usuarios', role: PerfilEnum.Admin },
    { titulo: 'Solicitações Parceiros', rota: '/solicitacoes-parceiro', icon: 'arquivados', role: PerfilEnum.Admin },

    /** Atendente */
    { titulo: 'Meu Painel', rota: '/painel', icon: 'home', role: PerfilEnum.Atendente },
    { titulo: 'Minhas Operações', rota: '/solicitantes', icon: 'clientes', role: PerfilEnum.Atendente },
    { titulo: 'Solicitações Parceiros', rota: '/solicitacoes-parceiro', icon: 'arquivados', role: PerfilEnum.Atendente },
    { titulo: 'Me Ajude', rota: '/me-ajude', icon: 'exclamation', role: PerfilEnum.Atendente },

    /** Parceiro */
    { titulo: 'Meu Painel', rota: '/painel', icon: 'home', role: PerfilEnum.Parceiro },
    { titulo: 'Minhas Operações', rota: '/solicitantes', icon: 'clientes', role: PerfilEnum.Parceiro },
    { titulo: 'Minhas Solicitações', rota: '/solicitacoes-parceiro', icon: 'arquivados', role: PerfilEnum.Parceiro },
    { titulo: 'Me Ajude', rota: '/me-ajude', icon: 'exclamation', role: PerfilEnum.Parceiro },
  ];
  private itens$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(this.itens);

  constructor() {
  }

  buscarItens() {
    return this.itens$.asObservable();
  }

  filtrarPorPerfil(idPerfil) {
    const itens = this.itens.filter(item => item.role === idPerfil);
    this.itens$.next(itens);
  }
}

export interface Menu {
  titulo: string;
  rota: string;
  icon: string;
  role: number;
}

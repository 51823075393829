import { PopoverController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select-novo.component.html',
  styleUrls: [ './select-novo.component.scss' ],
})
export class SelectNovoComponent implements OnInit {

  @Input() titulo = 'Selecione uma opção';
  @Input() lista: any[] = [];

  checkboxList: any[] = [];
  nenhumSelecionado = true;

  constructor(public popoverCtrl: PopoverController) {
  }

  ngOnInit() {
  }

  check(item) {
    item.checked = !item.checked;
    this.nenhumSelecionado = !this.lista.find(x => x.checked);
  }

  confirmar() {
    const selecionados = this.lista.filter(x => x.checked).map(x => x.id);
    this.popoverCtrl.dismiss(selecionados);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { showToastMessage } from '../../util/utils';
import { RequisicaoService } from '../../services/requisicao.service';
import { TipoAlteracaoPessoalEnum } from '../../enums/tipo-alteracao-pessoal.enum';

@Component({
  selector: 'app-alterar-dado-pessoal',
  templateUrl: './alterar-dado-pessoal.component.html',
  styleUrls: [ './alterar-dado-pessoal.component.scss' ],
})
export class AlterarDadoPessoalComponent implements OnInit {

  @Input() id;

  form: FormGroup;
  quantidadeCaracteres = 0;
  negar = false;
  requisicao;
  labelAlteracao;
  valorAlteracao;
  isLoading = true;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private requisicaoService: RequisicaoService) {
  }

  ionViewDidEnter() {
    this.getRequisicao();
  }

  ngOnInit() {
    this.form = new FormGroup({
      motivo: new FormControl(''),
    });
  }


  getRequisicao() {
    this.isLoading = true;
    if (this.id) {
      this.requisicaoService.buscar(this.id).subscribe((res: any) => {
        this.requisicao = res;

        switch (res?.tipoAlteracaoPessoal?.id) {
          case TipoAlteracaoPessoalEnum.Email:
            this.labelAlteracao = 'Novo e-mail';
            break;
          case TipoAlteracaoPessoalEnum.Senha:
            this.labelAlteracao = 'Nova senha';
            break;
          case TipoAlteracaoPessoalEnum.Telefone:
            this.labelAlteracao = 'Novo telefone';
            break;
          default:
        }
      }, () => {
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
    }
  }

  calcularQuantidade(campo: any) {
    this.quantidadeCaracteres = campo.value.length || 0;
  }

  negarAlteracao() {
    this.negar = true;
    this.form.get('motivo').setValidators(Validators.required);
    this.form.get('motivo').updateValueAndValidity();
  }

  async fechar() {
    await this.modalController.dismiss();
  }

  async realizarAlteracao(aceitar = true) {
    if (!this.form.valid) {
      return await showToastMessage('Preencha corretamente o formulário', 'danger');
    }
    const obj = {
      aceitar,
      motivo: this.form.get('motivo').value
    };
    await this.modalController.dismiss(obj);
  }
}

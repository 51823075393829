import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Usuario } from '@zellotec/terralogs_regras/models';
import { InfoPopoverComponent } from '../../info-popover/info-popover.component';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AlertaModalComponent } from '../../alerta-modal/alerta-modal.component';
import { DominioService } from '../../../services/dominio.service';
import { TipoCultura } from '@zellotec/terralogs_regras/models/tipo-cultura';
import { TipoAgricultura } from '@zellotec/terralogs_regras/models/tipo-agricultura';
import { TipoProducaoSafra } from '@zellotec/terralogs_regras/models/tipo-producao-safra';
import { parseDecimals, parseTextToDouble, showToastMessage } from '../../../util/utils';
import { AtividadeQuadroSafraService, TIPO_ATIVIDADE } from '../../../services/atividade-quadro-safra.service';
import { loading } from '../../../util/loading';
import { Cultura } from '@zellotec/terralogs_regras/models/cultura';
import { UtilService } from '../../../services/util.service';
import { TipoCulturaAtividadeEnum } from '../../../enums/tipo-cultura-atividade.enum';
import { UnidadeMedida } from '@zellotec/terralogs_regras/models/unidade-medida';
import { UnidadeMedidaService } from '../../../services/unidade-medida.service';
import { TipoAtividadeEnum } from '../../../enums/tipo-atividade.enum';

@Component({
  selector: 'app-quadro-safra',
  templateUrl: './quadro-safra.component.html',
  styleUrls: [ './quadro-safra.component.scss' ],
})
export class QuadroSafraComponent implements OnInit {

  @Input() item: any;
  @Input() idSolicitacao: number;
  @Input() usuarioLogado: Usuario;
  @Input() anoBase: string;
  @Input() culturaPrincipal: Cultura;
  @Input() culturaSecundaria: Cultura;
  @Input() cultura: any;
  open = false;
  periodosSafra = [];
  periodosSafraModelo = [];
  customPopoverOptions: any = {
    subHeader: 'Selecione uma opção',
  };
  form: FormGroup;
  tipoCulturas: TipoCultura[] = [];
  tipoAgriculturas: TipoAgricultura[] = [];
  tipoProducaoSafras: TipoProducaoSafra[] = [];
  unidadesMedidas: UnidadeMedida[] = [];
  periodosSafras = [];
  outraCulturaPrincipal;
  outraCulturaSecundaria;
  unidadeMedidaPrincipal;
  unidadeMedidaSecundaria;
  unidadeMedidaOutras;

  constructor(
    private popoverController: PopoverController,
    private atividadeQuadroSafraService: AtividadeQuadroSafraService,
    private unidadeMedidaService: UnidadeMedidaService,
    private utilService: UtilService,
    private dominioService: DominioService,
    private modalCtrl: ModalController
  ) {
    this.form = new FormGroup({
      safras: new FormArray([]),
    });
  }

  async ngOnInit() {
    this.outraCulturaPrincipal = this.cultura.outraCulturaPrincipal ?? '';
    this.outraCulturaSecundaria = this.cultura.outraCulturaSecundaria ?? '';
    this.unidadeMedidaPrincipal = this.cultura.unidadeMedidaPrincipal;
    this.unidadeMedidaSecundaria = this.cultura.unidadeMedidaSecundaria;
    await this.getPeriodosSafra();
    this.periodosSafraModelo = this.criarPeriodoAgricola();
    this.buscarTipoCulturas();
    this.buscarTipoAgriculturas();
    this.buscarTipoProducaoSafras();
    this.buscarUnidadeMedida();

    const safras = this.form.get('safras') as FormArray;
    loading(
      this.atividadeQuadroSafraService.buscarSolicitacoesQuadroSafra(this.idSolicitacao)
        .subscribe(async (solicitacao) => {
          if (solicitacao.result.length > 0) {
            safras.clear();
            solicitacao.result.forEach((safra, idx) => {
              this.periodosSafra[idx] = this.retornarModeloPeriodo();
              safras.insert(this.form.get('safras').value.length, this.criarQuadroSafra(
                safra.tipoAtividade?.id,
                safra.tipoAtividade?.nome,
                null,
                safra.tipoAgricultura?.id ?? null,
                safra.producaoSafra?.id ?? null,
                safra?.unidadeMedida?.id ?? null,
                safra?.nomeOutraAtividade ?? null
              ));
              const periodos = this.form.get('safras').get(idx.toString()).get('periodos') as FormArray;
              safra.solicitacaoQuadroSafraCultura.forEach((periodo, index) => {
                const periodoSafra = (periodo?.anoBase?.toString() + '/' + (((periodo?.anoBase || 0) + 1).toString()).substring(2, 4));
                this.periodosSafra[idx] = this.periodosSafra[idx].map((pdSafra) => {
                  if (pdSafra.nome === periodoSafra) {
                    pdSafra.checked = true;
                  }
                  return pdSafra;
                });
                periodos.insert(
                  periodos.value.length,
                  this.criarFormPeriodo(
                    parseDecimals(periodo?.id),
                    periodoSafra,
                    parseDecimals(periodo?.areaPropria),
                    parseDecimals(periodo?.areaArrendada),
                    parseDecimals(periodo?.produtividade),
                    parseDecimals(periodo?.producao),
                    parseDecimals(periodo?.preco),
                    parseDecimals(periodo?.produtividadePluma),
                    parseDecimals(periodo?.producaoPluma),
                    parseDecimals(periodo?.precoPluma),
                    parseDecimals(periodo?.produtividadeCaroco),
                    parseDecimals(periodo?.producaoCaroco),
                    parseDecimals(periodo?.precoCaroco),
                    parseDecimals(periodo?.custo)
                  )
                );
                this.calcularProducao(idx, index);
              });
            });
          } else {
            // Novo
            this.periodosSafra[0] = this.retornarModeloPeriodo();
            if (this.culturaPrincipal) {
              await safras.insert(this.form.get('safras').value.length, this.criarQuadroSafra(
                this.culturaPrincipal.id, this.culturaPrincipal.nome, null, null,
                null, this.unidadeMedidaPrincipal?.id, this.outraCulturaPrincipal
              ));
            }
            if (this.culturaSecundaria) {
              this.periodosSafra[1] = this.retornarModeloPeriodo();
              await safras.insert(this.form.get('safras').value.length, this.criarQuadroSafra(
                this.culturaSecundaria.id, this.culturaSecundaria.nome, null, null, null,
                this.unidadeMedidaSecundaria?.id, this.outraCulturaSecundaria
              ));
            }
          }
        })
    );
  }

  outraMedidaAlterar(evento: any, indexSafra: number) {
    this.form.get('safras').get(indexSafra.toString()).get('unidadeMedidaOutro').setValue(evento.detail.value);
  }

  retornarOutraMedida(index) {
    if (index === 0) {
      return this.unidadeMedidaPrincipal?.id;
    } else if (index === 1) {
      return this.unidadeMedidaSecundaria?.id;
    } else {
      return '';
    }
  }

  getPeriodosSafra() {
    this.periodosSafras = this.utilService.gerarSafras(+this.anoBase, false, 2, 2, true);
  }

  getLabelCultura(index) {
    if (index === 0) {
      return 'Principal';
    } else if (index === 1) {
      return 'Secundária';
    } else {
      return 'Outra';
    }
  }

  retornarModeloPeriodo() {
    return JSON.parse(JSON.stringify(this.periodosSafraModelo));
  }

  buscarTipoCulturas() {
    this.atividadeQuadroSafraService.buscarAtividadesPorPerfilProdutor().subscribe(res => {
      this.tipoCulturas = res;
    });
  }

  buscarTipoAgriculturas() {
    this.dominioService.buscarTipoAgricultura().subscribe(res => {
      this.tipoAgriculturas = res;
    });
  }

  buscarTipoProducaoSafras() {
    this.dominioService.buscarTipoProducaoSafras().subscribe(res => {
      this.tipoProducaoSafras = res;
    });
  }

  buscarUnidadeMedida() {
    this.unidadeMedidaService.buscarUnidadeMedida().subscribe(res => {
      this.unidadesMedidas = res;
    });
  }

  criarPeriodoAgricola() {
    return this.periodosSafras.map((periodo, id) => {
      return {
        id,
        nome: periodo.label,
        checked: false
      };
    });
  }

  async trocarNomeOutra(evento: any, indexSafra: number) {
    await this.form.get('safras').get(indexSafra.toString()).get('idCultura').setValue(TIPO_ATIVIDADE.outra);
    await this.form.get('safras').get(indexSafra.toString()).get('cultura').setValue(evento.detail.value);
  }

  async trocarCultura(evento: any, indexSafra: number) {

    setTimeout(async () => {
      const culturaBkp = await this.form.get('safras').get(indexSafra.toString()).get('bkpCultura').value;

      if (culturaBkp && culturaBkp.abrirModal) {
        const modal = await this.modalCtrl.create({
          component: AlertaModalComponent,
          swipeToClose: true,
          cssClass: 'alerta-modal',
          componentProps: {
            tipo: 'Alterar',
            titulo: 'Alterar Cultura!',
            mensagem: 'Tem certeza que deseja alterar a cultura?',
          }
        });

        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data) {
          const cultura = await this.tipoCulturas.find(cult => cult.id === evento.detail.value);
          await this.form.get('safras').get(indexSafra.toString()).get('cultura').setValue(cultura.nome);
          await this.form.get('safras').get(indexSafra.toString()).get('idCultura').setValue(cultura.id);
          await this.form.get('safras').get(indexSafra.toString()).get('bkpCultura').setValue({
            ...cultura,
            abrirModal: true
          });
        } else {
          await this.form.get('safras').get(indexSafra.toString()).get('cultura').setValue(culturaBkp.nome);
          await this.form.get('safras').get(indexSafra.toString()).get('idCultura').setValue(culturaBkp.id);
          culturaBkp.abrirModal = false;
          await this.form.get('safras').get(indexSafra.toString()).get('bkpCultura').setValue(culturaBkp);
        }
      } else {
        const cultura = await this.tipoCulturas.find(cult => cult.id === evento.detail.value);
        await this.form.get('safras').get(indexSafra.toString()).get('bkpCultura').setValue({
          ...cultura,
          abrirModal: true
        });
      }
    });
  }

  criarQuadroSafra(idCultura = null,
                   cultura = null,
                   bkpCultura = null,
                   tipo = null,
                   safra = null,
                   unidadeMedidaOutro = null,
                   outraCulturaNome = null): FormGroup {
    return new FormGroup({
      idCultura: new FormControl(idCultura),
      cultura: new FormControl(cultura),
      bkpCultura: new FormControl(bkpCultura),
      tipo: new FormControl(tipo),
      safra: new FormControl(safra),
      periodos: new FormArray([]),
      unidadeMedidaOutro: new FormControl(unidadeMedidaOutro),
      outraCulturaNome: new FormControl(outraCulturaNome),
    });
  }

  criarFormPeriodo(idSafra = null,
                   nomeSafra = null,
                   areaPropria = null,
                   areaArrendada = null,
                   produtividade = null,
                   producao = null,
                   precoVenda = null,
                   produtividadePluma = null,
                   producaoPluma = null,
                   precoPluma = null,
                   produtividadeCaroco = null,
                   producaoCaroco = null,
                   precoVendaCaroco = null,
                   custoProducao = null): FormGroup {
    return new FormGroup({
      idSafra: new FormControl(idSafra),
      nomeSafra: new FormControl(nomeSafra),
      areaPropria: new FormControl(areaPropria),
      areaArrendada: new FormControl(areaArrendada),
      produtividade: new FormControl(produtividade),
      producao: new FormControl(producao),
      precoVenda: new FormControl(precoVenda),
      produtividadePluma: new FormControl(produtividadePluma),
      producaoPluma: new FormControl(producaoPluma),
      precoPluma: new FormControl(precoPluma),
      produtividadeCaroco: new FormControl(produtividadeCaroco),
      producaoCaroco: new FormControl(producaoCaroco),
      precoVendaCaroco: new FormControl(precoVendaCaroco),
      custoProducao: new FormControl(custoProducao)
    });
  }

  async removerQuadroSafra(index) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Quadro Safra!',
        mensagem: 'Tem certeza que deseja excluir o quadro safra?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const quadrosSafras = this.form.get('safras') as FormArray;
      quadrosSafras.removeAt(index);
    }
  }

  async removerPeriodo(index, idQuadroSafra) {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Excluir',
        titulo: 'Excluir Período!',
        mensagem: 'Tem certeza que deseja excluir o período?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const periodos = this.form.get('safras').get(idQuadroSafra.toString()).get('periodos') as FormArray;
      const indexRemove = periodos.controls.findIndex((periodo) => periodo.value.idSafra === index);
      periodos.removeAt(indexRemove);
      return true;
    }
    return false;
  }

  retornarMedidasMascaras(idCultura, index) {
    switch (idCultura) {
      // SOJA
      case TIPO_ATIVIDADE.soja:
        return this.retornarMedidaSoja();
      // MILHO
      case TIPO_ATIVIDADE.milho:
        return this.retornarMedidaMilho();
      // CANA DE ACUCAR
      case TIPO_ATIVIDADE.cana_acucar:
        return this.retornarMedidaCanaAcucar();
      // CAFE
      case TIPO_ATIVIDADE.cafe:
        return this.retornarMedidaCafe();
      // TRIGO
      case TIPO_ATIVIDADE.trigo:
        return this.retornarMedidaTrigo();
      // ARROZ
      case TIPO_ATIVIDADE.arroz:
        return this.retornarMedidaArroz();
      // FEIJAO
      case TIPO_ATIVIDADE.feijao:
        return this.retornarMedidaFeijao();
      // CITROS
      case TIPO_ATIVIDADE.citros:
        return this.retornarMedidaCitros();
      // ALGODAO
      case TIPO_ATIVIDADE.algodao:
        return this.retornarMedidaAlgodao();
      // OUTRO
      case TIPO_ATIVIDADE.outra:
        const outraMedida = this.form.get('safras').get(index.toString()).get('unidadeMedidaOutro').value;
        const medida = this.unidadesMedidas.find((medidaU) => medidaU.id === outraMedida);
        if (!medida) {
          return this.retornarMedidaSoja();
        } else if (index === 1) {
          this.unidadeMedidaSecundaria = medida;
        } else if (index > 1) {
          this.unidadeMedidaOutras = medida;
        }
        return this.retornarMedidaOutra(index);
      default:
        return this.retornarMedidaSoja();
    }
  }

  retornarMedidaAlgodao() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'ton/ha',
      produtividadePlaceholder: 'ton/ha',
      producao: 'ton',
      precoProducao: '/ha',
      precoProducaoPlaceholder: 'R$/ha',
      custoProducao: '/ton',
      custoProducaoPlaceholder: 'R$/ton'
    };
  }

  retornarMedidaOutra(index) {
    if (index === 0) {
      return {
        areaPropria: 'ha',
        areaArrendada: 'ha',
        produtividade: this.unidadeMedidaPrincipal?.sigla + '/ha',
        produtividadePlaceholder: this.unidadeMedidaPrincipal?.sigla + '/ha',
        producao: this.unidadeMedidaPrincipal?.sigla,
        precoProducao: this.unidadeMedidaPrincipal?.sigla,
        precoProducaoPlaceholder: 'R$/' + this.unidadeMedidaPrincipal?.sigla,
        custoProducao: '/' + this.unidadeMedidaPrincipal?.sigla,
        custoProducaoPlaceholder: 'R$/' + this.unidadeMedidaPrincipal?.sigla
      };
    } else if (index === 1) {
      return {
        areaPropria: 'ha',
        areaArrendada: 'ha',
        produtividade: this.unidadeMedidaSecundaria?.sigla + '/ha',
        produtividadePlaceholder: this.unidadeMedidaSecundaria?.sigla + '/ha',
        producao: this.unidadeMedidaSecundaria?.sigla,
        precoProducao: this.unidadeMedidaSecundaria?.sigla,
        precoProducaoPlaceholder: 'R$/' + this.unidadeMedidaSecundaria?.sigla,
        custoProducao: '/' + this.unidadeMedidaSecundaria?.sigla,
        custoProducaoPlaceholder: 'R$/' + this.unidadeMedidaSecundaria?.sigla
      };
    } else if (index > 1 && this.unidadeMedidaOutras) {
      return {
        areaPropria: 'ha',
        areaArrendada: 'ha',
        produtividade: this.unidadeMedidaOutras?.sigla + '/ha',
        produtividadePlaceholder: this.unidadeMedidaOutras?.sigla + '/ha',
        producao: this.unidadeMedidaOutras?.sigla,
        precoProducao: this.unidadeMedidaOutras?.sigla,
        precoProducaoPlaceholder: 'R$/' + this.unidadeMedidaOutras?.sigla,
        custoProducao: '/' + this.unidadeMedidaOutras?.sigla,
        custoProducaoPlaceholder: 'R$/' + this.unidadeMedidaOutras?.sigla
      };
    } else {
      return {
        areaPropria: 'ha',
        areaArrendada: 'ha',
        produtividade: 'kg/ha',
        produtividadePlaceholder: 'kg/ha',
        producao: 'kg',
        precoProducao: '/ha',
        precoProducaoPlaceholder: 'R$/ha',
        custoProducao: '/kg',
        custoProducaoPlaceholder: 'R$/kg'
      };
    }
  }

  retornarMedidaCitros() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'cx/ha',
      produtividadePlaceholder: 'cx/ha',
      producao: 'cx',
      precoProducao: '/cx',
      precoProducaoPlaceholder: 'R$/cx',
      custoProducao: '/ha',
      custoProducaoPlaceholder: 'R$/ha'
    };
  }

  retornarMedidaFeijao() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'sc/ha',
      produtividadePlaceholder: 'sc/ha',
      producao: 'sc',
      precoProducao: '/sc',
      precoProducaoPlaceholder: 'R$/sc',
      custoProducao: '/ha',
      custoProducaoPlaceholder: 'R$/ha'
    };
  }

  retornarMedidaArroz() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'sc/ha',
      produtividadePlaceholder: 'sc/ha',
      producao: 'sc',
      precoProducao: '/sc',
      precoProducaoPlaceholder: 'R$/sc',
      custoProducao: '/ha',
      custoProducaoPlaceholder: 'R$/ha'
    };
  }

  retornarMedidaTrigo() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'sc/ha',
      produtividadePlaceholder: 'sc/ha',
      producao: 'sc',
      precoProducao: '/sc',
      precoProducaoPlaceholder: 'R$/sc',
      custoProducao: '/ha',
      custoProducaoPlaceholder: 'R$/ha'
    };
  }

  retornarMedidaCafe() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'sc/ha',
      produtividadePlaceholder: 'sc/ha',
      producao: 'sc',
      precoProducao: '/sc',
      precoProducaoPlaceholder: 'R$/sc',
      custoProducao: '/ha',
      custoProducaoPlaceholder: 'R$/ha'
    };
  }

  retornarMedidaCanaAcucar() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'ton/ha',
      produtividadePlaceholder: 'ton/ha',
      producao: 'ton',
      precoProducao: '/ton',
      precoProducaoPlaceholder: 'R$/ton',
      custoProducao: '/ha',
      custoProducaoPlaceholder: 'R$/ha'
    };
  }

  retornarMedidaMilho() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'sc/ha',
      produtividadePlaceholder: 'sc/ha',
      producao: 'sc',
      precoProducao: '/sc',
      precoProducaoPlaceholder: 'R$/sc',
      custoProducao: '/ha',
      custoProducaoPlaceholder: 'R$/ha'
    };
  }

  retornarMedidaSoja() {
    return {
      areaPropria: 'ha',
      areaArrendada: 'ha',
      produtividade: 'sc/ha',
      produtividadePlaceholder: 'sc/ha',
      producao: 'sc',
      precoProducao: '/sc',
      precoProducaoPlaceholder: 'R$/sc',
      custoProducao: '/ha',
      custoProducaoPlaceholder: 'R$/ha'
    };
  }

  calcularProducao(indexSafra, indexPeriodo) {
    let producao;
    let producaoCaroco;
    let producaoPluma;

    const safra = this.form.get('safras').get(indexSafra.toString()).get('periodos').get(indexPeriodo.toString());
    const areaPropria = parseFloat((safra.get('areaPropria').value || '0').replace('.', '').replace(',', '.') || 0);
    const areaArrendada = parseFloat((safra.get('areaArrendada').value || '0').replace('.', '').replace(',', '.') || 0);

    const produtividadePluma = parseFloat((safra.get('produtividadePluma').value || '0').replace('.', '').replace(',', '.') || 0);
    if ((areaPropria || areaArrendada) && produtividadePluma) {
      producaoPluma = parseDecimals((areaPropria + areaArrendada) * produtividadePluma);
      safra.get('producaoPluma').setValue(producaoPluma.toString());
    } else {
      safra.get('producaoPluma').setValue(null);
    }

    const produtividadeCaroco = parseFloat((safra.get('produtividadeCaroco').value || '0').replace('.', '').replace(',', '.') || 0);
    if ((areaPropria || areaArrendada) && produtividadeCaroco) {
      producaoCaroco = parseDecimals((areaPropria + areaArrendada) * produtividadeCaroco);
      safra.get('producaoCaroco').setValue(producaoCaroco.toString());
    } else {
      safra.get('producaoCaroco').setValue(null);
    }

    const produtividade = parseFloat((safra.get('produtividade').value || '0').replace('.', '').replace(',', '.') || 0);

    if ((areaPropria || areaArrendada) && produtividade) {
      producao = parseDecimals((areaPropria + areaArrendada) * produtividade);
      safra.get('producao').setValue(producao.toString());
    } else {
      safra.get('producao').setValue(null);
    }
  }

  async novoQuadroSafra() {
    const quadrosSafras = this.form.get('safras') as FormArray;
    quadrosSafras.insert(this.form.get('safras').value.length, this.criarQuadroSafra());
    this.periodosSafra[quadrosSafras.length - 1] = this.retornarModeloPeriodo();
    setTimeout(() => {
      const id = document.getElementById('quadro_safra_' + (quadrosSafras.length - 1));
      this.scrollToElement(id);
    });
  }

  async novoPeriodo(evento, idQuadroSafra) {
    const periodos = this.form.get('safras').get(idQuadroSafra.toString()).get('periodos') as FormArray;
    periodos.insert(this.form.get('safras').get(idQuadroSafra.toString()).get('periodos').value.length,
      this.criarFormPeriodo(evento.id, evento.nome));
    setTimeout(() => {
      const id = document.getElementById('quadro_' + idQuadroSafra + '_periodo_' + (periodos.length - 1));
      this.scrollToElement(id);
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  async selecionarOpcao(evento, idQuadroSafra) {
    if (evento.checked) {
      this.novoPeriodo(evento, idQuadroSafra);
    } else {
      const retornoChecked = await this.removerPeriodo(evento.id, idQuadroSafra);
      if (!retornoChecked) {
        evento.checked = true;
      }
    }
  }

  async infoPopover(ev: any, mensagem?) {
    const popover = await this.popoverController.create({
      component: InfoPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {
        mensagem
      }
    });
    await popover.present();
  }

  retornarTipoCultaraAtividade(index) {
    if (index === 0) {
      return TipoCulturaAtividadeEnum.Primaria;
    } else if (index === 1) {
      return TipoCulturaAtividadeEnum.Secundaria;
    } else {
      return TipoCulturaAtividadeEnum.Outros;
    }
  }

  async salvar() {

    const quadroSafra = [];

    const safras = this.form.get('safras') as FormArray;
    for (const [ index, safra ] of safras.controls.entries()) {
      const periodos = safra.get('periodos') as FormArray;

      const cultura = safra.get('idCultura').value;
      const outraCulturaNome = safra.get('outraCulturaNome').value;
      const outraUnidadeMedida = safra.get('unidadeMedidaOutro').value;

      if (!cultura ||
        (cultura === TipoAtividadeEnum.OutraPropriedadeRuralOuEndividamento && (!outraCulturaNome || !outraUnidadeMedida))) {
        await showToastMessage('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
        return;
      } else if (periodos.length === 0) {
        await showToastMessage('É necessário selecionar pelo menos um periodo.', 'danger');
        return;
      }

      const obj = {
        tipo: this.retornarTipoCultaraAtividade(index),
        tipoAtividade: safra.get('idCultura').value,
        nomeOutraAtividade: safra.get('idCultura').value === TIPO_ATIVIDADE.outra ? safra.get('outraCulturaNome').value : null,
        producaoSafra: safra.get('safra').value,
        tipoAgricultura: safra.get('tipo').value,
        unidadeMedida: safra.get('unidadeMedidaOutro').value,
        periodoSafra: []
      };

      for (const periodo of periodos.controls) {
        obj.periodoSafra.push(
          {
            anoBase: +(periodo.get('nomeSafra').value.substring(0, 4)),
            areaArrendada: parseTextToDouble(periodo.get('areaArrendada').value),
            areaPropria: parseTextToDouble(periodo.get('areaPropria').value),
            custo: parseTextToDouble(periodo.get('custoProducao').value),
            produtividade: parseTextToDouble(periodo.get('produtividade').value),
            producao: parseTextToDouble(periodo.get('producao').value),
            preco: parseTextToDouble(periodo.get('precoVenda').value),
            produtividadePluma: parseTextToDouble(periodo.get('produtividadePluma').value),
            producaoPluma: parseTextToDouble(periodo.get('producaoPluma').value),
            precoPluma: parseTextToDouble(periodo.get('precoPluma').value),
            produtividadeCaroco: parseTextToDouble(periodo.get('produtividadeCaroco').value),
            producaoCaroco: parseTextToDouble(periodo.get('producaoCaroco').value),
            precoCaroco: parseTextToDouble(periodo.get('precoVendaCaroco').value),
          }
        );
      }
      quadroSafra.push(obj);
    }

    loading(
      this.atividadeQuadroSafraService.salvarSolicitacaoQuadroSafra(
        {
          solicitacao: this.idSolicitacao,
          culturas: quadroSafra
        }
      ).subscribe(async () => {
        await showToastMessage('Registro salvo com sucesso.', 'success');
        await this.modalCtrl.dismiss(true);
      }, async () => {
        await showToastMessage('Erro no serviço, tente denovo em outro momento!', 'danger');
      })
    );

  }

  async fechar() {
    await this.modalCtrl.dismiss();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { showToastMessage } from '../../util/utils';

@Component({
  selector: 'app-recusa-cancelar-operacao',
  templateUrl: './recusa-cancelar-operacao.component.html',
  styleUrls: [ './recusa-cancelar-operacao.component.scss' ],
})

export class RecusaCancelarOperacaoComponent implements OnInit {

  @Input() nomeSolicitante = '-';
  @Input() motivosSolicitacao = '-';

  form: FormGroup;
  quantidadeCaracteres = 0;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private modalController: ModalController) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      descricao: new FormControl('')
    });
  }

  calcularQuantidade(campo: any) {
    this.quantidadeCaracteres = campo.value.length || 0;
  }

  async fechar() {
    await this.modalController.dismiss();
  }

  async salvar() {
    if (!this.form.valid) {
      return await showToastMessage('Preencha corretamente o formulário', 'danger');
    }
    await this.modalController.dismiss(this.form.getRawValue());
  }
}

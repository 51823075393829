import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { dicalogin, showToastMessage } from './util/utils';
import { ModalController, NavController } from '@ionic/angular';
import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { AlertaModalComponent } from './components/alerta-modal/alerta-modal.component';
import { environment } from '../environments/environment';
import { Menu, MenuService } from './services/menu.service';
import { Observable } from 'rxjs';
import { UsuarioService } from './services/usuario.service';
import { loading } from './util/loading';
import { DocumentoService } from './services/documento.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [ 'app.component.scss' ],
})
export class AppComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  itens$: Observable<Menu[]>;
  appVersion = environment.appVersion;
  menuShow = true;
  usuario;
  usuarioLogado;

  carregando = true;

  constructor(
    public modalCtrl: ModalController,
    private autorizacaoService: AutorizacaoService,
    private usuarioService: UsuarioService,
    private menuService: MenuService,
    private navCtrl: NavController,
    private documentoService: DocumentoService,
  ) {
    this.usuario = this.usuarioService.buscarUsuarioLogado();
    this.itens$ = this.menuService.buscarItens();
    if (this.usuario) {
      this.menuService.filtrarPorPerfil(this.usuario.perfil);
    }
  }

  ngOnInit() {
    this.autorizacaoService.iniciarSessao();
    this.usuarioLogado = this.usuarioService.buscarUsuarioLogado();
  }

  irHome() {
    this.navCtrl.navigateRoot('/solicitantes');
  }

  irLogin() {
    this.navCtrl.navigateRoot('/login');
  }

  dicalogin(nome) {
    return dicalogin(nome);
  }

  editarUsuario(evento: any) {
  }

  solicitacaoAlteracoes(evento: any) {
  }

  async sair() {
    const modal = await this.modalCtrl.create({
      component: AlertaModalComponent,
      swipeToClose: true,
      cssClass: 'alerta-modal',
      componentProps: {
        tipo: 'Sair',
        titulo: 'Sair!',
        mensagem: 'Tem certeza que deseja sair?',
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      await this.autorizacaoService.delete();
      await this.navCtrl.navigateRoot('/login');
    }
    localStorage.setItem('MODAL_PREENCHIMENTO_OBRIGATORIO', JSON.stringify(false));
  }

  async inserirArquivo(usuario: any, files: any) {
    for (const file of files) {
      if (!file)
        continue;

      const formData = new FormData();
      formData.append('arquivo', file);
      formData.append('usuario', usuario);

      const acao$ = this.documentoService.uploadArquivoUsuario(formData)
      await loading(acao$.subscribe(() => {
        this.fileInput.nativeElement.value = null;
      }, error => {
        this.fileInput.nativeElement.value = null;
        return showToastMessage(error.message || 'Não foi possível salvar os dados. Tente novamente!', 'danger');
      }));
    }
  }

}

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { resolve } from 'src/app/util/resolve';
import { ActivatedRoute, Params } from '@angular/router';
import { isEqual, pick } from 'lodash';
import { mapParamsPaginacao, mapRetornoApiPaginado, Pagina } from '../util/pagination';
import { Solicitacao } from '@zellotec/terralogs_regras/models';
import { ParamsPaginacao, RetornoApi, RetornoApiPaginado } from '../models/api';
import { distinctUntilChanged, map, pluck, tap } from 'rxjs/operators';
import { FonteDados } from '../components/apresentar-dados';
import { TabsService } from './tabs.service';
import {
  calcularPreenchimentoSessao,
  mapearSolicitacao,
  SolicitacaoSessao,
  validarObrigatorios
} from '../util/mapeamento-solicitacao';
import { SituacaoSolicitacaoEnum } from '../enums/situacao-solicitacao.enum';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoService {

  constructor(private http: HttpClient,
              private activatedRoute: ActivatedRoute,
              private tabsService: TabsService) {
  }

  calculoSolicitacao(idSolicitacao: number): Observable<any> {
    const url = resolve('refactory://solicitacaoCalculoTotalPecuaria/idSolicitacao', { idSolicitacao });
    return this.http.get<any>(url).pipe(
      map((result: any) => result.data)
    );
  }

  faturamento(idSolicitacao: number): Observable<any> {
    const url = resolve('refactory://solicitacaoFaturamento/idSolicitacao', { idSolicitacao });
    return this.http.get<any>(url).pipe(
      map((result: any) => result.data)
    );
  }

  calculoQuadroSafra(idSolicitacao: number): Observable<any> {
    const url = resolve('refactory://solicitacaoCalculoQuadroSafra/idSolicitacao', { idSolicitacao });
    return this.http.get<any>(url).pipe(
      pluck('data'),
      map(x => x.result)
    );
  }

  obterParamsGetSolicitacoes(queryParams: Params): ParamsGetSolicitacoes {
    return {
      ...pick(queryParams, 'name'),
      ...mapParamsPaginacao()(queryParams),
    };
  }

  obterParamsGetSolicitacao(queryParams: Params): ParamsGetSolicitacoes {
    return {
      ...pick(queryParams, 'name')
    };
  }

  buscarSolicitacoes(params: ParamsGetSolicitacoes): Observable<Pagina<Solicitacao>> {
    const url = resolve('refactory://solicitacoes');
    return this.http.get<RetornoSolicitacao>(url, {
      params: { ...params },
    }).pipe(
      mapRetornoApiPaginado('items', params),
    );
  }

  buscar(id: number): Observable<Solicitacao> {
    const url = resolve('refactory://solicitacoes/idSolicitacao', { idSolicitacao: id });
    return this.http.get<RetornoApi<{ result: Solicitacao }>>(url).pipe(
      map(result => result.data.result),
    );
  }

  adicionar(idUsuarioCadastro: number, idUsuarioSolicitante: number): Observable<Solicitacao> {
    const url = resolve('refactory://solicitacoes');
    return this.http.post<RetornoApi<{ result: Solicitacao }>>(url, {
      usuarioCadastro: idUsuarioCadastro,
      usuarioSolicitante: idUsuarioSolicitante
    }).pipe(
      map(result => result.data.result),
    );
  }

  alterar(solicitacao: Solicitacao | any, idSolicitacao: number): Observable<Solicitacao> {
    const url = resolve('refactory://solicitacoes/idSolicitacao', { idSolicitacao });
    return this.http.patch<Solicitacao>(url, solicitacao);
  }

  limparPerfilProdutor(idSolicitacao?: number) {
    const url = resolve('refactory://solicitacaoLimparProducao/idSolicitacao', { idSolicitacao });
    return this.http.delete<Solicitacao>(url);
  }

  solicitacaoCulturaAtividade(solicitacaoCulturaAtividade) {
    const url = resolve('refactory://solicitacaoCulturaAtividade');
    return this.http.post<Solicitacao>(url, solicitacaoCulturaAtividade);
  }

  async carregarDados(idSolicitacao?: number) {
    if (idSolicitacao) {
      localStorage.setItem('MODAL_PREENCHIMENTO_OBRIGATORIO', JSON.stringify(true));
      return FonteDados.consumindo(
        this.activatedRoute.queryParams.pipe(
          map(params => this.obterParamsGetSolicitacao(params)),
          distinctUntilChanged(isEqual),
        ),
        () => this.buscar(idSolicitacao)
          .pipe(
            distinctUntilChanged(isEqual),
            map((solicitacao) => mapearSolicitacao(solicitacao)),
            map((solicitacao) => calcularPreenchimentoSessao(solicitacao)),
            map((solicitacao) => validarObrigatorios(solicitacao)),
            tap(solicitacao => this.tabsService.liberarTabs(solicitacao)),
            tap(solicitacao => this.tabsService.alterarTitulo(
                solicitacao.informacoesGerais.campos.nome,
                solicitacao.menuTodos,
                solicitacao.id
              )
            )
          ),
      );
    } else {
      return FonteDados.consumindo(
        this.activatedRoute.queryParams.pipe(
          map(params => this.obterParamsGetSolicitacoes(params)),
        ),
        () => of(new SolicitacaoSessao()).pipe(
          map((solicitacao) => validarObrigatorios(solicitacao)),
          tap(solicitacao => this.tabsService.liberarTabs(solicitacao))
        ),
      );
    }
  }


  formularioNaoPossuiInfo(params: any): Observable<any> {
    const url = resolve('refactory://verificarPreenchimentoFormulario');
    return this.http.post<any>(url, params);
  }

  buscarPreenchimentoFormularioPorSolicitacao(idSolicitacao: number) {
    const url = resolve('refactory://verificarPreenchimentoFormularioPorSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe();
  }

  buscarPercentualPreenchimento(idSolicitacao: number) {
    const url = resolve('refactory://preenchimentoValor', { idSolicitacao });
    return this.http.get(url).pipe(pluck('data', 'items'));
  }

  buscarGuiasNaoPreenchidas(idSolicitacao: number) {
    const url = resolve('refactory://preenchimentoGuias', { idSolicitacao });
    return this.http.get(url).pipe(pluck('data', 'items'));
  }

  atualizarPreenchimentoSolicitacao(idSolicitacao: number) {
    const url = resolve('refactory://preenchimentoAtualizar', { idSolicitacao });
    return this.http.patch(url, {}).pipe(pluck('data', 'items'));
  }

  atualizarAtendente(idSolicitante: number, usuarioCadastro: number) {
    const url = resolve('refactory://atualizarAtendente', { idSolicitante });
    return this.http.patch(url, { usuarioCadastro });
  }


  /** EmPreenchimentoDoRaro */
  preencherRaro(solicitacao: number): Observable<Solicitacao> {
    const obj: SituacaoSolicitacao = {
      situacaoSolicitacao: SituacaoSolicitacaoEnum.EmPreenchimentoDoRaro
    };
    return this.alterar(obj, solicitacao);
  }

  /** AguardandoAprovacaoDoRaro */
  solicitarAprovacaoRaro(solicitacao: number): Observable<Solicitacao> {
    const obj: SituacaoSolicitacao = {
      situacaoSolicitacao: SituacaoSolicitacaoEnum.AguardandoAprovacaoDoRaro
    };
    return this.alterar(obj, solicitacao);
  }

  /** RaroAprovado */
  aprovarRaro(solicitacao: number): Observable<Solicitacao> {
    const obj: SituacaoSolicitacao = {
      situacaoSolicitacao: SituacaoSolicitacaoEnum.RaroAprovado
    };
    return this.alterar(obj, solicitacao);
  }

  /** PendenteDeCorrecao ou PendenteDeDocumentacao */
  reprovarRaro(solicitacao: number, situacao: number): Observable<Solicitacao> {
    const obj: SituacaoSolicitacao = {
      situacaoSolicitacao: situacao
    };
    return this.alterar(obj, solicitacao);
  }

  /** CancelamentoSolicitado */
  solicitarCancelamentoDaOperacao(solicitacao: number, tipo: number, motivo: string): Observable<Solicitacao> {
    const obj: SituacaoSolicitacao = {
      situacaoSolicitacao: SituacaoSolicitacaoEnum.CancelamentoSolicitado,
      tipoCancelamento: tipo,
      motivo
    };
    return this.alterar(obj, solicitacao);
  }

  cancelarSolicitacaoDeCancelamentoDaOperacao(solicitacao: number, motivo: string): Observable<Solicitacao> {
    const obj: SituacaoSolicitacao = {
      situacaoSolicitacao: SituacaoSolicitacaoEnum.EmPreenchimentoDoRaro,
      recusaCancelamento: motivo
    };
    return this.alterar(obj, solicitacao);
  }

  /** OperacaoCancelada */
  cancelarOperacao(solicitacao: number, tipo: number, motivo: string): Observable<Solicitacao> {
    const obj: SituacaoSolicitacao = {
      situacaoSolicitacao: SituacaoSolicitacaoEnum.OperacaoCancelada,
      tipoCancelamento: tipo,
      motivo
    };
    if (!tipo) {
      delete obj.tipoCancelamento;
    }
    if (!motivo) {
      delete obj.motivo;
    }
    return this.alterar(obj, solicitacao);
  }

  /** CreditoEmAnalise */
  analisarCredito() {}

  /** PendenteDeDocumentacaoDeCredito */
  cancelarAnaliseCredito() {}

  /** CreditoAprovado */
  aprovarCredito() {}

  /** CreditoReprovado */
  reprovarCredito() {}

  gerarRating(idSolicitacao: number): Observable<Solicitacao> {
    const url = resolve('refactory://gerarRatingRaro/idSolicitacao', { idSolicitacao });
    return this.http.get<any>(url).pipe(
      map((result: any) => result.data)
    );
  }

}

export type RetornoSolicitacao = RetornoApiPaginado<'items', Solicitacao>;

export interface ParamsGetSolicitacoes extends ParamsPaginacao {
  name?: string;
}

export interface SituacaoSolicitacao {
  situacaoSolicitacao: number;
  tipoCancelamento?: number;
  motivo?: string;
  recusaCancelamento?: string;
}

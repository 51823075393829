import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { resolve } from 'src/app/util/resolve';

@Injectable({
  providedIn: 'root'
})
export class IniciativaSociambientalService {
  constructor(
    private http: HttpClient
  ) {}

  buscarIniciativasSocioambientais() {
    const url = resolve('refactory://iniciativasSocioambientais');
    return this.http.get(url).pipe(
      map((result: any) => result.items)
    );
  }

  salvarSolicitacaoIniciativasSocioambientais(solicitacao: SolicitacaoIniciativaSocioambiental) {
    const url = resolve('refactory://solicitacaoIniciativasSocioambientais');
    return this.http.post(url, solicitacao);
  }

  salvarSolicitacaoIniciativasSocioambientaisOutros(solicitacao: SolicitacaoIniciativaSocioambientalOutros) {
    const url = resolve('refactory://solicitacaoIniciativasSocioambientaisOutros');
    return this.http.post(url, solicitacao);
  }

  deletarSolicitacoesIniciativasSocioambientais(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoIniciativasSocioambientais/idSolicitacao', { idSolicitacao });
    return this.http.delete(url);
  }

  buscarSolicitacoesIniciativasSocioambientais(idSolicitacao: number) {
    const url = resolve('refactory://solicitacaoIniciativasSocioambientais/idSolicitacao', { idSolicitacao });
    return this.http.get(url).pipe(
      map((result: any) => result.data)
    );
  }

}

export interface SolicitacaoIniciativaSocioambiental {
  solicitacao?: number;
  iniciativasSocioambientais?: number;
}

export interface SolicitacaoIniciativaSocioambientalOutros {
  nome?: string;
  solicitacaoIniciativasSocioambientais?: number;
}

export enum INICIATIVA_SOCIOAMBIENTAL {
  outros = 8,
  naoPossui = 9
}



